import { cloudinary } from '@trmediaab/zebra-utils';
import PropTypes from 'prop-types';

const screenSizes = [480, 768, 1200, 1600];

const Image = ({
  alt = '',
  className,
  crop,
  focus,
  gravity,
  height,
  imageId,
  ratio = [16, 9],
  width,
  zoom,
}) => {
  const fixedImageId = imageId
    .replace('cloudinary.', '') // temporary bug fix
    .replace('://', '/');

  const transforms = [
    height != null ? `h_${height}` : undefined,
    crop != null ? `c_${crop}` : undefined,
    zoom != null ? `z_${zoom}` : undefined,
    ...cloudinary.getFocusGravity(
      focus,
      gravity != null ? `g_${gravity}` : true,
    ),
  ].filter(Boolean);

  const srcset =
    width != null
      ? [width, width * 2].map(
          (width, i) =>
            `${cloudinary.getUrl(fixedImageId, width, ratio, transforms)} ${
              i + 1
            }x`,
        )
      : screenSizes.map(
          width =>
            `${cloudinary.getUrl(
              fixedImageId,
              width,
              ratio,
              transforms,
            )} ${width}w`,
        );

  const src = cloudinary.getUrl(
    fixedImageId,
    width || screenSizes[0],
    ratio,
    transforms,
  );

  const sizes = width ? undefined : '(min-width: 940px) 940px, 100vw';

  return (
    <img
      alt={alt}
      className={className}
      sizes={sizes}
      src={src}
      srcSet={srcset}
    />
  );
};

Image.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  crop: PropTypes.string,
  focus: PropTypes.string,
  gravity: PropTypes.string,
  height: PropTypes.string,
  imageId: PropTypes.string.isRequired,
  ratio: PropTypes.arrayOf(PropTypes.number),
  width: PropTypes.number,
  zoom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Image;
