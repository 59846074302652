import 'shared/utils/polyfills';
import 'current-input';
import './index.module.scss';

import ConsentBannerStyles from 'consentBannerStyles';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { AdsLoaderProvider } from 'calendar/components/Ad/AdsLoaderContext';
import ErrorBoundary from 'shared/components/ErrorBoundary';
import { UPDATE_AVAILABLE } from 'shared/constants/MessageTypes';
import VerifyApplicationAccessible from 'shared/containers/VerifyApplicationAccessible';
import { addSiteMessage } from 'shared/redux/actions';
import { configureStore } from 'shared/redux/store';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

console.info(`v.${process.env.REACT_APP_VERSION}`);

const store = configureStore();

const root = createRoot(document.querySelector('#app'));

root.render(
  <ErrorBoundary>
    <Router>
      <Provider store={store}>
        <ConsentBannerStyles />
        <AdsLoaderProvider>
          <VerifyApplicationAccessible>
            <App />
          </VerifyApplicationAccessible>
        </AdsLoaderProvider>
      </Provider>
    </Router>
  </ErrorBoundary>,
);

serviceWorkerRegistration.register({
  onUpdate: swRegistration =>
    store.dispatch(addSiteMessage(UPDATE_AVAILABLE, { swRegistration })),
});
