export const CACHE_FIVE_MINUTES = 5 * 60 * 1000;
export const CACHE_ONE_HOUR = 60 * 60 * 1000;

export const isValidCache = (timestamp, cacheLength) => {
  if (
    timestamp == null ||
    Number.isNaN(timestamp) ||
    cacheLength == null ||
    Number.isNaN(cacheLength)
  ) {
    return false;
  }

  const now = Date.now();

  return now <= timestamp + cacheLength;
};
