import { GTM_EVENT } from 'shared/redux/actionTypes';

export const sendTrackingEvent = (
  eventCategory,
  eventAction,
  eventLabel,
  eventValue = null,
) => ({
  type: GTM_EVENT,
  payload: {
    eventCategory: eventCategory ? eventCategory.toLowerCase() : null,
    eventAction: eventAction ? eventAction.toLowerCase() : null,
    eventLabel: eventLabel ? eventLabel.toLowerCase() : null,
    eventValue,
  },
});
