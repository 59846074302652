import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import transform from 'lodash/transform';

import Sentry from 'shared/utils/sentry';

const STORAGE_KEY = 'redux';

// List of object keys that should be excluded from storage
const BLACK_LIST = [
  'password',
  'loading',
  'isLoading',
  'error',
  'isError',
  'hasError',
];

const storageState = {
  localStorage: {},
  sessionStorage: {},
};

// web storage is not available (and will throw) in some circumstances, e.g Safari private mode.
const storageAvailable = storage => {
  try {
    const test = 'test';
    window[storage].setItem(test, test);
    window[storage].removeItem(test);
    return true;
  } catch {
    return false;
  }
};

// Returns the difference between two objects
const difference = (object, base, exclude = []) => {
  const changes = (object, base) =>
    transform(object, (result, value, key) => {
      if (!exclude.includes(key) && !isEqual(value, base[key])) {
        const diffValue =
          isObject(value) && isObject(base[key])
            ? changes(value, base[key])
            : value;

        if (!isObject(diffValue) || !isEmpty(diffValue)) {
          result[key] = diffValue;
        }
      }
    });
  return changes(object, base);
};

const persistToWebStorage = (storage, prevState, nextState) => {
  if (storageAvailable(storage)) {
    const diff = difference(nextState, prevState, BLACK_LIST);
    if (isEmpty(diff)) {
      return;
    }
    storageState[storage] = merge(storageState[storage], diff);
    try {
      window[storage].setItem(
        STORAGE_KEY,
        JSON.stringify(storageState[storage]),
      );
    } catch (error) {
      console.warn(`Failed to persist state to ${storage}`);
      Sentry.captureException(error);
    }
  }
};

export const getPersistentState = filterKeys => {
  const persistentState = merge(
    {},
    storageState.localStorage,
    storageState.sessionStorage,
  );

  return Array.isArray(filterKeys)
    ? pick(persistentState, filterKeys)
    : persistentState;
};

const middleware = () => {
  // Read initial state stored in local storage
  if (storageAvailable('localStorage')) {
    storageState.localStorage =
      JSON.parse(window.localStorage.getItem(STORAGE_KEY)) || {};
  }

  // Read initial state stored in session storage
  if (storageAvailable('sessionStorage')) {
    storageState.sessionStorage =
      JSON.parse(window.sessionStorage.getItem(STORAGE_KEY)) || {};
  }

  return store => next => action => {
    const persistLocal =
      !action.error && action.meta && action.meta.persist === 'local';
    const persistSession =
      !action.error && action.meta && action.meta.persist === 'session';

    if (persistLocal || persistSession) {
      const prevState = store.getState();
      const returnValue = next(action);
      const nextState = store.getState();

      if (prevState !== nextState) {
        persistToWebStorage(
          persistLocal ? 'localStorage' : 'sessionStorage',
          prevState,
          nextState,
        );
      }

      return returnValue;
    }

    return next(action);
  };
};

export default middleware;
