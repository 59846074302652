import { createSelector } from 'reselect';

const stateSelector = state => state.settings;

export const isLoading = createSelector(
  stateSelector,
  state => state.isLoading,
);

export const getSettings = createSelector(stateSelector, state => state.data);

export const getError = createSelector(stateSelector, state => state.error);

export const getId = createSelector(getSettings, settings =>
  settings != null ? settings.id : undefined,
);

export const defaultRanking = createSelector(getSettings, settings =>
  settings != null ? settings.defaultRanking : 'mark-percent',
);

export const isManualMode = createSelector(getSettings, settings =>
  settings != null ? settings.manualMode : false,
);

export const showMarkPercent = createSelector(getSettings, settings =>
  settings != null ? settings.showMarkPercent : true,
);

export const showOdds = createSelector(getSettings, settings =>
  settings != null ? settings.showOdds : true,
);

export const showRankingResults = createSelector(getSettings, settings =>
  settings != null ? settings.showRankingResults : true,
);

export const showBalance = createSelector(getSettings, settings =>
  settings != null ? settings.showBalance : true,
);

export const showWagon = createSelector(getSettings, settings =>
  settings != null ? settings.showWagon : true,
);

export const spareType = createSelector(getSettings, settings =>
  settings != null && settings.spareType != null
    ? settings.spareType
    : 'NEXT_RANKED',
);

export const v6 = createSelector(getSettings, settings =>
  settings != null && settings.v6 != null ? settings.v6 : false,
);

export const v7 = createSelector(getSettings, settings =>
  settings != null && settings.v7 != null ? settings.v7 : false,
);
