import memoize from 'lodash/memoize';
import throttle from 'lodash/throttle';

import moment from './moment';
import { getText } from './Text';

export const toLocalMoment = memoize(epochOrMoment => {
  const m = moment.utc(epochOrMoment);
  m.tz('Europe/Stockholm');
  return m;
});

export const getNow = throttle(() => {
  const now = moment();
  return now.tz('Europe/Stockholm');
}, 1000);

export function formatCalendar(
  epoch,
  {
    showTimeForToday = false,
    showDayOfWeek = true,
    showOnlyDayNextWeek = false,
  } = {},
) {
  return toLocalMoment(epoch).calendar(null, {
    sameDay: showTimeForToday ? '[idag] HH:mm' : '[idag]',
    nextDay: '[imorgon]',
    nextWeek: showOnlyDayNextWeek
      ? 'dddd'
      : showDayOfWeek
        ? 'dddd D MMM'
        : 'D MMM',
    lastDay: '[igår]',
    lastWeek: showDayOfWeek ? '[i] dddd[s] D MMM' : 'D MMM',
    sameElse: showDayOfWeek ? 'dddd D MMM' : 'D MMM',
  });
}

export const formatEpoch = (epoch, format) =>
  toLocalMoment(epoch).format(format);

export const formatDateTimeShort = epoch =>
  formatEpoch(epoch, 'D MMM [–] HH:mm');

export const formatDate = epoch => formatEpoch(epoch, 'D MMM');

export const formatDateCompact = epoch => formatEpoch(epoch, 'YYMMDD');

export const formatDateISO = epoch => formatEpoch(epoch, 'YYYY-MM-DD');

export const formatDateDayMonth = epoch => formatEpoch(epoch, 'D MMM YYYY');

export const formatMonthDayTime = epoch =>
  formatEpoch(epoch, 'D[/]MM[,] HH[:]mm');

export const formatDateTime = epoch =>
  formatEpoch(epoch, `D MMM, [${getText('time-prefix-short')}] HH[:]mm`);

export const formatTime = (epoch, raw) =>
  !epoch
    ? '-'
    : formatEpoch(
        epoch,
        `[${raw ? '' : `${getText('time-prefix-short')} `}]HH[:]mm`,
      );

export const isToday = epoch => datesAreSameDay(Date.now(), epoch);

export const datesAreSameDay = (...epochs) => {
  if (epochs.length <= 1) {
    return false;
  }
  const dateString1 = new Date(epochs[0]).toDateString();
  return epochs
    .slice(1)
    .every(epoch => new Date(epoch).toDateString() === dateString1);
};
