import dotProp from 'dot-prop-immutable';

import {
  SHARED_BET_CLAIM_UPDATE,
  SHARED_BET_COMMENT,
  SHARED_BET_UPDATE_ATG,
  SHARED_BET_UPDATE_SPORT,
} from 'shared/constants/StompKind';
import * as types from 'shared/redux/actionTypes';

const commentOldestFirstSorter = (commentA, commentB) =>
  commentA.created - commentB.created;

const defaultSectionState = {
  collaborators: {
    resolved: {},
    loading: {},
    errors: {},
  },
  resentInvites: {
    resolved: {},
    loading: {},
  },
  invites: {
    resolved: {},
    loading: {},
    errors: {},
  },
  shareCodes: {
    resolved: {},
    loading: {},
    errors: {},
  },
  comments: {
    resolved: {},
    loading: {},
    errors: {},
  },
  claimStatus: {
    resolved: {},
    loading: {},
    errors: {},
  },
  betUpdates: {
    resolved: {},
  },
};

const defaultState = {
  sport: {
    ...defaultSectionState,
  },
  atg: {
    ...defaultSectionState,
  },
};

export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case types.REQUEST_SHARE_CODE: {
      const { betId, type } = action.meta;
      let nextState = state;
      nextState = dotProp.set(
        nextState,
        `${type}.shareCodes.loading.${betId}`,
        true,
      );
      nextState = dotProp.delete(
        nextState,
        `${type}.shareCodes.errors.${betId}`,
      );
      return nextState;
    }

    case types.RECEIVE_SHARE_CODE: {
      const { betCode } = action.payload;
      const { betId, type } = action.meta;

      return {
        ...state,
        [type]: {
          ...state[type],
          shareCodes: {
            ...state[type].shareCodes,
            resolved: dotProp.set(
              state[type].shareCodes.resolved,
              betId,
              betCode,
            ),
            loading: dotProp.set(state[type].shareCodes.loading, betId, false),
            errors: dotProp.delete(state[type].shareCodes.errors, betId),
          },
        },
      };
    }

    case types.RECEIVE_SHARE_CODE_ERROR: {
      const error = action.payload;
      const { betId, type } = action.meta;
      return {
        ...state,
        [type]: {
          ...state[type],
          shareCodes: {
            ...state[type].shareCodes,
            loading: dotProp.set(state[type].shareCodes.loading, betId, false),
            errors: dotProp.set(
              state[type].shareCodes.errors,
              betId,
              error.message,
            ),
          },
        },
      };
    }

    // get collaborators list

    case types.REQUEST_COLLABORATORS: {
      const { betId, type } = action.meta;
      let nextState = state;
      nextState = dotProp.set(
        state,
        `${type}.collaborators.loading.${betId}`,
        true,
      );
      nextState = dotProp.delete(
        nextState,
        `${type}.collaborators.errors.${betId}`,
      );
      return nextState;
    }

    case types.RECEIVE_COLLABORATORS: {
      const list = action.payload;
      const { betId, type } = action.meta;
      return {
        ...state,
        [type]: {
          ...state[type],
          collaborators: {
            resolved: dotProp.set(
              state[type].collaborators.resolved,
              betId,
              list,
            ),
            loading: dotProp.set(
              state[type].collaborators.loading,
              betId,
              false,
            ),
            errors: dotProp.delete(state[type].collaborators.errors, betId),
          },
        },
      };
    }

    case types.RECEIVE_COLLABORATORS_ERROR: {
      const error = action.payload;
      const { betId, type } = action.meta;
      return {
        ...state,
        [type]: {
          ...state[type],
          collaborators: {
            ...state[type].collaborators,
            loading: dotProp.set(
              state[type].collaborators.loading,
              betId,
              false,
            ),
            errors: dotProp.set(
              state[type].collaborators.errors,
              betId,
              error.status,
            ),
          },
        },
      };
    }

    // create collaborator

    case types.REQUEST_CREATE_COLLABORATOR: {
      const { betId, type } = action.meta;
      let nextState = state;
      nextState = dotProp.set(
        state,
        `${type}.collaborators.loading.${betId}`,
        true,
      );
      nextState = dotProp.delete(
        nextState,
        `${type}.collaborators.errors.${betId}`,
      );
      return nextState;
    }

    case types.RECEIVE_CREATE_COLLABORATOR: {
      const { id } = action.payload;
      const { betId, created, email, type, collaborateType } = action.meta;
      const newItem = { id, email, type: collaborateType, created };
      const list = [...state[type].collaborators.resolved[betId], newItem];

      return {
        ...state,
        [type]: {
          ...state[type],
          collaborators: {
            resolved: dotProp.set(
              state[type].collaborators.resolved,
              betId,
              list,
            ),
            loading: dotProp.set(
              state[type].collaborators.loading,
              betId,
              false,
            ),
            errors: dotProp.delete(state[type].collaborators.errors, betId),
          },
        },
      };
    }

    case types.RECEIVE_CREATE_COLLABORATOR_ERROR: {
      const error = action.payload;
      const { betId, type } = action.meta;

      return {
        ...state,
        [type]: {
          ...state[type],
          collaborators: {
            ...state[type].collaborators,
            loading: dotProp.set(
              state[type].collaborators.loading,
              betId,
              false,
            ),
            errors: dotProp.set(
              state[type].collaborators.errors,
              betId,
              error.status,
            ),
          },
        },
      };
    }

    // update collaborator

    case types.REQUEST_UPDATE_COLLABORATOR: {
      const { betId, type } = action.meta;
      let nextState = state;
      nextState = dotProp.set(
        state,
        `${type}.collaborators.loading.${betId}`,
        true,
      );
      nextState = dotProp.delete(
        nextState,
        `${type}.collaborators.errors.${betId}`,
      );
      return nextState;
    }

    case types.RECEIVE_UPDATE_COLLABORATOR: {
      const { betId, collaborateType, userId, type } = action.meta;

      const nextList = state[type].collaborators.resolved[betId].map(e =>
        e.id === userId ? dotProp.set(e, 'type', collaborateType) : e,
      );

      return {
        ...state,
        [type]: {
          ...state[type],
          collaborators: {
            resolved: dotProp.set(
              state[type].collaborators.resolved,
              betId,
              nextList,
            ),
            loading: dotProp.set(
              state[type].collaborators.loading,
              betId,
              false,
            ),
            errors: dotProp.delete(state[type].collaborators.errors, betId),
          },
        },
      };
    }

    case types.RECEIVE_UPDATE_COLLABORATOR_ERROR: {
      const error = action.payload;
      const { betId, type } = action.meta;
      return {
        ...state,
        [type]: {
          ...state[type],
          collaborators: {
            ...state[type].collaborators,
            loading: dotProp.set(
              state[type].collaborators.loading,
              betId,
              false,
            ),
            errors: dotProp.set(
              state[type].collaborators.errors,
              betId,
              error.status,
            ),
          },
        },
      };
    }

    // delete collaborator

    case types.REQUEST_DELETE_COLLABORATOR: {
      const { betId, type } = action.meta;
      let nextState = state;
      nextState = dotProp.set(
        state,
        `${type}.collaborators.loading.${betId}`,
        true,
      );
      nextState = dotProp.delete(
        nextState,
        `${type}.collaborators.errors.${betId}`,
      );
      return nextState;
    }

    case types.RECEIVE_DELETE_COLLABORATOR: {
      const { betId, userId, type } = action.meta;
      const newList = state[type].collaborators.resolved[betId].filter(
        u => u.id !== userId,
      );

      return {
        ...state,
        [type]: {
          ...state[type],
          collaborators: {
            resolved: dotProp.set(
              state[type].collaborators.resolved,
              betId,
              newList,
            ),
            loading: dotProp.set(
              state[type].collaborators.loading,
              betId,
              false,
            ),
            errors: dotProp.delete(state[type].collaborators.errors, betId),
          },
        },
      };
    }

    case types.RECEIVE_DELETE_COLLABORATOR_ERROR: {
      const error = action.payload;
      const { betId, type } = action.meta;
      return {
        ...state,
        [type]: {
          ...state[type],
          collaborators: {
            ...state[type].collaborators,
            loading: dotProp.set(
              state[type].collaborators.loading,
              betId,
              false,
            ),
            errors: dotProp.set(
              state[type].collaborators.errors,
              betId,
              error.status,
            ),
          },
        },
      };
    }

    // resent collaborator invites

    case types.REQUEST_RESEND_COLLABORATOR_INVITE: {
      const { betId, type, collaboratorId } = action.meta;
      return dotProp.set(
        state,
        `${type}.resentInvites.loading.${betId}.${collaboratorId}`,
        true,
      );
    }

    case types.RECEIVE_RESEND_COLLABORATOR_INVITE: {
      const { betId, type, collaboratorId } = action.meta;
      let nextState = state;
      nextState = dotProp.set(
        state,
        `${type}.resentInvites.resolved.${betId}.${collaboratorId}`,
        true,
      );
      nextState = dotProp.delete(
        nextState,
        `${type}.resentInvites.loading.${betId}.${collaboratorId}`,
      );
      return nextState;
    }

    case types.CLEAR_RESENT_INVITES_DATA: {
      const { type, betId } = action.meta;
      let nextState = state;
      nextState = dotProp.delete(
        state,
        `${type}.resentInvites.resolved.${betId}`,
      );
      nextState = dotProp.delete(
        nextState,
        `${type}.resentInvites.loading.${betId}`,
      );
      return nextState;
    }

    // Invites handling

    case types.REQUEST_INVITE:
    case types.REQUEST_SET_INVITE_ALIAS: {
      const { betId, type } = action.meta;
      return dotProp.set(state, `${type}.invites.loading.${betId}`, true);
    }

    case types.RECEIVE_INVITE: {
      const { betId, type } = action.meta;
      let nextState = state;
      nextState = dotProp.set(
        state,
        `${type}.invites.resolved.${betId}`,
        action.payload,
      );
      nextState = dotProp.delete(nextState, `${type}.invites.loading.${betId}`);
      return nextState;
    }

    case types.RECEIVE_INVITE_ERROR: {
      const { betId, type } = action.meta;
      let nextState = state;
      nextState = dotProp.set(
        state,
        `${type}.invites.errors.${betId}`,
        action.payload,
      );
      nextState = dotProp.delete(nextState, `${type}.invites.loading.${betId}`);
      nextState = dotProp.delete(
        nextState,
        `${type}.invites.resolved.${betId}`,
      );
      return nextState;
    }

    case types.RECEIVE_SET_INVITE_ALIAS: {
      const { betId, type } = action.meta;
      let nextState = state;
      nextState = dotProp.set(
        state,
        `${type}.invites.resolved.${betId}`,
        'REDIRECT',
      );
      nextState = dotProp.delete(nextState, `${type}.invites.loading.${betId}`);
      return nextState;
    }

    case types.RECEIVE_DELETE_INVITE: {
      const { betId, type } = action.meta;
      let nextState = state;
      nextState = dotProp.set(
        state,
        `${type}.invites.resolved.${betId}`,
        'DELETED',
      );
      nextState = dotProp.delete(nextState, `${type}.invites.loading.${betId}`);
      return nextState;
    }

    case types.CLEAR_INVITE_DATA: {
      const { type, betId } = action.meta;
      let nextState = state;
      nextState = dotProp.delete(state, `${type}.invites.resolved.${betId}`);
      nextState = dotProp.delete(nextState, `${type}.invites.loading.${betId}`);
      nextState = dotProp.delete(nextState, `${type}.invites.errors.${betId}`);
      return nextState;
    }

    // get comment list

    case types.REQUEST_LIST_COMMENTS: {
      const { betId, type } = action.meta;
      let nextState = state;
      nextState = dotProp.set(state, `${type}.comments.loading.${betId}`, true);
      nextState = dotProp.delete(nextState, `${type}.comments.errors.${betId}`);
      return nextState;
    }

    case types.RECEIVE_LIST_COMMENTS: {
      const list = Array.isArray(action.payload)
        ? [...action.payload].sort(commentOldestFirstSorter)
        : [];
      const { betId, type } = action.meta;
      return {
        ...state,
        [type]: {
          ...state[type],
          comments: {
            resolved: dotProp.set(state[type].comments.resolved, betId, list),
            loading: dotProp.set(state[type].comments.loading, betId, false),
            errors: dotProp.delete(state[type].comments.errors, betId),
          },
        },
      };
    }

    case types.RECEIVE_LIST_COMMENTS_ERROR: {
      const error = action.payload;
      const { betId, type } = action.meta;
      return {
        ...state,
        [type]: {
          ...state[type],
          comments: {
            ...state[type].comments,
            loading: dotProp.set(state[type].comments.loading, betId, false),
            errors: dotProp.set(
              state[type].comments.errors,
              betId,
              error.status,
            ),
          },
        },
      };
    }

    case types.RECEIVE_CLAIM_STATUS: {
      const status = action.payload;
      const { betId, type } = action.meta;
      return {
        ...state,
        [type]: {
          ...state[type],
          claimStatus: {
            resolved: dotProp.set(
              state[type].claimStatus.resolved,
              betId,
              status,
            ),
            loading: dotProp.set(state[type].claimStatus.loading, betId, false),
            errors: dotProp.delete(state[type].claimStatus.errors, betId),
          },
        },
      };
    }

    case types.EXPIRE_CLAIM: {
      const { betId, type } = action.meta;
      const newClaimStatus = { claimed: false };
      return dotProp.set(
        state,
        `${type}.claimStatus.resolved.${betId}`,
        newClaimStatus,
      );
    }

    case types.STOMP_RECEIVE_DATA: {
      const { kind, data } = action.payload;

      if (kind === SHARED_BET_COMMENT) {
        const { betType: type, gameId: betId, comment, id } = data;

        const prevComments = state[type].comments.resolved[betId] ?? [];

        let newCommentsArray;
        const existingComment = prevComments.find(
          prevComment => prevComment.id === id,
        );

        if (existingComment) {
          if (comment) {
            // update old comment!
            newCommentsArray = prevComments.map(prevComment =>
              prevComment.id === id ? data : prevComment,
            );
          } else {
            // Delete existing comment
            newCommentsArray = prevComments.filter(
              prevComment => prevComment.id !== id,
            );
          }
        } else {
          newCommentsArray = [...prevComments, data].sort(
            commentOldestFirstSorter,
          );
        }

        return dotProp.set(
          state,
          `${type}.comments.resolved.${betId}`,
          newCommentsArray,
        );
      }
      if (kind === SHARED_BET_CLAIM_UPDATE) {
        const { betType: type, gameId: betId, status } = data;
        return dotProp.set(
          state,
          `${type}.claimStatus.resolved.${betId}`,
          status,
        );
      }
      if (kind === SHARED_BET_UPDATE_ATG || kind === SHARED_BET_UPDATE_SPORT) {
        console.warn('STOMP BET UPDATE!', kind);
        const type = kind === SHARED_BET_UPDATE_ATG ? 'atg' : 'sport';
        const { data } = action.payload;
        return dotProp.set(
          state,
          `${type}.betUpdates.resolved.${data.betAndStake.bet.id}`,
          data,
        );
      }

      return state;
    }

    default:
      return state;
  }
}
