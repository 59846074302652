export const CHANGE_MENU_CATEGORY = 'CHANGE_MENU_CATEGORY';
export const SET_SELECTED_MENU_ITEM = 'SET_SELECTED_MENU_ITEM';
export const CLEAR_SELECTED_MENU_ITEM = 'CLEAR_SELECTED_MENU_ITEM';
export const CHANGE_DISABLED_MENU_ITEMS = 'CHANGE_DISABLED_MENU_ITEMS';
export const TOGGLE_MANUAL_UPDATE_BUTTON = 'TOGGLE_MANUAL_UPDATE_BUTTON';

export const UPDATE_PAGE_VISIBILITY = 'UPDATE_PAGE_VISIBILITY';
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const CHANGE_UI_SCALE = 'CHANGE_UI_SCALE';
export const ADD_SITE_MESSAGE = 'ADD_SITE_MESSAGE';
export const DISMISS_SITE_MESSAGE_BY_SERVER_ID =
  'DISMISS_SITE_MESSAGE_BY_SERVER_ID';
export const DISMISS_SITE_MESSAGE_BY_CLIENT_ID =
  'DISMISS_SITE_MESSAGE_BY_CLIENT_ID';
export const DISMISS_SITE_MESSAGE_BY_TYPE = 'DISMISS_SITE_MESSAGE_BY_TYPE';
export const LOCATION_PATHNAME_CHANGE = 'LOCATION_PATHNAME_CHANGE';

export const TOGGLE_USER_TEMPLATES_VISIBILITY =
  'TOGGLE_USER_TEMPLATES_VISIBILITY';
export const TOGGLE_JOKER_TEMPLATES_VISIBILITY =
  'TOGGLE_JOKER_TEMPLATES_VISIBILITY';

export const REQUEST_PROMO_MESSAGE = 'REQUEST_PROMO_MESSAGE';
export const RECEIVE_PROMO_MESSAGE = 'RECEIVE_PROMO_MESSAGE';
export const RECEIVE_PROMO_ERROR = 'RECEIVE_PROMO_ERROR';

export const REQUEST_ALERT_MESSAGE = 'REQUEST_ALERT_MESSAGE';
export const RECEIVE_ALERT_MESSAGE = 'RECEIVE_ALERT_MESSAGE';
export const RECEIVE_ALERT_ERROR = 'RECEIVE_ALERT_ERROR';

export const SHOW_DIALOG = 'SHOW_DIALOG';
export const POP_DIALOG = 'POP_DIALOG';
export const POP_DIALOG_BY_TYPE = 'POP_DIALOG_BY_TYPE';
export const TOGGLE_LOCK_DIALOG = 'TOGGLE_LOCK_DIALOG';

export const REQUEST_CURRENT_USER = 'REQUEST_CURRENT_USER';
export const RECEIVE_CURRENT_USER = 'RECEIVE_CURRENT_USER';
export const RECEIVE_CURRENT_USER_ERROR = 'RECEIVE_CURRENT_USER_ERROR';
export const REQUEST_LOGIN_USER = 'REQUEST_LOGIN_USER';
export const RECEIVE_LOGIN_USER = 'RECEIVE_LOGIN_USER';
export const RECEIVE_LOGIN_USER_ERROR = 'RECEIVE_LOGIN_USER_ERROR';
export const REQUEST_LOGOUT_USER = 'REQUEST_LOGOUT_USER';
export const RECEIVE_LOGOUT_USER = 'RECEIVE_LOGOUT_USER';
export const RECEIVE_LOGOUT_USER_ERROR = 'RECEIVE_LOGOUT_USER_ERROR';
export const SET_USER = 'SET_USER';
export const DELETE_USER = 'DELETE_USER';
export const UNAUTHORIZED_REQUEST_ERROR = 'UNAUTHORIZED_REQUEST_ERROR';

export const REQUEST_INSTRUCTIONS = 'REQUEST_INSTRUCTIONS';
export const RECEIVE_INSTRUCTIONS = 'RECEIVE_INSTRUCTIONS';
export const RECEIVE_INSTRUCTIONS_ERROR = 'RECEIVE_INSTRUCTIONS_ERROR';

export const STOMP_SUBSCRIBE = 'STOMP_SUBSCRIBE';
export const STOMP_UNSUBSCRIBE = 'STOMP_UNSUBSCRIBE';
export const STOMP_RECEIVE_DATA = 'STOMP_RECEIVE_DATA';

export const TRAIS_SOCKET_SUBSCRIBE = 'TRAIS_SOCKET_ALERT_SUBSCRIBE';
export const TRAIS_SOCKET_UNSUBSCRIBE = 'TRAIS_SOCKET_ALERT_UNSUBSCRIBE';
export const TRAIS_SOCKET_RECEIVE_DATA = 'TRAIS_SOCKET_RECEIVE_DATA';

export const REQUEST_UPLOAD_BET = 'REQUEST_UPLOAD_BET';
export const RECEIVE_UPLOAD_BET = 'RECEIVE_UPLOAD_BET';
export const RECEIVE_UPLOAD_BET_ERROR = 'RECEIVE_UPLOAD_BET_ERROR';
export const CLEAR_ARCHIVE_UPLOAD_ERROR = 'CLEAR_ARCHIVE_UPLOAD_ERROR';

export const TRACK_USER = 'TRACK_USER';

export const REQUEST_SETTINGS = 'REQUEST_SETTINGS';
export const RECEIVE_SETTINGS = 'RECEIVE_SETTINGS';
export const RECEIVE_SETTINGS_ERROR = 'RECEIVE_SETTINGS_ERROR';
export const REQUEST_UPDATE_SETTINGS = 'REQUEST_UPDATE_SETTINGS';
export const RECEIVE_UPDATE_SETTINGS = 'RECEIVE_UPDATE_SETTINGS';
export const RECEIVE_UPDATE_SETTINGS_ERROR = 'RECEIVE_UPDATE_SETTINGS_ERROR';

export const GTM_EVENT = 'GTM_EVENT';

export const REQUEST_COLLABORATORS = 'REQUEST_COLLABORATORS';
export const RECEIVE_COLLABORATORS = 'RECEIVE_COLLABORATORS';
export const RECEIVE_COLLABORATORS_ERROR = 'RECEIVE_COLLABORATORS_ERROR';

export const REQUEST_CREATE_COLLABORATOR = 'REQUEST_CREATE_COLLABORATOR';
export const RECEIVE_CREATE_COLLABORATOR = 'RECEIVE_CREATE_COLLABORATOR';
export const RECEIVE_CREATE_COLLABORATOR_ERROR =
  'RECEIVE_CREATE_COLLABORATOR_ERROR';

export const REQUEST_UPDATE_COLLABORATOR = 'REQUEST_UPDATE_COLLABORATOR';
export const RECEIVE_UPDATE_COLLABORATOR = 'RECEIVE_UPDATE_COLLABORATOR';
export const RECEIVE_UPDATE_COLLABORATOR_ERROR =
  'RECEIVE_UPDATE_COLLABORATOR_ERROR';

export const REQUEST_DELETE_COLLABORATOR = 'REQUEST_DELETE_COLLABORATOR';
export const RECEIVE_DELETE_COLLABORATOR = 'RECEIVE_DELETE_COLLABORATOR';
export const RECEIVE_DELETE_COLLABORATOR_ERROR =
  'RECEIVE_DELETE_COLLABORATOR_ERROR';

export const REQUEST_INVITE = 'REQUEST_INVITE';
export const RECEIVE_INVITE = 'RECEIVE_INVITE';
export const RECEIVE_INVITE_ERROR = 'RECEIVE_INVITE_ERROR';

export const REQUEST_SET_INVITE_ALIAS = 'REQUEST_SET_INVITE_ALIAS';
export const RECEIVE_SET_INVITE_ALIAS = 'RECEIVE_SET_INVITE_ALIAS';
export const RECEIVE_SET_INVITE_ALIAS_ERROR = 'RECEIVE_SET_INVITE_ALIAS_ERROR';

export const REQUEST_DELETE_INVITE = 'REQUEST_DELETE_INVITE';
export const RECEIVE_DELETE_INVITE = 'RECEIVE_DELETE_INVITE';
export const RECEIVE_DELETE_INVITE_ERROR = 'RECEIVE_DELETE_INVITE_ERROR';

export const CLEAR_INVITE_DATA = 'CLEAR_INVITE_DATA';

export const REQUEST_RESEND_COLLABORATOR_INVITE =
  'REQUEST_RESEND_COLLABORATOR_INVITE';
export const RECEIVE_RESEND_COLLABORATOR_INVITE =
  'RECEIVE_RESEND_COLLABORATOR_INVITE';
export const RECEIVE_RESEND_COLLABORATOR_INVITE_ERROR =
  'RECEIVE_RESEND_COLLABORATOR_INVITE_ERROR';

export const CLEAR_RESENT_INVITES_DATA = 'CLEAR_RESENT_INVITES_DATA';

export const REQUEST_SHARE_CODE = 'REQUEST_SHARE_CODE';
export const RECEIVE_SHARE_CODE = 'RECEIVE_SHARE_CODE';
export const RECEIVE_SHARE_CODE_ERROR = 'RECEIVE_SHARE_CODE_ERROR';

export const REQUEST_ADD_COMMENT = 'REQUEST_ADD_COMMENT';
export const RECEIVE_ADD_COMMENT = 'RECEIVE_ADD_COMMENT';
export const RECEIVE_ADD_COMMENT_ERROR = 'RECEIVE_ADD_COMMENT_ERROR';

export const REQUEST_LIST_COMMENTS = 'REQUEST_LIST_COMMENTS';
export const RECEIVE_LIST_COMMENTS = 'RECEIVE_LIST_COMMENTS';
export const RECEIVE_LIST_COMMENTS_ERROR = 'RECEIVE_LIST_COMMENTS_ERROR';

export const REQUEST_CLAIM_BET = 'REQUEST_CLAIM_BET';
export const RECEIVE_CLAIM_BET = 'RECEIVE_CLAIM_BET';
export const RECEIVE_CLAIM_BET_ERROR = 'RECEIVE_CLAIM_BET_ERROR';

export const REQUEST_RELEASE_BET = 'REQUEST_RELEASE_BET';
export const RECEIVE_RELEASE_BET = 'RECEIVE_RELEASE_BET';
export const RECEIVE_RELEASE_BET_ERROR = 'RECEIVE_RELEASE_BET_ERROR';

export const EXPIRE_CLAIM = 'EXPIRE_CLAIM';

export const REQUEST_CLAIM_STATUS = 'REQUEST_CLAIM_STATUS';
export const RECEIVE_CLAIM_STATUS = 'RECEIVE_CLAIM_STATUS';
export const RECEIVE_CLAIM_STATUS_ERROR = 'RECEIVE_CLAIM_STATUS_ERROR';

export const REQUEST_CHANGE_LIVE_PARTICIPATION =
  'REQUEST_CHANGE_LIVE_PARTICIPATION';
export const RECEIVE_CHANGE_LIVE_PARTICIPATION =
  'RECEIVE_CHANGE_LIVE_PARTICIPATION';
export const RECEIVE_CHANGE_LIVE_PARTICIPATION_ERROR =
  'RECEIVE_CHANGE_LIVE_PARTICIPATION_ERROR';

export const REQUEST_OFFERS = 'REQUEST_OFFERS';
export const RECEIVE_OFFERS = 'RECEIVE_OFFERS';
export const RECEIVE_OFFERS_ERROR = 'RECEIVE_OFFERS_ERROR';

export const REQUEST_SHARED_WITH_USERS = 'REQUEST_SHARED_WITH_USERS';
export const RECEIVE_SHARED_WITH_USERS = 'RECEIVE_SHARED_WITH_USERS';
export const RECEIVE_SHARED_WITH_USERS_ERROR =
  'RECEIVE_SHARED_WITH_USERS_ERROR';

// MONITOR ACTION TYPES

export const REQUEST_MY_HORSES = 'REQUEST_MY_HORSES';
export const RECEIVE_MY_HORSES = 'RECEIVE_MY_HORSES';
export const RECEIVE_MY_HORSES_ERROR = 'RECEIVE_MY_HORSES_ERROR';

export const REQUEST_HORSE_DETAILS = 'REQUEST_HORSE_DETAILS';
export const RECEIVE_HORSE_DETAILS = 'RECEIVE_HORSE_DETAILS';
export const RECEIVE_HORSE_DETAILS_ERROR = 'RECEIVE_HORSE_DETAILS_ERROR';

export const RESOLVE_HORSE_DETAILS = 'RESOLVE_HORSE_DETAILS';

export const REQUEST_ADD_HORSE = 'REQUEST_ADD_HORSE';
export const RECEIVE_ADD_HORSE = 'RECEIVE_ADD_HORSE';
export const RECEIVE_ADD_HORSE_ERROR = 'RECEIVE_ADD_HORSE_ERROR';

export const REQUEST_EDIT_HORSE = 'REQUEST_EDIT_HORSE';
export const RECEIVE_EDIT_HORSE = 'RECEIVE_EDIT_HORSE';
export const RECEIVE_EDIT_HORSE_ERROR = 'RECEIVE_EDIT_HORSE_ERROR';

export const REQUEST_DELETE_HORSE = 'REQUEST_DELETE_HORSE';
export const RECEIVE_DELETE_HORSE = 'RECEIVE_DELETE_HORSE';
export const RECEIVE_DELETE_HORSE_ERROR = 'RECEIVE_DELETE_HORSE_ERROR';

export const REQUEST_START_COMMENTS = 'REQUEST_START_COMMENTS';
export const RECEIVE_START_COMMENTS = 'RECEIVE_START_COMMENTS';
export const RECEIVE_START_COMMENTS_ERROR = 'RECEIVE_START_COMMENTS_ERROR';

export const REQUEST_ADD_START_COMMENT = 'REQUEST_ADD_START_COMMENT';
export const RECEIVE_ADD_START_COMMENT = 'RECEIVE_ADD_START_COMMENT';
export const RECEIVE_ADD_START_COMMENT_ERROR =
  'RECEIVE_ADD_START_COMMENT_ERROR';

export const REQUEST_EDIT_START_COMMENT = 'REQUEST_EDIT_START_COMMENT';
export const RECEIVE_EDIT_START_COMMENT = 'RECEIVE_EDIT_START_COMMENT';
export const RECEIVE_EDIT_START_COMMENT_ERROR =
  'RECEIVE_EDIT_START_COMMENT_ERROR';

export const REQUEST_DELETE_START_COMMENT = 'REQUEST_DELETE_START_COMMENT';
export const RECEIVE_DELETE_START_COMMENT = 'RECEIVE_DELETE_START_COMMENT';
export const RECEIVE_DELETE_START_COMMENT_ERROR =
  'RECEIVE_DELETE_START_COMMENT_ERROR';

export const REQUEST_MONITOR_SETTINGS = 'REQUEST_MONITOR_SETTINGS';
export const REQUEST_EDIT_MONITOR_SETTINGS = 'REQUEST_EDIT_MONITOR_SETTINGS';
export const RECEIVE_MONITOR_SETTINGS = 'RECEIVE_MONITOR_SETTINGS';
export const RECEIVE_MONITOR_SETTINGS_ERROR = 'RECEIVE_MONITOR_SETTINGS_ERROR';

export const REQUEST_ROUND = 'REQUEST_ROUND';
export const RECEIVE_ROUND = 'RECEIVE_ROUND';
export const RECEIVE_ROUND_ERROR = 'RECEIVE_ROUND_ERROR';

export const REQUEST_TRAIS_HORSE = 'REQUEST_TRAIS_HORSE';
export const RECEIVE_TRAIS_HORSE = 'RECEIVE_TRAIS_HORSE';
export const RECEIVE_TRAIS_HORSE_ERROR = 'RECEIVE_TRAIS_HORSE_ERROR';

export const REQUEST_ATG_TREND_DATA = 'REQUEST_ATG_TREND_DATA';
export const RECEIVE_ATG_TREND_DATA = 'RECEIVE_ATG_TREND_DATA';
export const RECEIVE_ATG_TREND_DATA_ERROR = 'RECEIVE_ATG_TREND_DATA_ERROR';

export const REQUEST_TODAYS_BEST_BET = 'REQUEST_TODAYS_BEST_BET';
export const RECEIVE_TODAYS_BEST_BET = 'RECEIVE_TODAYS_BEST_BET';
export const RECEIVE_TODAYS_BEST_BET_ERROR = 'RECEIVE_TODAYS_BEST_BET_ERROR';

export const REQUEST_WIZ_SYSTEMS = 'REQUEST_WIZ_SYSTEMS';
export const RECEIVE_WIZ_SYSTEMS = 'RECEIVE_WIZ_SYSTEMS';
export const RECEIVE_WIZ_SYSTEMS_ERROR = 'RECEIVE_WIZ_SYSTEMS_ERROR';

export const REQUEST_LATEST_TIPS = 'REQUEST_LATEST_TIPS';
export const RECEIVE_LATEST_TIPS = 'RECEIVE_LATEST_TIPS';
export const RECEIVE_LATEST_TIPS_ERROR = 'RECEIVE_LATEST_TIPS_ERROR';

export const REQUEST_LATEST_NEWS = 'REQUEST_LATEST_NEWS';
export const RECEIVE_LATEST_NEWS = 'RECEIVE_LATEST_NEWS';
export const RECEIVE_LATEST_NEWS_ERROR = 'RECEIVE_LATEST_NEWS_ERROR';

export const CHANGE_SPORT_SECTION_VISIBILITY =
  'CALENDAR/CHANGE_SPORT_SECTION_VISIBILITY';
export const CHANGE_ATG_SECTION_VISIBILITY =
  'CALENDAR/CHANGE_ATG_SECTION_VISIBILITY';
export const CHANGE_ATG_LIST_VISIBILITY = 'CALENDAR/CHANGE_ATG_LIST_VISIBILITY';
export const CHANGE_SPORT_LIST_VISIBILITY =
  'CALENDAR/CHANGE_SPORT_LIST_VISIBILITY';

export const REQUEST_ATG_CALENDAR = 'CALENDAR/REQUEST_ATG_CALENDAR';
export const RECEIVE_ATG_CALENDAR = 'CALENDAR/RECEIVE_ATG_CALENDAR';
export const RECEIVE_ATG_CALENDAR_ERROR = 'CALENDAR/RECEIVE_ATG_CALENDAR_ERROR';

export const REQUEST_ATG_JACKPOTS = 'CALENDAR/REQUEST_ATG_JACKPOTS';
export const RECEIVE_ATG_JACKPOTS = 'CALENDAR/RECEIVE_ATG_JACKPOTS';
export const RECEIVE_ATG_JACKPOTS_ERROR = 'CALENDAR/RECEIVE_ATG_JACKPOTS_ERROR';

export const REQUEST_SPORT_CALENDAR = 'CALENDAR/REQUEST_SPORT_CALENDAR';
export const RECEIVE_SPORT_CALENDAR = 'CALENDAR/RECEIVE_SPORT_CALENDAR';
export const RECEIVE_SPORT_CALENDAR_ERROR =
  'CALENDAR/RECEIVE_SPORT_CALENDAR_ERROR';

export const REQUEST_SPORT_JACKPOTS = 'CALENDAR/REQUEST_SPORT_JACKPOTS';
export const RECEIVE_SPORT_JACKPOTS = 'CALENDAR/RECEIVE_SPORT_JACKPOTS';
export const RECEIVE_SPORT_JACKPOTS_ERROR =
  'CALENDAR/RECEIVE_SPORT_JACKPOTS_ERROR';

export const REQUEST_BET_TIPS = 'CALENDAR/REQUEST_BET_TIPS';
export const RECEIVE_BET_TIPS = 'CALENDAR/RECEIVE_BET_TIPS';
export const RECEIVE_BET_TIPS_ERROR = 'CALENDAR/RECEIVE_BET_TIPS_ERROR';

export const REQUEST_SPORT_ANALYSIS = 'CALENDAR/REQUEST_SPORT_ANALYSIS ';
export const RECEIVE_SPORT_ANALYSIS = 'CALENDAR/RECEIVE_SPORT_ANALYSIS ';
export const RECEIVE_SPORT_ANALYSIS_ERROR =
  'CALENDAR/RECEIVE_SPORT_ANALYSIS_ERROR ';
