import classnames from 'classnames';
import { node, string } from 'prop-types';

const Icon = ({ name, className, children, ...props }) => (
  <svg
    className={classnames('svg-icon', `icon-${name}`, className)}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
  </svg>
);

Icon.propTypes = {
  name: string.isRequired,
  className: string,
  children: node.isRequired,
  viewBox: string.isRequired,
};

export default Icon;
