import { RSAA } from 'redux-api-middleware';

import * as types from 'shared/redux/actionTypes';

export function fetchAtgBetTips() {
  return {
    [RSAA]: {
      endpoint: 'game/availableBetTips',
      types: [
        { type: types.REQUEST_BET_TIPS, meta: { kind: 'atg' } },
        { type: types.RECEIVE_BET_TIPS, meta: { kind: 'atg' } },
        {
          type: types.RECEIVE_BET_TIPS_ERROR,
          meta: { errorType: 'discrete', kind: 'atg' },
        },
      ],
    },
  };
}

export function fetchSportBetTips() {
  return {
    [RSAA]: {
      endpoint: 'sport/availableBetTips',
      types: [
        { type: types.REQUEST_BET_TIPS, meta: { kind: 'sport' } },
        { type: types.RECEIVE_BET_TIPS, meta: { kind: 'sport' } },
        {
          type: types.RECEIVE_BET_TIPS_ERROR,
          meta: { errorType: 'discrete', kind: 'sport' },
        },
      ],
    },
  };
}
