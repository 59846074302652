import { RSAA } from 'redux-api-middleware';

import * as types from 'shared/redux/actionTypes';
import { shares } from 'shared/redux/selectors';

export const shareBet = ({ betId, type }) => ({
  [RSAA]: {
    endpoint: `${type === 'sport' ? 'sport' : 'game'}/betCode/${betId}`,
    method: 'GET',
    types: [
      { type: types.REQUEST_SHARE_CODE, meta: { betId, type } },
      { type: types.RECEIVE_SHARE_CODE, meta: { betId, type } },
      { type: types.RECEIVE_SHARE_CODE_ERROR, meta: { betId, type } },
    ],
  },
});

export const fetchCollaborators = ({ betId, type }) => ({
  [RSAA]: {
    bailout: state => shares.isCollaboratorsLoading(state, { betId, type }),
    endpoint: `${type === 'sport' ? 'sport' : 'game'}/listShares/${betId}`,
    method: 'GET',
    types: [
      { type: types.REQUEST_COLLABORATORS, meta: { betId, type } },
      { type: types.RECEIVE_COLLABORATORS, meta: { betId, type } },
      { type: types.RECEIVE_COLLABORATORS_ERROR, meta: { betId, type } },
    ],
  },
});

export const createCollaborator = ({
  betId,
  email,
  collaborateType,
  type,
}) => ({
  [RSAA]: {
    bailout: state => shares.isCollaboratorsLoading(state, { betId, type }),
    endpoint: `${type === 'sport' ? 'sport' : 'game'}/shareBet/${betId}`,
    method: 'POST',
    body: JSON.stringify({ email, type: collaborateType }),
    types: [
      { type: types.REQUEST_CREATE_COLLABORATOR, meta: { betId, type } },
      {
        type: types.RECEIVE_CREATE_COLLABORATOR,
        meta: { betId, email, type, collaborateType },
      },
      {
        type: types.RECEIVE_CREATE_COLLABORATOR_ERROR,
        meta: { betId, type },
      },
    ],
  },
});

export const updateCollaborator = ({
  betId,
  userId,
  collaborateType,
  type,
}) => ({
  [RSAA]: {
    bailout: state => shares.isCollaboratorsLoading(state, { betId, type }),
    endpoint: `${type === 'sport' ? 'sport' : 'game'}/shareBet/${betId}`,
    method: 'PATCH',
    body: JSON.stringify({ type: collaborateType, id: userId }),
    types: [
      { type: types.REQUEST_UPDATE_COLLABORATOR, meta: { betId, type } },
      {
        type: types.RECEIVE_UPDATE_COLLABORATOR,
        meta: { betId, collaborateType, userId, type },
      },
      { type: types.RECEIVE_UPDATE_COLLABORATOR_ERROR, meta: { betId, type } },
    ],
  },
});

export const deleteCollaborator = ({ betId, userId, type }) => ({
  [RSAA]: {
    bailout: state => shares.isCollaboratorsLoading(state, { betId, type }),
    endpoint: `${
      type === 'sport' ? 'sport' : 'game'
    }/deleteShare/${betId}/${userId}`,
    method: 'DELETE',
    types: [
      { type: types.REQUEST_DELETE_COLLABORATOR, meta: { betId, type } },
      {
        type: types.RECEIVE_DELETE_COLLABORATOR,
        meta: { betId, type, userId },
      },
      { type: types.RECEIVE_DELETE_COLLABORATOR_ERROR, meta: { betId, type } },
    ],
  },
});

export const resendCollaboratorInvite = ({ betId, collaboratorId, type }) => ({
  [RSAA]: {
    endpoint: `${
      type === 'sport' ? 'sport' : 'game'
    }/resendShare/${collaboratorId}`,
    method: 'POST',
    types: [
      {
        type: types.REQUEST_RESEND_COLLABORATOR_INVITE,
        meta: { betId, collaboratorId, type },
      },
      {
        type: types.RECEIVE_RESEND_COLLABORATOR_INVITE,
        meta: { betId, collaboratorId, type },
      },
      {
        type: types.RECEIVE_RESEND_COLLABORATOR_INVITE_ERROR,
        meta: { betId, collaboratorId, type },
      },
    ],
  },
});

export const clearResentInvites = ({ betId, type }) => ({
  type: types.CLEAR_RESENT_INVITES_DATA,
  meta: { betId, type },
});

export const fetchInvite = ({ betId, type }) => ({
  [RSAA]: {
    endpoint: `${type === 'sport' ? 'sport' : 'game'}/invites/${betId}`,
    method: 'GET',
    types: [
      {
        type: types.REQUEST_INVITE,
        meta: { betId, type },
      },
      {
        type: types.RECEIVE_INVITE,
        meta: { betId, type },
      },
      {
        type: types.RECEIVE_INVITE_ERROR,
        meta: { betId, type },
      },
    ],
  },
});

export const setInviteAlias = ({ betId, type, alias }) => ({
  [RSAA]: {
    endpoint: `${type === 'sport' ? 'sport' : 'game'}/invites/${betId}`,
    method: 'POST',
    body: JSON.stringify({ alias }),
    types: [
      {
        type: types.REQUEST_SET_INVITE_ALIAS,
        meta: { betId, type },
      },
      {
        type: types.RECEIVE_SET_INVITE_ALIAS,
        meta: { betId, type },
      },
      {
        type: types.RECEIVE_SET_INVITE_ALIAS_ERROR,
        meta: { betId, type },
      },
    ],
  },
});

export const deleteInvite = ({ betId, type }) => ({
  [RSAA]: {
    endpoint: `${type === 'sport' ? 'sport' : 'game'}/invites/${betId}`,
    method: 'DELETE',
    types: [
      {
        type: types.REQUEST_DELETE_INVITE,
        meta: { betId, type },
      },
      {
        type: types.RECEIVE_DELETE_INVITE,
        meta: { betId, type },
      },
      {
        type: types.RECEIVE_DELETE_INVITE_ERROR,
        meta: { betId, type },
      },
    ],
  },
});

export const clearInvite = ({ betId, type }) => ({
  type: types.CLEAR_INVITE_DATA,
  meta: { betId, type },
});

export const addComment = ({ betId, type, comment }) => ({
  [RSAA]: {
    endpoint: `${type === 'sport' ? 'sport' : 'game'}/addComment/${betId}`,
    method: 'POST',
    body: JSON.stringify({ comment }),
    types: [
      { type: types.REQUEST_ADD_COMMENT, meta: { betId, type } },
      {
        type: types.RECEIVE_ADD_COMMENT,
        meta: { betId, type },
      },
      {
        type: types.RECEIVE_ADD_COMMENT_ERROR,
        meta: { betId, type },
      },
    ],
  },
});

export const updateComment = ({ betId, commentId, type, comment }) => ({
  [RSAA]: {
    endpoint: `${
      type === 'sport' ? 'sport' : 'game'
    }/updateComment/${commentId}`,
    method: 'PUT',
    body: JSON.stringify({ comment }),
    types: [
      { type: types.REQUEST_ADD_COMMENT, meta: { betId, type } },
      {
        type: types.RECEIVE_ADD_COMMENT,
        meta: { betId, type },
      },
      {
        type: types.RECEIVE_ADD_COMMENT_ERROR,
        meta: { betId, type },
      },
    ],
  },
});

export const deleteComment = ({ betId, commentId, type }) => ({
  [RSAA]: {
    endpoint: `${
      type === 'sport' ? 'sport' : 'game'
    }/deleteComment/${commentId}`,
    method: 'DELETE',
    types: [
      { type: types.REQUEST_ADD_COMMENT, meta: { betId, type } },
      {
        type: types.RECEIVE_ADD_COMMENT,
        meta: { betId, type },
      },
      {
        type: types.RECEIVE_ADD_COMMENT_ERROR,
        meta: { betId, type },
      },
    ],
  },
});

export const listComments = ({ betId, type }) => ({
  [RSAA]: {
    endpoint: `${type === 'sport' ? 'sport' : 'game'}/listComments/${betId}`,
    method: 'GET',
    types: [
      { type: types.REQUEST_LIST_COMMENTS, meta: { betId, type } },
      {
        type: types.RECEIVE_LIST_COMMENTS,
        meta: { betId, type },
      },
      {
        type: types.RECEIVE_LIST_COMMENTS_ERROR,
        meta: { betId, type },
      },
    ],
  },
});

export const claimBet = ({ betId, type }) => ({
  [RSAA]: {
    endpoint: `${type === 'sport' ? 'sport' : 'game'}/claimBet/${betId}`,
    method: 'POST',
    types: [
      { type: types.REQUEST_CLAIM_BET, meta: { betId, type } },
      {
        type: types.RECEIVE_CLAIM_BET,
        meta: { betId, type },
      },
      {
        type: types.RECEIVE_CLAIM_BET_ERROR,
        meta: { betId, type },
      },
    ],
  },
});

export const expireClaim = ({ betId, type }) => ({
  type: types.EXPIRE_CLAIM,
  meta: { betId, type },
});

export const releaseBet = ({ betId, type }) => ({
  [RSAA]: {
    endpoint: `${type === 'sport' ? 'sport' : 'game'}/releaseClaim/${betId}`,
    method: 'POST',
    types: [
      { type: types.REQUEST_RELEASE_BET, meta: { betId, type } },
      {
        type: types.RECEIVE_RELEASE_BET,
        meta: { betId, type },
      },
      {
        type: types.RECEIVE_RELEASE_BET_ERROR,
        meta: { betId, type },
      },
    ],
  },
});

export const getClaimStatus = ({ betId, type }) => ({
  [RSAA]: {
    endpoint: `${
      type === 'sport' ? 'sport' : 'game'
    }/claimInformation/${betId}`,
    method: 'GET',
    types: [
      { type: types.REQUEST_CLAIM_STATUS, meta: { betId, type } },
      {
        type: types.RECEIVE_CLAIM_STATUS,
        meta: { betId, type },
      },
      {
        type: types.RECEIVE_CLAIM_STATUS_ERROR,
        meta: { betId, type },
      },
    ],
  },
});
