import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

// https://reactcommunity.org/react-transition-group/transition#Transition-prop-nodeRef
const CSSTransitionWithRef = props => {
  const ref = useRef(null);
  return <CSSTransition nodeRef={ref} {...props} />;
};

export default CSSTransitionWithRef;
