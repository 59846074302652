import { RSAA } from 'redux-api-middleware';

import * as types from '../actionTypes';
import { user } from '../selectors';

export const fetchUser = () => ({
  [RSAA]: {
    endpoint: 'user/fetchUser',
    types: [
      types.REQUEST_CURRENT_USER,
      types.RECEIVE_CURRENT_USER,
      types.RECEIVE_CURRENT_USER_ERROR,
    ],
  },
});

export const unAuthorizedRequestError = () => ({
  type: types.UNAUTHORIZED_REQUEST_ERROR,
});

export const setUser = user => ({
  type: types.SET_USER,
  payload: user,
});

export const deleteUser = () => ({
  type: types.DELETE_USER,
});

export const trackUser = (eventName, eventDetails = {}) => ({
  type: types.TRACK_USER,
  payload: {
    eventName,
    eventDetails,
  },
});

export const toggleLiveParticipation = () => ({
  [RSAA]: {
    endpoint: 'user/playJokerLive',
    method: 'POST',
    body: state =>
      JSON.stringify({
        playJokerLive: !user.isLiveParticipant(state),
      }),
    types: [
      types.REQUEST_CHANGE_LIVE_PARTICIPATION,
      types.RECEIVE_CHANGE_LIVE_PARTICIPATION,
      types.RECEIVE_CHANGE_LIVE_PARTICIPATION_ERROR,
    ],
  },
});
