import PropTypes from 'prop-types';

import { ATG_PRODUCTS, SPORT_PRODUCTS } from 'shared/constants/AppConstants';

export const menuItem = PropTypes.shape({
  id: PropTypes.string.isRequired,
  labelProp: PropTypes.string.isRequired,
  path: PropTypes.string,
});

export const menuList = PropTypes.arrayOf(menuItem);

export const atgBetType = PropTypes.oneOf(ATG_PRODUCTS);

export const sportProduct = PropTypes.oneOf(SPORT_PRODUCTS);

export const sportCalendarBetParams = PropTypes.shape({
  product: sportProduct.isRequired,
  drawNr: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(PropTypes.string),
});

export const atgCalendarBetParams = PropTypes.shape({
  betType: atgBetType.isRequired,
  trackKey: PropTypes.number.isRequired,
  date: PropTypes.number.isRequired,
});

export const tipBetParams = PropTypes.shape({
  tipBetId: PropTypes.number.isRequired,
});

export const duplicateBetParams = PropTypes.shape({
  duplicateBetId: PropTypes.number.isRequired,
});

export const createAtgBetParams = PropTypes.oneOfType([
  atgCalendarBetParams,
  tipBetParams,
]);

export const createSportBetParams = PropTypes.oneOfType([
  sportCalendarBetParams,
  tipBetParams,
  duplicateBetParams,
]);
