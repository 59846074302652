import uniqueId from 'lodash/uniqueId';

import * as sharedTypes from 'shared/redux/actionTypes';

export const addSubscription = (destination, kind, meta) => ({
  type: sharedTypes.STOMP_SUBSCRIBE,
  payload: {
    destination,
    kind,
  },
  meta,
});

export const removeSubscription = destination => ({
  type: sharedTypes.STOMP_UNSUBSCRIBE,
  payload: destination,
});

export const onStompMessage = (data, kind, meta = {}) => ({
  type: sharedTypes.STOMP_RECEIVE_DATA,
  payload: { data, kind },
  meta: {
    ...meta,
    clientId: uniqueId('stompMessage_'),
  },
});
