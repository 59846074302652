import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Button from 'shared/components/button/Button';
import { getText } from 'shared/utils/Text';
import { getUrl } from 'shared/utils/urls';

const LiveNoAliasDialog = ({
  closeHandler,
  positive = getText('dialog-confirm-confirm'),
}) => (
  <>
    <h1 className="dialog-title">{getText('menu-popup-live-dialog-title')}</h1>
    <hr />

    <p className="details">
      {getText('menu-popup-live-dialog-details-1')}{' '}
      <a href={getUrl.userpages()}>
        {getText('menu-popup-live-dialog-register')}
      </a>
      .
    </p>

    <p className="details">{getText('menu-popup-live-dialog-details-2')}</p>

    <p className="details">
      {getText('menu-popup-live-dialog-details-3')}{' '}
      <Link to={getUrl.live()}>{getText('menu-popup-live-dialog-more')}</Link>.
    </p>

    <hr />
    <ul className="button-list fgrid fgrid--full">
      <li className="fgrid-cell">
        <Button onClick={closeHandler}>
          <span className="sprite dialog-confirm" />
          <span className="label">{positive}</span>
        </Button>
      </li>
    </ul>
  </>
);

LiveNoAliasDialog.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  positive: PropTypes.string,
};

export default LiveNoAliasDialog;
