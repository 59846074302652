import 'moment/locale/sv';

import moment from 'moment';
import momentTZ from 'moment-timezone';

// https://github.com/moment/moment-timezone/issues/647#issuecomment-439600573
// Ugly hack around issue with setting locale on moment-timezone
moment.locale('sv'); // Set the locale on moment
momentTZ.defineLocale('sv', moment.localeData()._config); // copy locale to moment-timezone
momentTZ.locale('sv'); // apply it to moment-timezone

export { default } from 'moment-timezone';
