import PropTypes from 'prop-types';

import Button from 'shared/components/button/Button';
import * as Text from 'shared/utils/Text';

const AlertDialog = ({
  closeHandler,
  title,
  details,
  positive = Text.get('dialog-alert-confirm'),
}) => (
  <>
    <h1 className="dialog-title">{title}</h1>
    <hr />
    {details && (
      <p
        className="details"
        dangerouslySetInnerHTML={{ __html: details.replace(/\n/g, '<br/>') }}
      />
    )}
    <hr />
    <ul className="button-list fgrid fgrid--full">
      <li className="fgrid-cell">
        <Button onClick={closeHandler}>
          <span className="sprite dialog-confirm" />
          <span className="label">{positive}</span>
        </Button>
      </li>
    </ul>
  </>
);

AlertDialog.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  details: PropTypes.string,
  positive: PropTypes.string,
};

export default AlertDialog;
