import { useSelector } from 'react-redux';

import User from 'shared/components/icon/User';
import { user } from 'shared/redux/selectors';
import * as Text from 'shared/utils/Text';

import styles from './AccountItem.module.scss';

const AccountItem = () => {
  const firstName = useSelector(state => user.getFirstname(state));
  const lastName = useSelector(state => user.getLastname(state));
  const hasName = [firstName, lastName].some(name => name.length > 0);
  return (
    <div className={styles.accountItem}>
      <User />
      {hasName ? (
        <span className={styles.titleUsername}>
          {firstName.length > 0 ? firstName : lastName}
        </span>
      ) : (
        <span className={styles.title}>{` ${Text.get('menu-account')}`}</span>
      )}
    </div>
  );
};

export default AccountItem;
