import { RSAA } from 'redux-api-middleware';

import * as types from '../actionTypes';

export const fetchSharedWithUsers = () => ({
  [RSAA]: {
    endpoint: 'user/sharedWithUsers',
    types: [
      types.REQUEST_SHARED_WITH_USERS,
      types.RECEIVE_SHARED_WITH_USERS,
      types.RECEIVE_SHARED_WITH_USERS_ERROR,
    ],
  },
});
