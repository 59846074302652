import { RSAA } from 'redux-api-middleware';

import * as types from '../actionTypes';

export function fetchOffers() {
  return {
    [RSAA]: {
      endpoint: `/api/checkout/offers/${process.env.REACT_APP_TRAIS_SITE}`,
      types: [
        types.REQUEST_OFFERS,
        types.RECEIVE_OFFERS,
        types.RECEIVE_OFFERS_ERROR,
      ],
    },
  };
}
