import reduce from 'lodash/reduce';
import { combineReducers } from 'redux';

import archiveUpload from 'shared/redux/reducers/archiveUploadReducer';
import betTips from 'shared/redux/reducers/betTipsReducer';
import calendar from 'shared/redux/reducers/calendarReducer';
import contacts from 'shared/redux/reducers/contactsReducer';
import dialogs from 'shared/redux/reducers/dialogReducer';
import instructions from 'shared/redux/reducers/instructionsReducer';
import menu from 'shared/redux/reducers/menuReducer';
import monitor from 'shared/redux/reducers/monitorReducer';
import offers from 'shared/redux/reducers/offersReducer';
import settings from 'shared/redux/reducers/settingsReducer';
import shares from 'shared/redux/reducers/shareReducer';
import ui from 'shared/redux/reducers/uiReducer';
import user from 'shared/redux/reducers/userReducer';

function makeHydratable(reducers) {
  return reduce(
    reducers,
    (acc, reducer, key) => {
      acc[key] = (state, action = {}) => {
        if (action.type === '__HYDRATE_STATE__' && action.key === key) {
          return reducer(action.state, action);
        }
        return reducer(state, action);
      };
      return acc;
    },
    {},
  );
}

export default function createReducer(asyncReducers) {
  return combineReducers(
    makeHydratable({
      ui,
      menu,
      user,
      archiveUpload,
      dialogs,
      instructions,
      monitor,
      settings,
      shares,
      contacts,
      offers,
      calendar,
      betTips,
      ...asyncReducers,
    }),
  );
}
