import config from 'config';
import { RSAA } from 'redux-api-middleware';

import * as actionTypes from '../actionTypes';
import { monitor } from '../selectors';

export const fetchMyHorses = () => ({
  [RSAA]: {
    bailout: state => monitor.myHorsesSelector(state).hasLoadedItems,
    endpoint: `${config.watchmanApi}/horse?includeAllHorses=true`,
    method: 'GET',
    types: [
      actionTypes.REQUEST_MY_HORSES,
      actionTypes.RECEIVE_MY_HORSES,
      actionTypes.RECEIVE_MY_HORSES_ERROR,
    ],
  },
});

export const fetchHorseDetailsAndStartComments =
  horseId => async (dispatch, getState) => {
    await dispatch(fetchHorseDetails(horseId));
    const latestStarts =
      monitor.getHorseDetails(getState(), { horseId })?.latestStarts ?? [];
    if (latestStarts.length > 0) {
      await dispatch(fetchStartComments(latestStarts.map(start => start.id)));
    }
    dispatch({ type: actionTypes.RESOLVE_HORSE_DETAILS, meta: { horseId } });
  };

const fetchHorseDetails = horseId => ({
  [RSAA]: {
    endpoint: `${process.env.REACT_APP_TRAIS_API}/v1/public/horse/${horseId}/?detail=true`,
    method: 'GET',
    types: [
      actionTypes.REQUEST_HORSE_DETAILS,
      { type: actionTypes.RECEIVE_HORSE_DETAILS, meta: { horseId } },
      actionTypes.RECEIVE_HORSE_DETAILS_ERROR,
    ],
  },
});

export const addHorse = (horseId, comment, notify) => ({
  [RSAA]: {
    endpoint: `${config.watchmanApi}/horse`,
    method: 'POST',
    body: JSON.stringify({
      horseId,
      comment: comment || undefined,
      notify,
    }),
    types: [
      actionTypes.REQUEST_ADD_HORSE,
      actionTypes.RECEIVE_ADD_HORSE,
      actionTypes.RECEIVE_ADD_HORSE_ERROR,
    ],
  },
});

export const editHorse = (horseId, { comment, notify }) => ({
  [RSAA]: {
    endpoint: `${config.watchmanApi}/horse/${horseId}`,
    method: 'PUT',
    body: JSON.stringify({ comment, notify }),
    types: [
      { type: actionTypes.REQUEST_EDIT_HORSE, meta: { horseId } },
      actionTypes.RECEIVE_EDIT_HORSE,
      actionTypes.RECEIVE_EDIT_HORSE_ERROR,
    ],
  },
});

export const removeHorse = horseId => ({
  [RSAA]: {
    endpoint: `${config.watchmanApi}/horse/${horseId}`,
    method: 'DELETE',
    types: [
      actionTypes.REQUEST_DELETE_HORSE,
      { type: actionTypes.RECEIVE_DELETE_HORSE, meta: { horseId } },
      actionTypes.RECEIVE_DELETE_HORSE_ERROR,
    ],
  },
});

export const fetchStartComments = startIds => ({
  [RSAA]: {
    endpoint: `${config.watchmanApi}/start?startIds=${startIds.join(',')}`,
    method: 'GET',
    types: [
      actionTypes.REQUEST_START_COMMENTS,
      {
        type: actionTypes.RECEIVE_START_COMMENTS,
        meta: { startIds },
      },
      actionTypes.RECEIVE_START_COMMENTS_ERROR,
    ],
  },
});

export const addStartComment = (startId, comment) => ({
  [RSAA]: {
    endpoint: `${config.watchmanApi}/start`,
    method: 'POST',
    // String property label is currently unused in Joker, but required by backend.
    // For now sending string copy of startId.
    body: JSON.stringify({ startId, comment, label: startId.toString() }),
    types: [
      actionTypes.REQUEST_ADD_START_COMMENT,
      {
        type: actionTypes.RECEIVE_ADD_START_COMMENT,
        meta: { startId, comment },
      },
      actionTypes.RECEIVE_ADD_START_COMMENT_ERROR,
    ],
  },
});

export const editStartComment = (startId, comment) => ({
  [RSAA]: {
    endpoint: `${config.watchmanApi}/start/${startId}`,
    method: 'PUT',
    body: JSON.stringify({ comment }),
    types: [
      actionTypes.REQUEST_EDIT_START_COMMENT,
      {
        type: actionTypes.RECEIVE_EDIT_START_COMMENT,
        meta: { startId, comment },
      },
      actionTypes.RECEIVE_EDIT_START_COMMENT_ERROR,
    ],
  },
});

export const deleteStartComment = startId => ({
  [RSAA]: {
    endpoint: `${config.watchmanApi}/start/${startId}`,
    method: 'DELETE',
    types: [
      actionTypes.REQUEST_DELETE_START_COMMENT,
      {
        type: actionTypes.RECEIVE_DELETE_START_COMMENT,
        meta: { startId },
      },
      actionTypes.RECEIVE_DELETE_START_COMMENT_ERROR,
    ],
  },
});

export const fetchMonitorSettings = () => ({
  [RSAA]: {
    bailout: state => Boolean(monitor.getSettings(state)),
    endpoint: `${config.watchmanApi}/user/horse`,
    method: 'GET',
    types: [
      actionTypes.REQUEST_MONITOR_SETTINGS,
      actionTypes.RECEIVE_MONITOR_SETTINGS,
      actionTypes.RECEIVE_MONITOR_SETTINGS_ERROR,
    ],
  },
});

export const toggleMonitorSetting = property => ({
  [RSAA]: {
    endpoint: `${config.watchmanApi}/user/horse`,
    method: 'PUT',
    body: state => {
      const oldSettings = monitor.getSettings(state);
      let targetArray;
      if (['email', 'mobile'].includes(property)) {
        targetArray = 'methods';
      } else if (['start_reg', 'race_60', 'race_5'].includes(property)) {
        targetArray = 'when';
      } else {
        console.error(`Unknown property: ${property}`);
        throw new Error(`Unknown property: ${property}`);
      }
      const oldArray = oldSettings[targetArray];
      const newArray = oldArray.includes(property)
        ? oldArray.filter(prop => prop !== property)
        : [...oldArray, property];
      const newSettings = {
        ...oldSettings,
        [targetArray]: newArray,
      };
      return JSON.stringify(newSettings);
    },
    types: [
      actionTypes.REQUEST_EDIT_MONITOR_SETTINGS,
      actionTypes.RECEIVE_MONITOR_SETTINGS,
      actionTypes.RECEIVE_MONITOR_SETTINGS_ERROR,
    ],
  },
});

export const fetchRound = roundSlug => ({
  [RSAA]: {
    bailout: state => Boolean(monitor.getRound(state, { roundSlug })),
    endpoint: `${process.env.REACT_APP_TRAIS_API}/v1/public/round/${roundSlug}/`,
    method: 'GET',
    types: [
      actionTypes.REQUEST_ROUND,
      actionTypes.RECEIVE_ROUND,
      actionTypes.RECEIVE_ROUND_ERROR,
    ],
  },
});

export const fetchTraisHorse = atgHorseId => ({
  [RSAA]: {
    bailout: state => Boolean(monitor.getTraisHorseId(state, { atgHorseId })),
    endpoint: `${process.env.REACT_APP_TRAIS_API}/v1/public/horse/${atgHorseId}/?atg=true`,
    method: 'GET',
    types: [
      actionTypes.REQUEST_TRAIS_HORSE,
      actionTypes.RECEIVE_TRAIS_HORSE,
      actionTypes.RECEIVE_TRAIS_HORSE_ERROR,
    ],
  },
});
