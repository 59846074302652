import * as types from '../actionTypes';

const defaultState = {
  isLoading: false,
  error: null,
  data: undefined,
};

export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case types.REQUEST_OFFERS:
      return {
        isLoading: true,
        error: null,
        data: action.payload,
        prevData: state.data,
      };

    case types.RECEIVE_OFFERS:
      return {
        isLoading: false,
        error: null,
        data: action.payload,
        prevData: undefined,
      };

    case types.RECEIVE_OFFERS_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        data: state.prevData,
        prevData: undefined,
      };

    default:
      return state;
  }
}
