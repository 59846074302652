import * as Sentry from '@sentry/react';
import { ignoreErrors } from '@trmediaab/sentry-helpers';

if (['production', 'preview'].includes(process.env.REACT_APP_VERCEL_ENV)) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    debug: false,

    environment: `vercel-${process.env.REACT_APP_VERCEL_ENV}`,

    // Send 0,5% of transactions in production for performance monitoring
    tracesSampleRate:
      process.env.REACT_APP_VERCEL_ENV === 'production' ? 0.005 : 0,

    // The sample rate for replays that begin recording immediately and last the entirety of the user's session.
    // Sample 0,5% of sessions in production
    replaysSessionSampleRate:
      process.env.REACT_APP_VERCEL_ENV === 'production' ? 0.005 : 0,

    // The sample rate for replays that are recorded when an error happens.
    // Sample 5% of errors
    replaysOnErrorSampleRate:
      process.env.REACT_APP_VERCEL_ENV === 'production' ? 0.05 : 0,

    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    integrations: [
      Sentry.replayIntegration({
        // Additional Replay configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],

    // Ignore unactionable errors
    ignoreErrors,

    // Allow only errors originating from
    // - Jokersystemet production domain (Sweden & Norway)
    // - Vercel preview domains for Jokersystemet
    allowUrls: [
      /https:\/\/(www\.)?jokersystemet\.(se|no)/,
      /https:\/\/joker(\S+)?\.trdev\.se/,
    ],
  });
}

export default Sentry;
