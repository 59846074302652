import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

export const myHorsesSelector = state => state.monitor.myHorses;
const horseDetailsSelector = state => state.monitor.horseDetails;
export const startCommentsSelector = state => state.monitor.startComments;
const settingsSelector = state => state.monitor.settings;
const roundsSelector = state => state.monitor.rounds;
const traisHorseIdsSelector = state => state.monitor.traisHorseIds;

const atgHorseIdSelector = (_, props) => props.atgHorseId;
const horseIdSelector = (_, props) => props.horseId;
const startIdSelector = (_, props) => props.startId;
const roundSlugSelector = (_, props) => props.roundSlug;
const legNumberSelector = (_, props) => props.legNumber;
const startNumberSelector = (_, props) => props.startNumber;

export const getError = createSelector(
  myHorsesSelector,
  myHorses => myHorses.error,
);

export const getHorses = createSelector(
  myHorsesSelector,
  myHorses => myHorses.items,
);

export const getHorseByAtgId = createCachedSelector(
  [getHorses, atgHorseIdSelector],
  (horses, atgId) => horses.find(horse => horse.aisId === atgId),
)(atgHorseIdSelector);

export const isHorseLoading = createCachedSelector(
  [myHorsesSelector, horseIdSelector],
  (myHorses, horseId) => myHorses.loadingDict[horseId],
)(horseIdSelector);

export const isLoading = createSelector(
  myHorsesSelector,
  myHorses => myHorses.loading,
);

export const getHorseDetails = createSelector(
  [horseDetailsSelector, horseIdSelector],
  (horseDetails, horseId) => horseDetails.resolved[horseId],
);

export const isHorseDetailsFullyLoaded = createSelector(
  [horseDetailsSelector, horseIdSelector],
  (horseDetails, horseId) => horseDetails.fullyLoaded[horseId],
);

export const getStartComment = createSelector(
  [startCommentsSelector, startIdSelector],
  (startComments, startId) => startComments.resolved[startId],
);

export const getSettings = createSelector(
  settingsSelector,
  settings => settings.resolved,
);

export const isSettingsLoading = createSelector(
  settingsSelector,
  settings => settings.loading,
);

export const getRound = createSelector(
  [roundsSelector, roundSlugSelector],
  (rounds, roundSlug) => rounds.resolved[roundSlug],
);

export const getStartId = createCachedSelector(
  getRound,
  legNumberSelector,
  startNumberSelector,
  (round, legNumber, startNumber) => {
    const starts = round?.starts;
    if (Array.isArray(starts)) {
      return starts.find(
        start =>
          start.leg_number === legNumber && start.start_number === startNumber,
      )?.start_id;
    }
  },
)((_state, { roundSlug, legNumber, startNumber }) =>
  [roundSlug, legNumber, startNumber].join(','),
);

export const getTraisHorseId = createSelector(
  [traisHorseIdsSelector, atgHorseIdSelector],
  (traisHorseIds, atgHorseId) => traisHorseIds.resolved[atgHorseId],
);
