import config from 'config';
import defaultTo from 'lodash/defaultTo';

export const withDefault = value => defaultTo(value, '');

export function getBaseUrl() {
  return window.location.origin
    ? window.location.origin
    : `${window.location.protocol}//${window.location.host}`;
}

export function toAbsoluteUrl(path) {
  if (path == null) {
    return null;
  }

  if (/^https?:\/\/[^/]+/.test(path)) {
    return path;
  }

  return getBaseUrl() + (path.indexOf('/') !== 0 ? '/' : '') + path;
}

export const getUrl = {
  shareFb: url =>
    `https://facebook.com/sharer/sharer.php?u=${withDefault(url)}`,
  shareTw: url => `https://twitter.com/intent/tweet?url=${withDefault(url)}`,
  live: section => `/live/${section === 'active' ? '' : section || ''}`,
  news: () => `/nyheter`,
  instructions: () =>
    IS_NO_SITE ? '/instruksjonsfilmer' : '/instruktionsfilmer',
  userpages: () => config.userpagesUrl,
  editLiveAlias: () => `${config.userpagesUrl}/joker`,
  login: (returnHref = window.location.href) =>
    toAbsoluteUrl(
      `/auth/authorize?dest=${encodeURI(toAbsoluteUrl(returnHref))}&client_id=${
        process.env.REACT_APP_TRAIS_SITE
      }`,
    ),
  logout: (returnHref = window.location.href) =>
    toAbsoluteUrl(`/auth/logout?dest=${encodeURI(toAbsoluteUrl(returnHref))}`),
  register: (returnHref = window.location.href) =>
    `${config.authUrl}/register?dest=${encodeURI(toAbsoluteUrl(returnHref))}`,
  atgVideoArchive: horseId =>
    `https://www.atg.se/videoarkiv/trav?horses=${
      Array.isArray(horseId) ? horseId.join(',') : horseId
    }`,
};

/**
 * Returns a complete URL (affiliate branding) for SvenskaSpel
 * p - program id - 295103
 * a - affiliate id - 3165491
 */

export function getSvSpelAffiliateLink(dest) {
  const currLocation = window.location.href;

  const partA = `https://clk.tradedoubler.com/click?p=295103&a=3165491&epi=&epi2=${currLocation}&url=${dest}`;
  const partB = `https://clk.tradedoubler.com/click?f=0&p=353618&a=89693&epi=3165491_0&epi2=${currLocation}&url=`;

  return `${partB}${encodeURIComponent(partA)}`;
}

/** Impressions aren't counted using just the affiliate link
 *  this is TradeDoubler's way of counting impressions
 */
export function getSvSpelImpressionUrl() {
  return `https://clk.tradedoubler.com/click?imp&f=0&p=353617&a=89693&g=25614120&epi=3165491_0&epi2=${encodeURIComponent(
    window.location.href,
  )}`;
}
