/*
 * Selector that returns betId from props
 */
export const betIdSelector = (_, props) => props.betId;

/*
 * Selector that returns type from props
 */
export const typeSelector = (_, props) => props.type;

/*
 * Re-reselect resolver function.
 * Cache/call a new selector for each different "betId"
 */
export const betIdCacheResolver = (_, props) => props.betId;
