import get from 'lodash/get';

import {
  LOGGED_IN_TO_OTHER_DEVICE,
  SESSION_EXPIRED,
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
  USER_LOGGED_OUT_BY_SERVER,
} from 'shared/constants/MessageTypes';
import { MESSAGES_PRIVATE, USER_DATA_UPDATE } from 'shared/constants/StompKind';
import Sentry from 'shared/utils/sentry';

import {
  addSiteMessage,
  addSubscription,
  deleteUser,
  dismissSiteMessageByType,
  removeSubscription,
  setUser,
} from '../actions';
import * as actionTypes from '../actionTypes';
import { ui as uiSelectors, user as userSelectors } from '../selectors';

const isStompMessageAction = action =>
  action.type === actionTypes.STOMP_RECEIVE_DATA &&
  action.payload.kind === MESSAGES_PRIVATE;

const isLoggedIn = store => userSelectors.isLoggedIn(store.getState());

const findMessageType = action =>
  isStompMessageAction(action)
    ? get(action, 'payload.data.messageType')
    : undefined;

function dismissLoggedOutSiteMessages(store) {
  const selector = messageType =>
    uiSelectors.hasMessageType(store.getState(), { messageType });
  const action = messageType =>
    store.dispatch(dismissSiteMessageByType(messageType));

  if (selector(SESSION_EXPIRED)) {
    action(SESSION_EXPIRED);
  } else if (selector(LOGGED_IN_TO_OTHER_DEVICE)) {
    action(LOGGED_IN_TO_OTHER_DEVICE);
  } else if (selector(USER_LOGGED_OUT_BY_SERVER)) {
    action(USER_LOGGED_OUT_BY_SERVER);
  }
}

function getNewUserDataFromAction(action) {
  if (
    [actionTypes.RECEIVE_CURRENT_USER, actionTypes.RECEIVE_LOGIN_USER].includes(
      action.type,
    )
  ) {
    return action.payload;
  }

  if ([USER_LOGGED_IN, USER_LOGGED_OUT].includes(findMessageType(action))) {
    return action.payload.data.user;
  }
}

const middleware = store => {
  // Note: USER_LOGGED_OUT is pushed on startup if user is logged out.
  // We want to update the user store all the same, just as if we get
  // RECEIVE_CURRENT_USER with a logged out user.
  function shouldSetUser(action) {
    if (isLoggedIn(store)) {
      return false;
    }

    if (
      [
        actionTypes.RECEIVE_CURRENT_USER,
        actionTypes.RECEIVE_LOGIN_USER,
        actionTypes.RECEIVE_CURRENT_USER_ERROR, // this one will set user to empty.
      ].includes(action.type)
    ) {
      return true;
    }

    if ([USER_LOGGED_IN, USER_LOGGED_OUT].includes(findMessageType(action))) {
      return true;
    }

    return false;
  }

  function shouldDeleteUser(action) {
    if (!isLoggedIn(store)) {
      return false;
    }

    if (
      [
        actionTypes.RECEIVE_LOGOUT_USER,
        actionTypes.UNAUTHORIZED_REQUEST_ERROR,
      ].includes(action.type)
    ) {
      return true;
    }

    if (
      [SESSION_EXPIRED, LOGGED_IN_TO_OTHER_DEVICE, USER_LOGGED_OUT].includes(
        findMessageType(action),
      )
    ) {
      return true;
    }

    return false;
  }

  return next => action => {
    const returnValue = next(action);

    if (shouldSetUser(action)) {
      // Set user in store
      const user = getNewUserDataFromAction(action);
      store.dispatch(setUser(user));

      // If we became logged in now
      if (isLoggedIn(store)) {
        const userId = userSelectors.getUserId(store.getState());
        const email = userSelectors.getEmail(store.getState());
        const name = userSelectors.getName(store.getState());
        // Subscribe to messages for current user
        store.dispatch(
          addSubscription('/user/queue/messages', MESSAGES_PRIVATE),
        );
        // Subscribe to updates to user profile
        store.dispatch(
          addSubscription(`/topic/userupdate_${userId}`, USER_DATA_UPDATE),
        );
        // Set Sentry user
        Sentry.setUser({ id: userId, email, username: name });
        // Dismiss invalid messages
        dismissLoggedOutSiteMessages(store);
      }

      return returnValue;
    }

    if (shouldDeleteUser(action)) {
      // Unsubscribe from user profile updates
      const userId = userSelectors.getUserId(store.getState());
      store.dispatch(removeSubscription(`/topic/userupdate_${userId}`));
      // Delete user data
      store.dispatch(deleteUser());
      // Notify user that they have been logged out by the server
      if (action.type === actionTypes.UNAUTHORIZED_REQUEST_ERROR) {
        store.dispatch(addSiteMessage(USER_LOGGED_OUT_BY_SERVER));
      }
    }

    return returnValue;
  };
};

export default middleware;
