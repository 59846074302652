import * as types from '../actionTypes';

export const changeMenuCategory = category => ({
  type: types.CHANGE_MENU_CATEGORY,
  payload: category,
});

export const setSelectedMenuItem = id => ({
  type: types.SET_SELECTED_MENU_ITEM,
  payload: id,
});

export const clearSelectedMenuItem = () => ({
  type: types.CLEAR_SELECTED_MENU_ITEM,
});

export const changeDisabledMenuItems = ids => ({
  type: types.CHANGE_DISABLED_MENU_ITEMS,
  payload: !ids ? [] : Array.isArray(ids) ? ids : [ids],
});

export const toggleManualUpdateButton = () => ({
  type: types.TOGGLE_MANUAL_UPDATE_BUTTON,
});
