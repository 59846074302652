import { func, number, string } from 'prop-types';
import { Component } from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';

import { popDialog } from 'shared/redux/actions';

import WithWindowSize from '../WithWindowSize';

const mapDispatchToProps = (dispatch, ownprops) => ({
  popDialog: () => {
    dispatch(popDialog(ownprops.dialogKey));
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(
  WithWindowSize()(
    class DialogPortal extends Component {
      static propTypes = {
        className: string.isRequired,
        dialogKey: string.isRequired,
        children: func.isRequired,
        windowHeight: number.isRequired,
      };

      static containerNode = null;

      constructor() {
        super();
        if (!DialogPortal.containerNode) {
          const node = document.createElement('div');
          node.classList.add('dialogRootContainer');
          document.body.appendChild(node);
          DialogPortal.containerNode = node;
        }
      }

      componentDidMount() {
        this.ensureFit();
        if (typeof ResizeObserver === 'function') {
          this.resizeObserver = new ResizeObserver(() => this.ensureFit());
          this.resizeObserver.observe(this.innerNode);
        }
      }

      componentWillUnmount() {
        this.resizeObserver?.disconnect();
      }

      UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.windowHeight !== this.props.windowHeight) {
          this.ensureFit();
        }
      }

      ensureFit = () => {
        const overshoot = this.innerNode.offsetHeight - window.innerHeight;
        if (overshoot > 0 && overshoot !== this.lastOvershoot) {
          this.lastOvershoot = overshoot;
          this.innerNode.style.marginTop = `${overshoot}px`;
          this.rootNode.classList.add('overflow-vertical');
        } else if (overshoot <= 0 && this.lastOvershoot) {
          this.lastOvershoot = null;
          this.innerNode.style.removeProperty('margin-top');
          this.rootNode.classList.remove('overflow-vertical');
        }
      };

      onClick = e => {
        const { popDialog } = this.props;
        const target = e.target;
        const dialog = this.rootNode.querySelector('.dialog');

        const isDialogClick =
          dialog === target || (dialog && dialog.contains(target));

        if (!isDialogClick) {
          e.stopPropagation();
          popDialog();
        }
      };

      onRootRef = ref => {
        this.rootNode = ref;
      };

      onInnerRef = ref => {
        this.innerNode = ref;
      };

      render() {
        const { className, children, popDialog } = this.props;

        return createPortal(
          <div
            className="dialog-wrapper"
            onClick={this.onClick}
            ref={this.onRootRef}
          >
            <div className={`dialog ${className}`} ref={this.onInnerRef}>
              {children(popDialog)}
            </div>
          </div>,
          DialogPortal.containerNode,
        );
      }
    },
  ),
);
