import Stomp from '@stomp/stompjs';
import config from 'config';
import forEach from 'lodash/forEach';
import noop from 'lodash/noop';
import random from 'lodash/random';
import SockJS from 'sockjs-client';

import Sentry from 'shared/utils/sentry';

import { onStompMessage } from '../actions';
import { STOMP_SUBSCRIBE, STOMP_UNSUBSCRIBE } from '../actionTypes';
import { user as userSelectors } from '../selectors';

// First reconnect after 3 seconds
export const INITIAL_RECONNECT_DELAY = 3000;
// Max delay between reconnections is 1 minute
export const MAX_RECONNECT_DELAY = 60000;
// Exponentially increase the reconnection delay between each
// attempt until we reach a ceiling. We add some randomness so
// clients reconnects more unevenly to ease the pressure on the server.
export const nextReconnectDelay = (prevDelay, numOfReconnectAttempts) =>
  Math.min(
    prevDelay + numOfReconnectAttempts * random(100, 1000),
    MAX_RECONNECT_DELAY,
  );

const middleware = store => {
  const subscriptions = {};
  //const client = Stomp.client(`ws://${window.location.host}/ws`);

  const client = Stomp.over(() => new SockJS(config.socketUrl));
  client.reconnect_delay = INITIAL_RECONNECT_DELAY;
  client.debug = str => {
    // Collapse long debug strings
    if (str && str.length > 100) {
      // TODO - only in dev? otherwise verify browser support
      /* eslint-disable no-console */
      console.groupCollapsed('STOMP:');
      console.info(str);
      console.groupEnd();
      /* eslint-enable no-console */
    } else {
      console.info('STOMP:', str);
    }
  };

  let reconnectCounter = 0;
  let connectPromise;
  let authHeadersSent = false;

  return next => async action => {
    if (action.type === STOMP_SUBSCRIBE) {
      const { destination, kind } = action.payload;
      const meta = action.meta;

      if (!subscriptions[destination]) {
        let headers;

        if (!authHeadersSent) {
          const truthToken = userSelectors.getToken(store.getState());
          if (truthToken != null) {
            headers = {
              truthToken,
            };
            authHeadersSent = true;
          }
        }

        try {
          await connect(headers);
          subscribe(destination, kind, meta);
        } catch (error) {
          console.info('Failed to subscribe with stomp client. Error:', error);
          authHeadersSent = true;
          Sentry.captureException(error);
        }
      } else if (process.env.NODE_ENV === 'development') {
        console.info(
          `Ignoring subscription to ${destination}, already subscribing`,
        );
      }
    } else if (action.type === STOMP_UNSUBSCRIBE) {
      const destination = action.payload;
      unsubscribe(destination);
    }

    return next(action);
  };

  function connect(headers) {
    if (!connectPromise || headers != null) {
      // We need to disconnect and connect again
      // to send headers
      if (headers != null && client.connected) {
        client.disconnect();
      }

      connectPromise = new Promise(resolve => {
        console.info('client connecting');
        client.connect(
          headers || {},
          frame => {
            console.info('client connected', frame);
            // Re-subscribe in case we've reconnected
            forEach(subscriptions, subscription => {
              const { destination, kind, meta } = subscription;
              subscribe(destination, kind, meta);
            });
            client.reconnect_delay = INITIAL_RECONNECT_DELAY;
            reconnectCounter = 0;
            resolve();
          },
          () => {
            client.reconnect_delay = nextReconnectDelay(
              client.reconnect_delay,
              reconnectCounter,
            );
            reconnectCounter++;
          },
        );
      });
    }

    return connectPromise;
  }

  function subscribe(destination, kind, meta) {
    console.info('subscribe to', destination);
    const subscription = client.subscribe(destination, message => {
      try {
        const data = JSON.parse(message.body);
        if (data == null) {
          throw new Error(
            `${destination} sent an invalid body '${message.body}'`,
          );
        }
        store.dispatch(onStompMessage(data, kind, meta));
      } catch (error) {
        Sentry.captureException(error);
        if (process.env.NODE_ENV === 'development') {
          console.info(error);
        }
      }
    });
    subscriptions[destination] = {
      destination,
      kind,
      meta,
      unsubscribe: subscription.unsubscribe,
    };
  }

  function unsubscribe(destination) {
    if (client.connected) {
      const { unsubscribe = noop } = subscriptions[destination] || {};
      unsubscribe();
    }
    delete subscriptions[destination];
  }
};

export default middleware;
