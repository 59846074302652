import { GTM_EVENT } from 'shared/redux/actionTypes';
import * as types from 'sport/redux/actionTypes';

const hitType = 'event';
const event = 'event-ga';

export const eventCategories = {
  CLICK_EVENT: 'click event',
};

const sendEvents = eventData => {
  const events = { hitType, event, ...eventData };
  const dataLayer = window.dataLayer || [];
  dataLayer.push(events);
  window.dataLayer = dataLayer;
};

// eslint-disable-next-line unicorn/consistent-function-scoping
const middleware = () => next => action => {
  switch (action.type) {
    case GTM_EVENT: {
      sendEvents(action.payload);

      break;
    }
    case types.REQUEST_SHARE_CODE: {
      sendEvents({
        eventCategory: eventCategories.CLICK_EVENT,
        eventAction: 'share bet',
      });

      break;
    }
    case types.REQUEST_ARCHIVED_BETS: {
      sendEvents({
        eventCategory: eventCategories.CLICK_EVENT,
        eventAction: 'archive sport',
      });

      break;
    }
    default:
    // Do nothing
  }
  return next(action);
};

export default middleware;
