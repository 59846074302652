import noop from 'lodash/noop';
import { func, number, oneOfType, string } from 'prop-types';
import { Component } from 'react';

export default class TextInput extends Component {
  static propTypes = {
    type: string,
    placeholder: string,
    value: oneOfType([string, number]),
    inputRef: func,
    onClick: func,
    onChange: func.isRequired,
    onBlur: func,
  };

  static defaultProps = {
    type: 'text',
    placeholder: '',
    onBlur: noop,
  };

  handleEvent = e => {
    if (e.type === 'keyup') {
      if (e.key === 'Escape' || e.key === 'Enter') {
        this.isEscapeBlur = e.key === 'Escape';
        e.currentTarget.blur();
      }
    } else {
      const fn = e.type === 'blur' ? this.props.onBlur : this.props.onChange;
      if (this.isEscapeBlur) {
        this.isEscapeBlur = false;
        fn(undefined, e);
      } else {
        fn(e.currentTarget.value, e);
      }
    }
  };

  render() {
    const {
      type,
      value,
      placeholder,
      inputRef,
      onClick,
      onChange,
      onBlur,
      onKeyUp,
      ...rest
    } = this.props;

    return (
      <input
        type={type}
        value={value == null ? '' : value}
        placeholder={placeholder}
        onClick={onClick}
        onChange={this.handleEvent}
        onBlur={this.handleEvent}
        onKeyUp={this.handleEvent}
        ref={inputRef}
        {...rest}
      />
    );
  }
}
