import dotProp from 'dot-prop-immutable';

import * as types from '../actionTypes';

const defaultState = {
  numUploaded: 0,
  error: undefined,
};

export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case types.RECEIVE_UPLOAD_BET:
      return {
        numUploaded: state.numUploaded + 1,
        error: undefined,
      };

    case types.RECEIVE_UPLOAD_BET_ERROR:
      return dotProp.set(state, 'error', action.payload);

    case types.CLEAR_ARCHIVE_UPLOAD_ERROR:
      return dotProp.set(state, 'error');

    default:
      return state;
  }
}
