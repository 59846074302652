import classnames from 'classnames';
import PropTypes from 'prop-types';

import Button from 'shared/components/button/Button';
import Close from 'shared/components/icon/Close';

import styles from './index.module.scss';

const CloseButton = ({
  className,
  onClick,
  border = true,
  disabled = false,
  invert = false,
  large = false,
  muted = false,
}) => (
  <Button
    className={classnames(className, styles.btn, {
      [styles.large]: large,
      [styles.invert]: invert,
      [styles.muted]: muted,
      [styles.border]: border,
    })}
    disabled={disabled}
    onClick={onClick}
  >
    <Close />
  </Button>
);

CloseButton.propTypes = {
  className: PropTypes.string,
  border: PropTypes.bool,
  disabled: PropTypes.bool,
  invert: PropTypes.bool,
  large: PropTypes.bool,
  muted: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default CloseButton;
