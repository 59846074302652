import dotProp from 'dot-prop-immutable';

import * as types from '../actionTypes';

const defaultState = {
  category: undefined,
  selectedId: undefined,
  manualUpdateButtonActive: false,
  disabled: [],
};

export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case types.CHANGE_MENU_CATEGORY: {
      const category = action.payload;
      let nextState = state;
      nextState = dotProp.set(nextState, 'category', category);
      nextState = dotProp.set(nextState, 'disabled', []);
      nextState = dotProp.set(nextState, 'selectedId');
      return nextState;
    }

    case types.SET_SELECTED_MENU_ITEM: {
      const id = action.payload;
      return dotProp.set(state, 'selectedId', id);
    }

    case types.CLEAR_SELECTED_MENU_ITEM: {
      return dotProp.set(state, 'selectedId');
    }

    case types.CHANGE_DISABLED_MENU_ITEMS: {
      const itemIds = action.payload;
      return dotProp.set(state, 'disabled', itemIds);
    }

    case types.TOGGLE_MANUAL_UPDATE_BUTTON: {
      return dotProp.toggle(state, 'manualUpdateButtonActive');
    }

    default:
      return state;
  }
}
