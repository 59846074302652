import { node, string } from 'prop-types';

const ExternalLink = ({ url, children, className }) => (
  <a
    href={url}
    target={url.indexOf('mailto:') === 0 ? '_self' : '_blank'}
    className={className}
    rel="noopener noreferrer"
  >
    {children}
  </a>
);

ExternalLink.propTypes = {
  url: string.isRequired,
  children: node,
  className: string,
};

export default ExternalLink;
