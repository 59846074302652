import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { categories as menuCategories } from 'shared/constants/MenuConstants';
import { changeMenuCategory } from 'shared/redux/actions';
import { getDisplayName } from 'shared/utils';

const mapDispatchToProps = dispatch =>
  bindActionCreators({ changeMenuCategory }, dispatch);

const withContentMenu = WrappedComponent => {
  class WithContentMenu extends Component {
    static displayName = `WithContentMenu(${getDisplayName(WrappedComponent)})`;

    componentDidMount() {
      this.props.changeMenuCategory(menuCategories.CONTENT);
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }
  return connect(null, mapDispatchToProps)(WithContentMenu);
};

export default withContentMenu;
