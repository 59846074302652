import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { bindActionCreators } from 'redux';

import SiteMessages from 'shared/components/SiteMessages';
import { NAVIGATOR_OFFLINE, PROMO } from 'shared/constants/MessageTypes';
import { ALERT_UPDATE } from 'shared/constants/TraisSocketKinds';
import {
  dismissSiteMessageByClientId,
  dismissSiteMessageByServerId,
  dismissSiteMessageByType,
  fetchAlert,
  fetchPromo,
} from 'shared/redux/actions';
import {
  addTraisSubscription,
  removeTraisSubscription,
} from 'shared/redux/actions/traisSocketActions';
import { ui, user } from 'shared/redux/selectors';

const mapStateToProps = state => ({
  isLoggedIn: user.isLoggedIn(state),
  messages: ui.getUnseenSiteMessages(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dismissSiteMessageByServerId,
      dismissSiteMessageByClientId,
      dismissSiteMessageByType,
      fetchPromo,
      fetchAlert,
      addTraisSubscription,
      removeTraisSubscription,
    },
    dispatch,
  );

const SiteMessagesContainer = ({
  messages,
  isLoggedIn,
  fetchPromo,
  fetchAlert,
  addTraisSubscription,
  removeTraisSubscription,
  dismissSiteMessageByType,
  dismissSiteMessageByServerId,
  dismissSiteMessageByClientId,
}) => {
  const { pathname } = useLocation();

  const onCloseClick = message => {
    const { id } = message;
    if (id) {
      dismissSiteMessageByServerId(message);
    } else {
      dismissSiteMessageByClientId(message);
    }
  };

  useEffect(() => {
    fetchPromo();

    // Fetch existing alert messages
    fetchAlert();

    // Listen for any newly published alert message
    addTraisSubscription(ALERT_UPDATE);

    const onOnline = () => dismissSiteMessageByType(NAVIGATOR_OFFLINE);

    window.addEventListener('online', onOnline);

    return () => {
      window.removeEventListener('online', onOnline);
      removeTraisSubscription(ALERT_UPDATE);
    };
  }, [
    dismissSiteMessageByType,
    fetchAlert,
    fetchPromo,
    addTraisSubscription,
    removeTraisSubscription,
  ]);

  const filteredMessages = isLoggedIn
    ? messages.filter(message => message.messageType !== PROMO)
    : messages;

  // Note: remounts on pathname changes to ensure login links are using
  // an up to date location.href value
  return (
    <SiteMessages
      key={pathname}
      messages={filteredMessages}
      onClose={onCloseClick}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SiteMessagesContainer);
