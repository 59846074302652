import { TOPPTIPSET_PRODUCTS } from 'shared/constants/AppConstants';

import { getText, hasText } from './Text';

const maybeUpper = (text, upper) => (upper ? text.toUpperCase() : text);

const name = product => {
  switch (product) {
    case 'WmTipset': {
      return 'VM-Tipset';
    }
    case 'TopptipsetEuropa':
    case 'TopptipsetStryk': {
      return 'Topptipset';
    }
    case 'PowerPlay': {
      return 'Powerplay';
    }
    default: {
      // Horse racing bet types are named in lang files
      const productLangKey = `bet-type-short_${product.toLowerCase()}`;
      if (hasText(productLangKey)) {
        return getText(productLangKey);
      }
    }
  }
  // Leave others unmodified
  return product;
};

export const getProductDisplayName = (product, uppercase = false) =>
  maybeUpper(name(product), uppercase);

export const isTopptipset = product => TOPPTIPSET_PRODUCTS.includes(product);
