import classnames from 'classnames';
import { bool, func, number, oneOfType, string } from 'prop-types';

const RadioInput = ({
  value,
  name,
  labelText,
  checked,
  disabled = false,
  onChange,
}) => (
  <label
    className={classnames('radio-input', {
      'radio-input--disabled': disabled,
    })}
  >
    <span
      className={classnames('checkmark', {
        'checkmark--checked': checked,
      })}
    >
      <span className="checkmark__inner" />
    </span>
    <span className="label-text">{labelText}</span>
    <input
      type="radio"
      value={value}
      name={name}
      checked={checked}
      disabled={disabled}
      onChange={() => onChange(value)}
    />
  </label>
);

RadioInput.propTypes = {
  checked: bool,
  disabled: bool,
  labelText: string.isRequired,
  value: oneOfType([string, number]).isRequired,
  name: string,
  onChange: func.isRequired,
};

export default RadioInput;
