import config from 'config';
import isPlainObject from 'lodash/isPlainObject';
import reduce from 'lodash/reduce';

// Recursively remove properties with null value
export const rejectNullDeep = object => {
  if (!isPlainObject(object)) {
    return object;
  }
  return reduce(
    object,
    (acc, value, key) => {
      if (isPlainObject(value)) {
        acc[key] = rejectNullDeep(value);
      } else if (value !== null) {
        acc[key] = value;
      }
      return acc;
    },
    {},
  );
};

export const getDisplayName = Component => {
  if (typeof Component === 'string') {
    return Component;
  }

  if (!Component) {
    return;
  }

  return Component.displayName || Component.name || 'Component';
};

/**
 * Returns a copy of an object with the given keys excluded
 */
export const omitKeys = (obj = {}, keys = []) => {
  // Ensure string keys
  const mappedKeys = new Set(keys.map(key => key.toString()));
  return reduce(
    obj,
    (acc, value, key) => {
      if (!mappedKeys.has(key)) {
        acc[key] = value;
      }
      return acc;
    },
    {},
  );
};

export const createShopUrl = (sku, provider = null) => {
  const { spelvardeCheckoutUrl, storavinsterCheckoutUrl, travogatCheckoutUrl } =
    config;

  switch (provider) {
    case 'Spelvärde':
      return `${spelvardeCheckoutUrl}?sku=${sku}`;
    case 'Travögat':
      return `${travogatCheckoutUrl}?sku=${sku}`;
    case 'Storavinster':
      return `${storavinsterCheckoutUrl}?sku=${sku}`;
    default:
      console.error(`Unknown provider ${provider}`);
  }
};

export const parseQueryString = qs =>
  qs && qs.length > 1
    ? qs
        .slice(1)
        .split('&')
        .reduce((acc, pair) => {
          const a = pair.split('=');
          acc[a[0]] = a[1];
          return acc;
        }, {})
    : {};

export function objectToBaseB64(object) {
  try {
    return window.btoa(encodeURIComponent(JSON.stringify(object)));
  } catch {
    throw new Error(`Failed to encode, ${object}`);
  }
}

export function base64ToObject(str) {
  try {
    return JSON.parse(decodeURIComponent(window.atob(str)));
  } catch (error) {
    console.error(error);
    return null;
  }
}
