import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SubscriptionsPage from 'shared/components/SubscriptionsPage';
import { fetchOffers } from 'shared/redux/actions/offerActions';
import { offers, user } from 'shared/redux/selectors';

const SubscriptionsPageContainer = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(user.isLoggedIn);
  const fetchedOffers = useSelector(offers.getOffers);

  useEffect(() => {
    dispatch(fetchOffers());
  }, [dispatch]);

  return <SubscriptionsPage isLoggedIn={isLoggedIn} offers={fetchedOffers} />;
};

export default SubscriptionsPageContainer;
