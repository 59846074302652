export const WAIT_FOR_ALL_AD_SLOTS = 500;

export const STATUS = {
  INITIAL: 'INITIAL',
  FILLED: 'FILLED',
  EMPTY: 'EMPTY',
};

export const UNIT_CONFIGS = {
  panorama: [
    {
      viewport: [0, 0],
      sizes: [[320, 320]],
    },
    {
      viewport: [758, 0],
      sizes: [[980, 240]],
    },
  ],
  insider: [
    {
      viewport: [0, 0],
      sizes: [[300, 250]],
    },
    {
      viewport: [758, 0],
      sizes: [[300, 600]],
    },
  ],
};

export const ADS_PREFIX = IS_NO_SITE ? 'JOKER_NO' : 'JOKER';

export const PLACEMENTS = {
  panorama: 'Panorama',
  insider: 'Square',
};
