import PropTypes from 'prop-types';

import Button from 'shared/components/button/Button';
import Logo from 'shared/components/Logo';

import styles from './index.module.scss';

const headings = IS_SE_SITE
  ? {
      maintenance: 'Tyvärr kan du inte nå Jokersystemet för tillfället',
      offline: 'Du är inte ansluten till internet',
    }
  : {
      maintenance: 'Dessverre kan du ikke nå Jokersystemet akkurat nå',
      offline: 'Du er ikke tilkoblet internett',
    };

const content = IS_SE_SITE
  ? {
      maintenance: (
        <>
          Orsaken kan vara att vi uppdaterar sajten, har tillfälliga tekniska
          problem eller att din internetanslutning är bristfällig. Du kommer
          automatiskt in på Jokersystemet så fort orsaken är löst. Eller{' '}
          <Button
            className="text-button"
            onClick={() => window.location.reload()}
          >
            försök igen nu
          </Button>
          .
        </>
      ),
      offline: (
        <>
          Vänligen kontrollera att du har en fungerande internetuppkoppling och{' '}
          <Button
            className="text-button"
            onClick={() => window.location.reload()}
          >
            försök igen
          </Button>
          .
        </>
      ),
    }
  : {
      maintenance: (
        <>
          Årsaken kan være at vi oppdater siden, har tilfeldige tekniske
          problemer eller din internettilgang er usikker. Du kommer automatisk
          in på Jokersystemet så fort årsaken er oppklart, eller{' '}
          <Button
            className="text-button"
            onClick={() => window.location.reload()}
          >
            forsøk igjen nå
          </Button>
          .
        </>
      ),
      offline: (
        <>
          Vennligst kontroller at du er koblet til internett og{' '}
          <Button
            className="text-button"
            onClick={() => window.location.reload()}
          >
            prøv igjen
          </Button>
          .
        </>
      ),
    };

const ApplicationInaccessible = ({ reason }) => (
  <div className={styles.root}>
    <Logo className={styles.img} />
    <h1 className={styles.h1}>{headings[reason]}</h1>
    <p className={styles.p}>{content[reason]}</p>
  </div>
);

ApplicationInaccessible.propTypes = {
  reason: PropTypes.oneOf(['maintenance', 'offline']).isRequired,
};

export default ApplicationInaccessible;
