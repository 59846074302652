import { createSelector } from 'reselect';

export const isPageVisible = state => !state.ui.documentHidden;
export const getLoader = state => state.ui.loader;
export const getScale = state => state.ui.scale;

export const userTemplatesVisible = state => state.ui.userTemplatesVisible;

export const jokerTemplatesVisible = state => state.ui.jokerTemplatesVisible;

const getDismissedSiteMessageHashes = createSelector(
  state => state.ui.dismissedSiteMessagesByHash,
  hashes => [...hashes.local, ...hashes.persist],
);

export const getUnseenSiteMessages = createSelector(
  [state => state.ui.siteMessages, getDismissedSiteMessageHashes],
  (messages, dismissed) =>
    messages.filter(message => !dismissed.includes(message.hash)),
);

export const hasMessageType = createSelector(
  [getUnseenSiteMessages, (_, props) => props.messageType],
  (messages, messageType) =>
    messages.some(message => message.messageType === messageType),
);
