import { bool, func } from 'prop-types';
import { Component } from 'react';

import { isTouch } from 'atg/utils/device';

export default class VisibilityToggle extends Component {
  static propTypes = {
    render: func.isRequired,
    autoToggleOutside: bool,
    propagateOutsideEvent: bool,
  };

  static defaultProps = {
    autoToggleOutside: true,
    propagateOutsideEvent: true,
  };

  insideRefs = [];

  state = {
    visible: false,
    hovered: false,
  };

  componentWillUnmount() {
    document.removeEventListener('click', this.onDocClick, true);
  }

  getElement = ({ target }) =>
    this.insideRefs.find(
      ref => ref && (ref === target || ref.contains(target)),
    );

  toggleVisible = () => {
    const { autoToggleOutside } = this.props;
    isTouch() && this.setState(({ hovered }) => ({ hovered: !hovered }));

    this.setState(
      ({ visible }) => ({
        visible: !visible,
      }),
      () => {
        if (!autoToggleOutside) {
          return;
        }
        if (this.state.visible) {
          document.addEventListener('click', this.onDocClick, true);
        } else {
          document.removeEventListener('click', this.onDocClick, true);
        }
      },
    );
  };

  onDocClick = e => {
    if (this.getElement(e)) {
      return;
    }
    if (!this.props.propagateOutsideEvent) {
      e.stopPropagation();
    }
    this.toggleVisible();
  };

  onHover = e => {
    if (isTouch()) {
      return;
    }
    const element = this.getElement(e);
    if (element) {
      this.setState({ hovered: true });
    }
  };

  onLeave = e => {
    if (isTouch()) {
      return;
    }
    const element = this.getElement(e);
    if (element) {
      this.setState({ hovered: false });
    }
  };

  insideRefHandler = ref => {
    if (!this.insideRefs.includes(ref)) {
      this.insideRefs.push(ref);
    }
  };

  render() {
    const { visible, hovered } = this.state;
    const isHover = isTouch() ? visible : hovered;

    return this.props.render({
      visible,
      onToggleClick: this.toggleVisible,
      insideRefHandler: this.insideRefHandler,
      onHover: this.onHover,
      onLeave: this.onLeave,
      isHover,
    });
  }
}
