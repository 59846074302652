import classnames from 'classnames';
import { bool, node } from 'prop-types';

const Editorial = ({ children, smallText = false, responsive = true }) => (
  <div
    className={classnames('editorial', {
      'editorial--small-text': smallText,
      'editorial--responsive': responsive,
    })}
  >
    {children}
  </div>
);

Editorial.propTypes = {
  children: node,
  smallText: bool,
  responsive: bool,
};

export default Editorial;
