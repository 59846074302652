import { sortByBetTypePriority } from '@trmediaab/zebra-utils';
import dotProp from 'dot-prop-immutable';

import { SPORT_PRODUCTS } from 'shared/constants/AppConstants';
import * as types from 'shared/redux/actionTypes';

const defaultState = {
  atgTrendData: {
    data: null,
    error: undefined,
    loading: false,
  },
  todaysBestBet: {
    betData: null,
    timestamp: null,
    error: undefined,
    loading: false,
  },
  jokerWizSystems: {
    availableSystems: [],
    timestamp: null,
    error: undefined,
    loading: false,
  },
  latestTips: {
    items: [],
    count: 0,
    timestamp: null,
    loading: false,
    error: null,
  },
  latestNews: {
    items: [],
    count: 0,
    timestamp: null,
    loading: false,
    error: null,
  },
  atgCalendar: {
    resolved: [],
    timestamp: null,
    isLoading: false,
    isSectionVisible: true,
    isListVisible: false,
    error: null,
  },
  sportCalendar: {
    resolved: [],
    timestamp: null,
    isLoading: false,
    isSectionVisible: true,
    isListVisible: false,
    error: null,
  },
  atgJackpots: {
    resolved: [],
    timestamp: null,
    isLoading: false,
    error: null,
  },
  sportJackpots: {
    resolved: [],
    timestamp: null,
    isLoading: false,
    error: null,
  },
  sportAnalysis: {
    OVERODDS: {
      resolved: [],
      timestamp: null,
      isLoading: false,
      error: null,
    },
    JOKER_SPORT: {
      resolved: [],
      timestamp: null,
      isLoading: false,
      error: null,
    },
  },
};

const setLoading = (state, path, action) =>
  dotProp.set(state, path, props => ({
    ...props,
    isLoading: !action.error,
    error: action.error ? action.error : null,
  }));

const setResolved = (state, path, data) =>
  dotProp.set(state, path, props => ({
    ...props,
    resolved: data,
    timestamp: Date.now(),
    isLoading: false,
    error: null,
  }));

const setError = (state, path, error) =>
  dotProp.set(state, path, props => ({
    ...props,
    isLoading: false,
    error,
  }));

export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    // ATG Trends
    case types.REQUEST_ATG_TREND_DATA: {
      return {
        ...state,
        atgTrendData: {
          ...defaultState.atgTrendData,
          loading: true,
        },
      };
    }

    case types.RECEIVE_ATG_TREND_DATA: {
      return {
        ...state,
        atgTrendData: {
          ...defaultState.atgTrendData,
          data: action.payload,
        },
      };
    }

    case types.RECEIVE_ATG_TREND_DATA_ERROR: {
      const { errorType } = action.meta;
      return {
        ...state,
        atgTrendData: {
          ...defaultState.atgTrendData,
          error: errorType,
        },
      };
    }

    // Today's Best Bet
    case types.REQUEST_TODAYS_BEST_BET: {
      return {
        ...state,
        todaysBestBet: {
          ...defaultState.todaysBestBet,
          loading: true,
        },
      };
    }

    case types.RECEIVE_TODAYS_BEST_BET: {
      return {
        ...state,
        todaysBestBet: {
          ...defaultState.todaysBestBet,
          timestamp: Date.now(),
          betData: action.payload,
        },
      };
    }

    case types.RECEIVE_TODAYS_BEST_BET_ERROR: {
      const { errorType } = action.meta;
      return {
        ...state,
        todaysBestBet: {
          ...defaultState.todaysBestBet,
          error: errorType,
        },
      };
    }

    // JokerWiz Systems
    case types.REQUEST_WIZ_SYSTEMS: {
      return {
        ...state,
        jokerWizSystems: {
          ...defaultState.jokerWizSystems,
          loading: true,
        },
      };
    }

    case types.RECEIVE_WIZ_SYSTEMS: {
      // Flatten payload since it's easier to treat individual betType array items for wiz
      const flattenedSystems = [];
      action.payload.forEach(
        ({ date, firstRacePostTime, track, trackKey, betTypes }) => {
          betTypes.forEach(betType => {
            flattenedSystems.push({
              ...betType,
              date,
              firstRacePostTime,
              track,
              trackKey,
            });
          });
        },
      );
      return {
        ...state,
        jokerWizSystems: {
          ...defaultState.jokerWizSystems,
          timestamp: Date.now(),
          availableSystems: flattenedSystems,
        },
      };
    }

    case types.RECEIVE_WIZ_SYSTEMS_ERROR: {
      const { errorType } = action.meta;
      return {
        ...state,
        jokerWizSystems: {
          ...defaultState.jokerWizSystems,
          error: errorType,
        },
      };
    }

    // Latest Tips
    case types.REQUEST_LATEST_TIPS: {
      return {
        ...state,
        latestTips: {
          ...defaultState.latestTips,
          loading: true,
        },
      };
    }

    case types.RECEIVE_LATEST_TIPS: {
      const { payload } = action;
      const { count, results } = payload;

      return {
        ...state,
        latestTips: {
          ...defaultState.latestTips,
          loading: false,
          items: results,
          count,
          timestamp: Date.now(),
        },
      };
    }

    case types.RECEIVE_LATEST_TIPS_ERROR: {
      const {
        payload: { status, statusText },
      } = action;

      return {
        ...state,
        latestTips: {
          ...defaultState.latestTips,
          error: {
            status,
            statusText,
          },
        },
      };
    }

    // Latest News
    case types.REQUEST_LATEST_NEWS: {
      return {
        ...state,
        latestNews: {
          ...defaultState.latestNews,
          loading: true,
        },
      };
    }

    case types.RECEIVE_LATEST_NEWS: {
      const { payload } = action;
      const { count, results } = payload;

      return {
        ...state,
        latestNews: {
          ...defaultState.latestNews,
          loading: false,
          items: results,
          count,
          timestamp: Date.now(),
        },
      };
    }

    case types.RECEIVE_LATEST_NEWS_ERROR: {
      const {
        payload: { status, statusText },
      } = action;

      return {
        ...state,
        latestNews: {
          ...defaultState.latestNews,
          error: {
            status,
            statusText,
          },
        },
      };
    }

    // UI changes
    case types.CHANGE_SPORT_SECTION_VISIBILITY:
      return dotProp.set(
        state,
        'sportCalendar.isSectionVisible',
        action.payload,
      );

    case types.CHANGE_ATG_SECTION_VISIBILITY:
      return dotProp.set(state, 'atgCalendar.isSectionVisible', action.payload);

    case types.CHANGE_ATG_LIST_VISIBILITY:
      return dotProp.set(state, 'atgCalendar.isListVisible', action.payload);

    case types.CHANGE_SPORT_LIST_VISIBILITY:
      return dotProp.set(state, 'sportCalendar.isListVisible', action.payload);

    // ATG calendar
    case types.REQUEST_ATG_CALENDAR:
      return setLoading(state, 'atgCalendar', action);

    case types.RECEIVE_ATG_CALENDAR: {
      const raceDays = action.payload.raceDays.map(day => ({
        ...day,
        betTypes: sortByBetTypePriority(day.betTypes, 'betType'),
      }));
      return setResolved(state, 'atgCalendar', raceDays);
    }
    case types.RECEIVE_ATG_CALENDAR_ERROR:
      return setError(state, 'atgCalendar', action.payload);

    // Sport calendar
    case types.REQUEST_SPORT_CALENDAR:
      return setLoading(state, 'sportCalendar', action);

    case types.RECEIVE_SPORT_CALENDAR: {
      const { events = [] } = action.payload;
      return setResolved(
        state,
        'sportCalendar',
        events
          .filter(
            ({ isOpen, product, drawNr }) =>
              // drawNr 3334 is a cancelled Powerplay bet from Svenska Spel
              // Remove this temporary hotfix when that bet is expired.
              isOpen && SPORT_PRODUCTS.includes(product) && drawNr !== 3334,
          )
          .map(event => ({
            ...event,
            matches: event.matches?.map(match => ({
              ...match,
              // If homeTeam and awayTeam names are provided,
              // store a prettier match description
              description:
                match.homeTeam &&
                match.awayTeam &&
                match.participantType !== 'player'
                  ? `${match.homeTeam} – ${match.awayTeam}`
                  : match.description,
            })),
          })),
      );
    }

    case types.RECEIVE_SPORT_CALENDAR_ERROR:
      return setError(state, 'sportCalendar', action.payload);

    // ATG jackpots
    case types.REQUEST_ATG_JACKPOTS:
      return setLoading(state, 'atgJackpots', action);

    case types.RECEIVE_ATG_JACKPOTS:
      return setResolved(state, 'atgJackpots', action.payload);

    case types.RECEIVE_ATG_JACKPOTS_ERROR:
      return setError(state, 'atgJackpots', action.payload);

    // Sport jackpots
    case types.REQUEST_SPORT_JACKPOTS:
      return setLoading(state, 'sportJackpots', action);

    case types.RECEIVE_SPORT_JACKPOTS:
      return setResolved(state, 'sportJackpots', action.payload);

    case types.RECEIVE_SPORT_JACKPOTS_ERROR:
      return setError(state, 'sportJackpots', action.payload);

    // Sport analysis
    case types.REQUEST_SPORT_ANALYSIS:
      return setLoading(state, `sportAnalysis.${action.meta.site}`, action);

    case types.RECEIVE_SPORT_ANALYSIS:
      return setResolved(
        state,
        `sportAnalysis.${action.meta.site}`,
        action.payload,
      );

    case types.RECEIVE_SPORT_ANALYSIS_ERROR:
      return setError(
        state,
        `sportAnalysis.${action.meta.site}`,
        action.payload,
      );

    default:
      return state;
  }
}
