let data = {};

export async function load() {
  data = await import(/* webpackChunkName: "sv" */ '../sv.json');

  // no.json is only a partial translation file.
  // For Norwegian site, keep Swedish data but overwrite
  // any sv.json fields with no.json
  if (IS_NO_SITE) {
    data = {
      ...data,
      ...(await import(/* webpackChunkName: "no" */ '../no.json')),
    };
  }
}

// Deprecated
export function get(...args) {
  return getText(...args);
}

export function hasText(id) {
  return data[id] ? true : false;
}

export function getText(id, defaultValue) {
  let str = data[id];

  if (str === undefined) {
    str = defaultValue != null ? defaultValue : id;
  }

  if (str && str.replace) {
    for (let n = 1; n < arguments.length; n++) {
      str = str.replace(new RegExp(`%${n}`, 'g'), arguments[n]);
    }
  }

  return str;
}

export function upperFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const isValidEmail = email =>
  /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/.test(
    email,
  );

export function capitalizeWords(string, wordSeparators = [' ']) {
  if (
    typeof string !== 'string' ||
    (typeof wordSeparators !== 'string' && !Array.isArray(wordSeparators))
  ) {
    return string;
  }
  let capitalizedString = string.toLowerCase();
  for (const separator of wordSeparators) {
    capitalizedString = capitalizedString
      .split(separator)
      .map(upperFirst)
      .join(separator);
  }
  return capitalizedString;
}

export function formatSentence(string) {
  if (typeof string !== 'string' || string === '') {
    return string;
  }
  let sentence = upperFirst(string);
  if (
    !sentence.endsWith('.') &&
    !sentence.endsWith('!') &&
    !sentence.endsWith('?')
  ) {
    sentence += '.';
  }
  return sentence;
}

export function zeroPad(number) {
  return number < 10 ? `0${number}` : `${number}`;
}

export function makeHighlightedTextNode(string, highlightString) {
  const index = string.toLowerCase().indexOf(highlightString.toLowerCase());
  if (index === -1) {
    return <span>{string}</span>;
  }
  return (
    <>
      <span>{string.slice(0, index)}</span>
      <em>{string.slice(index, index + highlightString.length)}</em>
      <span>{string.slice(index + highlightString.length)}</span>
    </>
  );
}
