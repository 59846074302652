import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import ContentWrapper from 'shared/components/layout/ContentWrapper';
import PageBody from 'shared/components/layout/PageBody';
import PageMeta from 'shared/components/PageMeta';
import withContentMenu from 'shared/containers/withContentMenu';
import { formatDate } from 'shared/utils/dateUtils';
import { getText } from 'shared/utils/Text';

import Button from '../button/Button';
import RadioInput from '../input/RadioInput';
import TextInput from '../input/TextInput';
import Loader from '../loader/Loader';
import styles from './index.module.scss';

const AliasForm = ({ invite, userEmail, onSubmitAlias, onDeleteInvite }) => {
  const [nameType, setNameType] = useState(
    invite.alias === userEmail ? 'email' : 'custom',
  );
  const [customAlias, setCustomAlias] = useState(invite.alias ?? '');
  const usesCustomAlias = nameType === 'custom';
  const inputValue = usesCustomAlias ? customAlias : userEmail;
  return (
    <div className={styles.form}>
      <div className={styles.radio}>
        <RadioInput
          name="nameType"
          value="email"
          checked={!usesCustomAlias}
          labelText={getText('invite-page-use-email-as-alias')}
          onChange={setNameType}
        />
      </div>
      <div className={styles.radio}>
        <RadioInput
          name="nameType"
          value="custom"
          checked={usesCustomAlias}
          labelText={getText('invite-page-use-custom-alias')}
          onChange={setNameType}
        />
      </div>
      <div className={styles.input}>
        <TextInput
          value={inputValue}
          placeholder={getText('invite-page-custom-alias-placeholder')}
          onChange={setCustomAlias}
          disabled={!usesCustomAlias}
          maxLength={80}
        />
      </div>
      <div className={styles.buttons}>
        <Button
          className={classNames(styles.button, styles.submit)}
          onClick={() => onSubmitAlias(inputValue)}
          disabled={inputValue.trim() === ''}
        >
          {invite.accepted
            ? getText('invite-page-update-alias-button')
            : getText('invite-page-join-bet-button')}
        </Button>
        <Button className={styles.button} onClick={onDeleteInvite}>
          {invite.accepted
            ? getText('invite-page-leave-bet-button')
            : getText('invite-page-decline-invite-button')}
        </Button>
      </div>
    </div>
  );
};

const InvitePage = ({
  type,
  betPath,
  invite,
  error,
  isLoading,
  userEmail,
  fetchInvite,
  setInviteAlias,
  deleteInvite,
  clearInvite,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchInvite();
    return () => clearInvite();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return (
      <PageBody alignTo="menu">
        <PageMeta
          theme="article"
          title={getText('invite-page-no-invite-found')}
        />
        <ContentWrapper columnWidth="narrow">
          <header className={styles.header}>
            <h1>{getText('invite-page-no-invite-found')}</h1>
          </header>
          <p className={styles.explanation}>
            {getText('invite-page-no-invite-explanation')}
          </p>
        </ContentWrapper>
      </PageBody>
    );
  }

  if (isLoading || !invite) {
    return (
      <div className={styles.loadingContainer}>
        <Loader visible />
      </div>
    );
  }

  if (invite === 'REDIRECT') {
    return <Redirect to={betPath} />;
  }

  if (invite === 'DELETED') {
    return (
      <PageBody alignTo="menu">
        <PageMeta theme="article" title={getText('invite-page-invite-title')} />
        <ContentWrapper columnWidth="narrow">
          <header className={styles.header}>
            <h1>{getText('invite-page-invite-title')}</h1>
          </header>
          <p className={styles.explanation}>
            {getText('invite-page-invite-removed-explanation')}
          </p>
        </ContentWrapper>
      </PageBody>
    );
  }

  return (
    <PageBody alignTo="menu">
      <PageMeta theme="article" title={getText('invite-page-invite-title')} />
      <ContentWrapper columnWidth="narrow">
        <header className={styles.header}>
          <h1>{getText('invite-page-invite-title')}</h1>
        </header>
        <table className={styles.betInfoTable}>
          <tbody>
            <tr>
              <td>{getText('invite-page-info-table-name')}</td>
              <td>{invite.name}</td>
            </tr>
            <tr>
              <td>{getText('invite-page-info-table-round')}</td>
              <td>
                {type === 'sport'
                  ? `${invite.product} ${invite.sportDate}`
                  : `${invite.betKey.betType} ${invite.trackName} ${formatDate(
                      invite.betKey.date,
                    )}`}
              </td>
            </tr>
            <tr>
              <td>{getText('invite-page-info-table-created-by')}</td>
              <td>{invite.creator}</td>
            </tr>
          </tbody>
        </table>
        {!invite.accepted ? (
          <p className={styles.explanation}>
            {getText('invite-page-invite-pending-explanation', invite.creator)}
          </p>
        ) : (
          <p className={styles.explanation}>
            {getText('invite-page-invite-accepted-explanation')}
          </p>
        )}
        <AliasForm
          invite={invite}
          userEmail={userEmail}
          onSubmitAlias={alias => setInviteAlias(alias)}
          onDeleteInvite={deleteInvite}
        />
      </ContentWrapper>
    </PageBody>
  );
};

export default withContentMenu(InvitePage);
