import { bool } from 'prop-types';

import Icon from './Icon';

// https://material.io/tools/icons/?search=err&icon=error&style=baseline
// https://material.io/tools/icons/?search=err&icon=error_outline&style=baseline
const ErrorCircle = ({ className, outline = false }) => (
  <Icon name="error-circle" className={className} viewBox="0 0 24 24">
    {outline ? (
      <>
        <path fill="none" d="M0 0h24v24H0V0z" />
        <path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
      </>
    ) : (
      <>
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
      </>
    )}
  </Icon>
);

ErrorCircle.propTypes = {
  outline: bool,
};

export default ErrorCircle;
