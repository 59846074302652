export const DEFAULT_POINT = 1;
export const DEFAULT_FACTOR = 1;
export const SVENSKA_SPEL_MAX_ROWS = 20000;
export const TOPPTIPSET_ROW_AMOUNTS = [1, 2, 3, 5, 10];
export const POWERPLAY_ROW_AMOUNTS = [1, 2, 5, 10];
export const BOMBEN_ROW_AMOUNTS = [1, 2, 5, 10];

export const ANALYSIS_SITES = {
  OVERODDS: 'OVERODDS',
  JOKER_SPORT: 'JOKER_SPORT',
};

export const DRAW_STATUS = {
  UNSTARTED: 'UNSTARTED',
  LIVE: 'LIVE',
  ENDED: 'ENDED',
};
