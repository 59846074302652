export const SPORT_UPDATE = 'SPORT_UPDATE';
export const ATG_UPDATE = 'ATG_UPDATE';
export const MESSAGES_PRIVATE = 'MESSAGE_PRIVATE';
export const USER_DATA_UPDATE = 'USER_DATA_UPDATE';

export const LIVE_CHAT_MESSAGE = 'LIVE_CHAT_MESSAGE';
export const LIVE_CHAT_MESSAGE_DELETE = 'LIVE_CHAT_MESSAGE_DELETE';
export const LIVE_CHAT_MESSAGE_UPDATE = 'LIVE_CHAT_MESSAGE_UPDATE';

export const LIVE_ACTIVE_UPDATE = 'LIVE_ACTIVE_UPDATE';

export const SHARED_BET_COMMENT = 'SHARED_BET_COMMENT';
export const SHARED_BET_UPDATE_ATG = 'SHARED_BET_UPDATE_ATG';
export const SHARED_BET_UPDATE_SPORT = 'SHARED_BET_UPDATE_SPORT';
export const SHARED_BET_CLAIM_UPDATE = 'SHARED_BET_CLAIM_UPDATE';
