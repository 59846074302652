import { createSelector } from 'reselect';

const contactsSelector = state => state.contacts;

export const isLoading = createSelector(
  contactsSelector,
  state => state.isLoading,
);

export const getSharedWithUsers = createSelector(
  contactsSelector,
  state => state.sharedWithUsers,
);
