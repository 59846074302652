import { getText, hasText } from './Text';

export const getErrorMessage = ({ response = {} } = {}, defaultMessage) => {
  const { messageId, message } = response;

  if (messageId && hasText(messageId)) {
    return getText(messageId);
  }

  if (message) {
    return message;
  }

  if (messageId) {
    return getText('error-message-unknown-id', messageId);
  }

  return (
    defaultMessage || getText('error-message-generic', 'Ett fel inträffade')
  );
};

export const getErrorDetails = ({ response = {} } = {}) => {
  const { messageId, details } = response;
  let detailsId;

  if (messageId) {
    detailsId = `${messageId}-details`;
  }

  if (detailsId && hasText(detailsId)) {
    return getText(detailsId);
  }

  if (details) {
    return details;
  }

  return;
};
