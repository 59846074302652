import dotProp from 'dot-prop-immutable';

import * as types from 'shared/redux/actionTypes';

const defaultState = {
  atg: {
    isLoading: false,
    error: null,
    timestamp: null,
  },
  sport: {
    isLoading: false,
    error: null,
    timestamp: null,
  },
  providers: {},
};

export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case types.REQUEST_BET_TIPS:
      return dotProp.set(state, `${action.meta.kind}.isLoading`, true);

    case types.RECEIVE_BET_TIPS: {
      let nextState = state;

      nextState = dotProp.set(state, action.meta.kind, {
        error: null,
        isLoading: false,
        timestamp: Date.now(),
      });

      nextState = dotProp.set(nextState, 'providers', providers => ({
        ...providers,
        ...action.payload,
      }));

      return nextState;
    }

    case types.RECEIVE_BET_TIPS_ERROR:
      return dotProp.set(state, action.meta.kind, {
        isLoading: false,
        error: action.payload,
      });

    default:
      return state;
  }
}
