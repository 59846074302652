import { Component } from 'react';
import { createPortal } from 'react-dom';

import Loader from './Loader';

export default class FullscreenLoader extends Component {
  static propTypes = {};

  static containerNode = null;

  constructor() {
    super();
    if (!FullscreenLoader.containerNode) {
      const node = document.createElement('div');
      document.body.appendChild(node);
      FullscreenLoader.containerNode = node;
    }
  }

  render() {
    // pathname can be set instead of backgroundAlpha to get a background
    // based on the path
    const { pathname } = this.props;
    let { backgroundAlpha } = this.props;
    if (pathname && !backgroundAlpha) {
      const isAtgPath = pathname.indexOf('/trav') === 0;
      const isSportPath = pathname.indexOf('/sport') === 0;
      backgroundAlpha = isAtgPath ? 'dark' : isSportPath ? 'none' : 'light';
    }

    return createPortal(
      <Loader
        {...this.props}
        alwaysShowLabel
        backgroundAlpha={backgroundAlpha ?? 'dark'}
        fullscreen
      />,
      FullscreenLoader.containerNode,
    );
  }
}
