import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import { formatLargeMoneyAmount } from 'shared/utils/money';

// Get highlight as next open event with matching bet type
export function getAtgHighlight(type, raceDays, jackpots) {
  const selected = raceDays
    // Reduce to a list with only the type we're looking for. We will need data from the race day later.
    .reduce((acc, day) => {
      const betType = day.betTypes.find(
        ({ betType, isOpenForPlay }) => betType === type && isOpenForPlay,
      );

      return betType == null
        ? acc
        : [
            ...acc,
            {
              day,
              betType,
            },
          ];
    }, [])
    // Sort by the start time of this bet type
    .sort(
      (a, b) =>
        a.betType.firstPostTimePerBetType - b.betType.firstPostTimePerBetType,
    )
    // We want the first in the sorted list
    .shift();

  if (selected == null) {
    return;
  }

  const { betType, day } = selected;
  const { sharedTrackKey, sharedTrack, firstPostTimePerBetType } = betType;
  const betParams = {
    betType: betType.betType,
    trackKey: sharedTrackKey != null ? sharedTrackKey : day.trackKey,
    date: day.date,
  };
  const jackpot = get(
    jackpots.find(({ betKey }) => isEqual(betKey, betParams)),
    'jackpot',
  );
  return {
    trackName:
      sharedTrack != null ? sharedTrack.domesticText : day.track.domesticText,
    betParams,
    startTime: firstPostTimePerBetType,
    jackpotAmount: jackpot
      ? formatLargeMoneyAmount(jackpot.sum, {
          currency: jackpot.currency,
          shortenMillionSuffix: true,
        })
      : undefined,
  };
}
