import isError from 'lodash/isError';
import { serializeError } from 'serialize-error';

import { NAVIGATOR_OFFLINE } from 'shared/constants/MessageTypes';
import { getErrorDetails, getErrorMessage } from 'shared/utils/errorUtils';
import Sentry from 'shared/utils/sentry';

import {
  addSiteMessage,
  showErrorDialog,
  unAuthorizedRequestError,
} from '../actions';

const getClientErrorType = ({ meta = {} }) => meta.errorType;
const getServerErrorType = ({ response = {} }) => response.type;
const getServerLogNumber = ({ response = {} }) => response.logNumber;

const isLikelyNetworkError = ({ name, message = '' }) => {
  if (!window.navigator.onLine) {
    return true;
  }

  // A RequestError is dispatched by redux-api-middleware when an error
  // occured while initiating the API call. It is either raised by the
  // middleware if a property of the RSAA threw or by fetch, either due
  // to malformed data or a network error. We filter out the former and
  // a fetch error will likely be due to a network error.
  if (name === 'RequestError' && !message.includes('RSAA')) {
    return true;
  }

  return false;
};

const middleware = store => next => action => {
  if (isError(action.payload)) {
    action.payload = serializeError(action.payload);
    const { status, name } = action.payload;

    if (isLikelyNetworkError(action.payload)) {
      store.dispatch(addSiteMessage(NAVIGATOR_OFFLINE));
    } else if (status === 401) {
      store.dispatch(unAuthorizedRequestError());
    } else if (
      name === 'RequestError' ||
      (status >= 400 &&
        status !== 404 &&
        getClientErrorType(action) !== 'discrete' &&
        getServerErrorType(action.payload) !== 'discrete')
    ) {
      store.dispatch(
        showErrorDialog({
          message: getErrorMessage(action.payload),
          details: getErrorDetails(action.payload),
          logNumber: getServerLogNumber(action.payload),
        }),
      );
    }
  }

  Sentry.addBreadcrumb({
    level: 'error',
    type: action.type,
    data: action.payload,
  });

  // Pass the FSA to the next action
  return next(action);
};

export default middleware;
