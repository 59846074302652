import { lazy, Suspense } from 'react';

const LivePageComponent = lazy(
  () => import(/* webpackChunkName: "live" */ 'live'),
);

const AsyncLiveApp = () => (
  <Suspense fallback={<span />}>
    <LivePageComponent />
  </Suspense>
);

export default AsyncLiveApp;
