import { createSelector } from 'reselect';

import { getSportEvents } from './calendarSelectors';

const betTipsSelector = state => state.betTips;

export const getTimeStamps = createSelector(betTipsSelector, betTips => ({
  atg: betTips.atg.timestamp,
  sport: betTips.sport.timestamp,
}));

export const getTipsError = createSelector(
  betTipsSelector,
  betTips => betTips.atg.error || betTips.sport.error,
);

export const isTipsLoading = createSelector(
  betTipsSelector,
  betTips => betTips.atg.isLoading || betTips.sport.isLoading,
);

export const getBetTips = createSelector(
  [betTipsSelector, getSportEvents],
  (betTips, sportEvents) => {
    const providerOrder = [
      'Överodds',
      'JokerSport',
      'Spelvärde',
      'Travögat',
      'Storavinster',
    ];

    return Object.keys(betTips.providers)
      .map(provider => ({
        provider,
        events: betTips.providers[provider].map(event => {
          if (provider === 'Överodds' || provider === 'JokerSport') {
            const { closeTime } =
              sportEvents.find(
                ({ betParams: { product, drawNr } }) =>
                  event.product === product && event.drawNr === drawNr,
              ) || {};
            return {
              ...event,
              closeTime,
            };
          }
          return event;
        }),
      }))
      .sort(
        (a, b) =>
          providerOrder.indexOf(a.provider) - providerOrder.indexOf(b.provider),
      );
  },
);

export const hasBetTips = createSelector(
  getTimeStamps,
  timestamps => timestamps.atg != null && timestamps.sport != null,
);
