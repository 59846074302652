import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { createSelector } from 'reselect';

const getUser = state => get(state, 'user');

const getResolved = createSelector(getUser, user => get(user, 'resolved'));

export const isLoggedIn = createSelector(
  getResolved,
  resolved => !isEmpty(get(resolved, 'userRole')),
);

export const hasSubscription = createSelector(getResolved, resolved =>
  get(resolved, 'hasPaidSubscription'),
);

export const getToken = createSelector(getResolved, resolved =>
  get(resolved, 'sessionId'),
);

export const getImageId = createSelector(getResolved, resolved =>
  get(resolved, 'imageId'),
);

export const getUserIsAdmin = createSelector(getResolved, resolved =>
  get(resolved, 'isAdmin', false),
);

export const getEmail = createSelector(getUser, user => get(user, 'email'));

export const getAlias = createSelector(getResolved, resolved =>
  get(resolved, 'alias', ''),
);

export const getFirstname = createSelector(getResolved, resolved =>
  get(resolved, 'firstName', ''),
);

export const getLastname = createSelector(getResolved, resolved =>
  get(resolved, 'lastName', ''),
);

export const getUserId = createSelector(getResolved, resolved =>
  get(resolved, 'uid'),
);

export const getName = createSelector(getUser, user => get(user, 'name'));

export const hasRole = role =>
  createSelector(getResolved, resolved =>
    get(resolved, 'userRole', []).some(
      ({ role: userRole }) => userRole === role,
    ),
  );

export const getEmployer = createSelector(getResolved, resolved =>
  get(resolved, 'employer'),
);

export const hasServerState = createSelector(getUser, user =>
  get(user, 'hasServerState'),
);

export const isLoading = createSelector(getUser, user =>
  get(user, 'isLoading'),
);

export const getError = createSelector(getUser, user => get(user, 'error'));

// Is user participating in Joker Live

export const getLiveParticipationState = createSelector(getUser, user =>
  get(user, 'liveParticipant'),
);

export const isLiveParticipant = createSelector(
  getLiveParticipationState,
  state => state.resolved,
);
