import dotProp from 'dot-prop-immutable';
import get from 'lodash/get';

import { USER_DATA_UPDATE } from 'shared/constants/StompKind';
import * as types from 'shared/redux/actionTypes';

const liveParticipantDefaultState = {
  error: undefined,
  loading: false,
  resolved: false,
};

const defaultState = {
  email: undefined,
  error: undefined,
  hasServerState: false,
  isLoading: false,
  resolved: undefined,
  liveParticipant: liveParticipantDefaultState,
};

export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case types.REQUEST_LOGIN_USER: {
      const { payload: email } = action;

      return {
        ...state,
        error: undefined,
        isLoading: true,
        email,
      };
    }

    case types.RECEIVE_LOGIN_USER_ERROR: {
      const { payload: error } = action;

      return {
        ...state,
        error,
        isLoading: false,
      };
    }

    case types.SET_USER: {
      const { payload: user } = action;

      return {
        ...state,
        resolved: user,
        email: get(user, 'email', state.email),
        isLoading: false,
        hasServerState: true,
        liveParticipant: {
          ...liveParticipantDefaultState,
          resolved: get(user, 'usesLive', state.liveParticipant.resolved),
        },
      };
    }

    case types.DELETE_USER: {
      const { email, hasServerState } = state;

      return {
        ...defaultState,
        email,
        hasServerState,
      };
    }

    case types.REQUEST_CHANGE_LIVE_PARTICIPATION: {
      return dotProp.set(state, 'liveParticipant', oldState => ({
        error: undefined,
        loading: true,
        resolved: oldState.resolved,
      }));
    }

    case types.RECEIVE_CHANGE_LIVE_PARTICIPATION: {
      return dotProp.set(state, 'liveParticipant', oldState => ({
        error: undefined,
        loading: false,
        resolved: !oldState.resolved,
      }));
    }

    case types.RECEIVE_CHANGE_LIVE_PARTICIPATION_ERROR: {
      return dotProp.set(state, 'liveParticipant', oldState => ({
        error: action.payload.message,
        loading: false,
        resolved: oldState.resolved,
      }));
    }

    case types.STOMP_RECEIVE_DATA: {
      const { kind, data } = action.payload;
      const alias = dotProp.get(data, 'alias');
      const imageId = dotProp.get(data, 'imageId');

      if (kind === USER_DATA_UPDATE) {
        return {
          ...state,
          resolved: {
            ...state.resolved,
            alias,
            imageId,
          },
          liveParticipant: {
            ...state.liveParticipant,
            resolved: alias ? state.liveParticipant.resolved : false,
          },
        };
      }
      return state;
    }

    default:
      return state;
  }
}
