import { SHARED_BET_UPDATE_SPORT } from 'shared/constants/StompKind';
import { RECEIVE_UPDATE_BET } from 'sport/redux/actionTypes';

import { STOMP_RECEIVE_DATA } from '../actionTypes';
import { getCollaboratorsList } from '../selectors/shareSelectors';

const middleware = store => next => action => {
  if (
    action.type === STOMP_RECEIVE_DATA &&
    action.payload.kind === SHARED_BET_UPDATE_SPORT
  ) {
    const updaterId = action.payload.data.updater;
    const collaborators = getCollaboratorsList(store.getState(), {
      betId: action.payload.data.sportBet.id,
      type: 'sport',
    });
    const myId = collaborators?.find(collaborator => collaborator.self)?.id;
    if (updaterId === myId) {
      return; // Ignore if update was triggered by self
    }
    // Modify action to become a normal RECEIVE_UPDATE_BET, but add a meta
    // flag since reducer merge rules may differ
    action.type = RECEIVE_UPDATE_BET;
    action.payload = action.payload.data.sportBet;
    action.meta = { betId: action.payload.id, isCollaborationUpdate: true };
  }
  return next(action);
};

export default middleware;
