import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

import { betIdCacheResolver, betIdSelector } from './shared';

const shareSelector = state => state.shares;

const typeCacheResolver = (_, { type }) => type;
const typeSelector = (_, { type }) => type;

/**
 * Creates a selector function for selecting a state of collaborators
 * @param  {string} type - The type for part of state to select
 */

const shareStateSelector = createCachedSelector(
  [shareSelector, typeSelector],
  (state, type) => state[type],
)(typeCacheResolver);

const shareCodesSelector = createCachedSelector(
  [shareStateSelector],
  shareState => shareState.shareCodes,
)(typeCacheResolver);

const collaboratorsSelector = createCachedSelector(
  [shareStateSelector],
  shareState => shareState.collaborators,
)(typeCacheResolver);

const resentInvitesSelector = createCachedSelector(
  [shareStateSelector],
  shareState => shareState.resentInvites,
)(typeCacheResolver);

const invitesSelector = createCachedSelector(
  [shareStateSelector],
  shareState => shareState.invites,
)(typeCacheResolver);

const commentsSelector = createCachedSelector(
  [shareStateSelector],
  shareState => shareState.comments,
)(typeCacheResolver);

const claimStatusSelector = createCachedSelector(
  [shareStateSelector],
  shareState => shareState.claimStatus,
)(typeCacheResolver);

const betUpdatesSelector = createCachedSelector(
  [shareStateSelector],
  shareState => shareState.betUpdates,
)(typeCacheResolver);

/**
 * Creates a selector function for selecting share code for a bet
 * @param  {number} betId - The id for the bet to select
 */
export const getShareCode = createCachedSelector(
  [shareCodesSelector, betIdSelector],
  (shareCodes, betId) => shareCodes.resolved[betId],
)(betIdCacheResolver);

/**
 * Creates a selector function for selecting loading status of a share code
 * @param  {number} betId - The id for the bet to select
 */
export const isShareCodeLoading = createCachedSelector(
  [shareCodesSelector, betIdSelector],
  (shareCodes, betId) => shareCodes.loading[betId] === true,
)(betIdCacheResolver);

/**
 * Creates a selector function for selecting share code error
 * @param  {number} betId - The id for the bet to select
 */
export const getShareCodeError = createCachedSelector(
  [shareCodesSelector, betIdSelector],
  (shareCodes, betId) => shareCodes.errors[betId],
)(betIdCacheResolver);

/**
 * Creates a selector function for selecting list of collaborators
 * @param  {string} shareCode - The share code for the bet to select
 */

export const getCollaboratorsList = createCachedSelector(
  [collaboratorsSelector, betIdSelector],
  (collaborators, betId) => collaborators.resolved[betId],
)(betIdCacheResolver);

export const getMyPermission = createSelector(
  getCollaboratorsList,
  collaborators => collaborators?.find(collaborator => collaborator.self)?.type,
);

export const isOwner = createSelector(getCollaboratorsList, collaborators =>
  collaborators?.some(collaborator => collaborator.self && collaborator.owner),
);

/**
 * Creates a selector function for selecting loading status of collaborators
 * @param  {string} betId - The bet id
 */
export const isCollaboratorsLoading = createCachedSelector(
  [collaboratorsSelector, betIdSelector],
  (collaborators, betId) => collaborators.loading[betId] === true,
)(betIdCacheResolver);

/**
 * Creates a selector function for selecting collaborators errors
 * @param  {string} shareCode - The share code for the bet to select
 */
export const getCollaboratorsError = createCachedSelector(
  [collaboratorsSelector, betIdSelector],
  (collaborators, betId) => collaborators.errors[betId],
)(betIdCacheResolver);

/**
 * Creates a selector function for selecting list of resent invites
 * @param  {string} betId - The bet id
 */
export const getCompleteResentInvites = createCachedSelector(
  [resentInvitesSelector, betIdSelector],
  (resentInvites, betId) =>
    Object.keys(resentInvites.resolved[betId] ?? {}).map(Number),
)(betIdCacheResolver);

/**
 * Creates a selector function for selecting loading status of resent invites
 * @param  {string} betId - The bet id
 */
export const getLoadingResentInvites = createCachedSelector(
  [resentInvitesSelector, betIdSelector],
  (resentInvites, betId) =>
    Object.keys(resentInvites.loading[betId] ?? {}).map(Number),
)(betIdCacheResolver);

/**
 * Creates a selector function for selecting list of resent invites
 * @param  {string} betId - The bet id
 */
export const getInvite = createCachedSelector(
  [invitesSelector, betIdSelector],
  (invites, betId) => invites.resolved[betId],
)(betIdCacheResolver);

/**
 * Creates a selector function for selecting loading status of resent invites
 * @param  {string} betId - The bet id
 */
export const getLoadingInvite = createCachedSelector(
  [invitesSelector, betIdSelector],
  (invites, betId) => invites.loading[betId],
)(betIdCacheResolver);

/**
 * Creates a selector function for selecting loading status of resent invites
 * @param  {string} betId - The bet id
 */
export const getInviteError = createCachedSelector(
  [invitesSelector, betIdSelector],
  (invites, betId) => invites.errors[betId],
)(betIdCacheResolver);

/**
 * Creates a selector function for selecting collaborators errors
 * @param  {string} shareCode - The share code for the bet to select
 */
export const getCommentsError = createCachedSelector(
  [commentsSelector, betIdSelector],
  (comments, betId) => comments.errors[betId],
)(betIdCacheResolver);

/**
 * Creates a selector function for selecting list of collaborators
 * @param  {string} shareCode - The share code for the bet to select
 */

export const getCommentsList = createCachedSelector(
  [commentsSelector, betIdSelector],
  (comments, betId) => comments.resolved[betId],
)(betIdCacheResolver);

export const getClaimStatus = createCachedSelector(
  [claimStatusSelector, betIdSelector],
  (claimStatus, betId) => claimStatus?.resolved[betId],
)(betIdCacheResolver);

export const getLastBetUpdate = createCachedSelector(
  [betUpdatesSelector, betIdSelector],
  (betUpdates, betId) => betUpdates?.resolved[betId],
)(betIdCacheResolver);

/**
 * Creates a selector function for selecting loading status of collaborators
 * @param  {string} betId - The bet id
 */
export const isCommentsLoading = createCachedSelector(
  [commentsSelector, betIdSelector],
  (comments, betId) => comments.loading[betId] === true,
)(betIdCacheResolver);
