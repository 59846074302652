import { ALERT_UPDATE } from 'shared/constants/TraisSocketKinds';

import { onTraisSocketMessage } from '../actions';
import {
  TRAIS_SOCKET_SUBSCRIBE,
  TRAIS_SOCKET_UNSUBSCRIBE,
} from '../actionTypes';

// Wait at least 3 seconds before reconnecting
const MIN_RECONNECT_DELAY = 3000;
// Max delay between reconnections is 1 minute
const MAX_RECONNECT_DELAY = 60000;

const getSubscriptionString = kind => {
  if (kind === ALERT_UPDATE) {
    return `content:alert:${process.env.REACT_APP_TRAIS_SITE}`;
  }
  throw new Error(`Unexpected kind argument ${kind}`);
};

const middleware = store => {
  let socket;
  let connectDelay = 0;
  const subscriptions = new Set();

  const sendSubscriptions = () => {
    socket.send(`set-subscriptions|${JSON.stringify([...subscriptions])}`);
  };

  const subscribe = channel => {
    subscriptions.add(channel);
    // Send subscriptions if socket already open.
    // Otherwise they're sent upon socket opening.
    if (socket?.readyState === WebSocket.OPEN) {
      sendSubscriptions();
    }
  };

  const unsubscribe = channel => {
    subscriptions.delete(channel);
    // Send subscriptions if socket already open.
    // Otherwise they're sent upon socket opening.
    if (socket?.readyState === WebSocket.OPEN) {
      sendSubscriptions();
    }
  };

  const onMessage = event => {
    try {
      const message = JSON.parse(event.data);
      if (message.type === 'CONTENT_UPDATE' && message.data.type === 'alert') {
        store.dispatch(onTraisSocketMessage(message, ALERT_UPDATE));
      }
    } catch {
      console.info('unexpected socket message', event);
    }
  };

  const connect = () => {
    setTimeout(() => {
      socket = new WebSocket(process.env.REACT_APP_TRAIS_SOCKET_URL);
      socket.addEventListener('message', onMessage);
      socket.addEventListener('close', () => {
        // Wait previous delay plus 0-5 seconds, within min-max interval.
        connectDelay = Math.min(
          MAX_RECONNECT_DELAY,
          Math.max(MIN_RECONNECT_DELAY, connectDelay + 5000 * Math.random()),
        );
        connect();
      });
      socket.addEventListener('open', () => {
        // Reset for future reconnection
        connectDelay = 0;
        // If existing subscriptions, send them
        if (subscriptions.size > 0) {
          sendSubscriptions();
        }
      });
    }, connectDelay);
  };

  connect();

  return next => async action => {
    if (action.type === TRAIS_SOCKET_SUBSCRIBE) {
      subscribe(getSubscriptionString(action.payload.kind));
    } else if (action.type === TRAIS_SOCKET_UNSUBSCRIBE) {
      unsubscribe(getSubscriptionString(action.payload.kind));
    }

    return next(action);
  };
};

export default middleware;
