// chat

export const LIVE_CHAT_SET_PAGE = 'LIVE_CHAT_SET_PAGE';

export const LIVE_CHAT_REQUEST = 'LIVE_CHAT_REQUEST';
export const LIVE_CHAT_RECEIVE = 'LIVE_CHAT_RECEIVE';
export const LIVE_CHAT_ERROR = 'LIVE_CHAT_ERROR';

export const LIVE_CHAT_MESSAGE_EDIT = 'LIVE_CHAT_MESSAGE_EDIT';
export const LIVE_CHAT_MESSAGE_REQUEST = 'LIVE_CHAT_MESSAGE_REQUEST';
export const LIVE_CHAT_MESSAGE_RECEIVE = 'LIVE_CHAT_MESSAGE_RECEIVE';
export const LIVE_CHAT_MESSAGE_ERROR = 'LIVE_CHAT_MESSAGE_ERROR';

export const LIVE_CHAT_USERS_REQUEST = 'LIVE_CHAT_USERS_REQUEST';
export const LIVE_CHAT_USERS_RECEIVE = 'LIVE_CHAT_USERS_RECEIVE';
export const LIVE_CHAT_USERS_ERROR = 'LIVE_CHAT_USERS_ERROR';

// (dis)like messages

export const LIVE_CHAT_MESSAGE_RATE_REQUEST = 'LIVE_CHAT_MESSAGE_RATE_REQUEST';
export const LIVE_CHAT_MESSAGE_RATE_RECEIVE = 'LIVE_CHAT_MESSAGE_RATE_RECEIVE';
export const LIVE_CHAT_MESSAGE_RATE_ERROR = 'LIVE_CHAT_MESSAGE_RATE_ERROR';

// delete messages

export const LIVE_CHAT_MESSAGE_DELETE_REQUEST =
  'LIVE_CHAT_MESSAGE_DELETE_REQUEST';
export const LIVE_CHAT_MESSAGE_DELETE_RECEIVE =
  'LIVE_CHAT_MESSAGE_DELETE_RECEIVE';
export const LIVE_CHAT_MESSAGE_DELETE_ERROR = 'LIVE_CHAT_MESSAGE_DELETE_ERROR';

// block messages (sender of message)

export const LIVE_CHAT_MESSAGE_BLOCK_REQUEST =
  'LIVE_CHAT_MESSAGE_BLOCK_REQUEST';
export const LIVE_CHAT_MESSAGE_BLOCK_RECEIVE =
  'LIVE_CHAT_MESSAGE_BLOCK_RECEIVE';
export const LIVE_CHAT_MESSAGE_BLOCK_ERROR = 'LIVE_CHAT_MESSAGE_BLOCK_ERROR';

// top list (top profits and top dividends)

export const LIVE_TOP_LIST_REQUEST = 'LIVE_TOP_LIST_REQUEST';
export const LIVE_TOP_LIST_RECEIVE = 'LIVE_TOP_LIST_RECEIVE';
export const LIVE_TOP_LIST_ERROR = 'LIVE_TOP_LIST_ERROR';

// delete game

export const LIVE_GAME_DELETE_REQUEST = 'LIVE_GAME_DELETE_REQUEST';
export const LIVE_GAME_DELETE_RECEIVE = 'LIVE_GAME_DELETE_RECEIVE';
export const LIVE_GAME_DELETE_ERROR = 'LIVE_GAME_DELETE_ERROR';

// load games

export const LIVE_GAMES_REQUEST = 'LIVE_GAMES_REQUEST';
export const LIVE_GAMES_RECEIVE = 'LIVE_GAMES_RECEIVE';
export const LIVE_GAMES_ERROR = 'LIVE_GAMES_ERROR';
export const LIVE_GAMES_CLEAR = 'LIVE_GAMES_CLEAR';

// add game to live

export const LIVE_GAME_PLAY_REQUEST = 'LIVE_GAME_PLAY_REQUEST';
export const LIVE_GAME_PLAY_RECEIVE = 'LIVE_GAME_PLAY_RECEIVE';
export const LIVE_GAME_PLAY_ERROR = 'LIVE_GAME_PLAY_ERROR';

// etc

export const LIVE_GAME_SET = 'LIVE_GAME_SET';
export const LIVE_SET_SECTION = 'LIVE_SET_SECTION';
