export const CONFIRM = 'CONFIRM';
export const ALERT = 'ALERT';
export const HELP = 'HELP';
export const LOCKED_ALERT = 'LOCKED_ALERT';
export const CLAIMED_ALERT = 'CLAIMED_ALERT';
export const GUARANTEE_ALERT = 'GUARANTEE_ALERT';
export const ERROR = 'ERROR';
export const ATG_TEMPLATE = 'ATG_TEMPLATE';
export const ATG_REPORT = 'ATG_REPORT';
export const ATG_COUPONS = 'ATG_COUPONS';
export const ATG_ARCHIVE = 'ATG_ARCHIVE';
export const ATG_SHARE = 'ATG_SHARE';
export const ATG_SETTINGS = 'ATG_SETTINGS';
export const ATG_BET_CONFIG = 'ATG_BET_CONFIG';
export const SPORT_ARCHIVE = 'SPORT_ARCHIVE';
export const SPORT_SHARE = 'SPORT_SHARE';
export const SPORT_EXPORT = 'SPORT_EXPORT';
export const SPORT_PUBLISH = 'SPORT_PUBLISH';
export const JOKERWIZ_DIALOG = 'JOKERWIZ_DIALOG';
export const LIVE_NO_ALIAS_DIALOG = 'LIVE_NO_ALIAS_DIALOG';
export const MANAGE_HORSE = 'MANAGE_HORSE';
