import classnames from 'classnames';
import { node, oneOf, string } from 'prop-types';

const ContentWrapper = ({
  children,
  columnWidth = 'wide',
  columnHeight = 'auto',
  className,
}) => (
  <div
    className={classnames(
      'content-wrapper',
      `content-wrapper--${columnWidth}`,
      { 'content-wrapper--v100': columnHeight === 'full' },
      className,
    )}
  >
    {children}
  </div>
);

ContentWrapper.propTypes = {
  children: node.isRequired,
  className: string,
  columnWidth: oneOf(['full', 'wide', 'narrow', 'compact']),
  columnHeight: oneOf(['full', 'auto']),
};

export default ContentWrapper;
