import { useEffectOnce } from '@trmediaab/zebra-hooks';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';

import Button from 'shared/components/button/Button';
import CloseButton from 'shared/components/button/CloseButton';
import AddCircle from 'shared/components/icon/AddCircle';
import Edit from 'shared/components/icon/Edit';
import CheckboxInput from 'shared/components/input/CheckboxInput';
import Textarea from 'shared/components/input/Textarea';
import Loader from 'shared/components/loader/Loader';
import { formatDateDayMonth } from 'shared/utils/dateUtils';
import { getText } from 'shared/utils/Text';

import styles from './index.module.scss';

const ManageHorseDialog = ({
  myHorses,
  horseName,
  raceName,
  startId,
  startComment,
  atgHorseId,
  roundSlug,
  traisHorseId,
  fetchTraisHorse,
  fetchRound,
  fetchStartComment,
  addStartComment,
  editStartComment,
  deleteStartComment,
  addHorse,
  editHorse,
  removeHorse,
  showConfirmDialog,
  closeHandler,
}) => {
  useEffectOnce(() => {
    if (roundSlug) {
      fetchRound();
    }
    if (atgHorseId) {
      fetchTraisHorse();
    }
  });

  useEffect(() => {
    if (startId && startComment === undefined) {
      fetchStartComment(startId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startId, startComment]);

  const [comment, setComment] = useState('');
  const [monitor, setMonitor] = useState(false);

  const [isEditingComment, setIsEditingComment] = useState(false);
  const [editedComment, setEditedComment] = useState('');

  const [isEditingStartComment, setIsEditingStartComment] = useState(false);
  const [editedStartComment, setEditedStartComment] = useState('');

  const myHorse = useMemo(
    () => myHorses.find(horse => horse.horseId === traisHorseId),
    [myHorses, traisHorseId],
  );

  let dialogContent;
  let startCommentSection;

  const shouldRenderStartCommentSection = Boolean(roundSlug || startId);
  const shouldRenderMonitorHorseSection = Boolean(atgHorseId);

  const pendingStartCommentSection =
    shouldRenderStartCommentSection && startComment === undefined;
  const pendingMonitorHorseSection =
    shouldRenderMonitorHorseSection && !traisHorseId;

  if (pendingStartCommentSection || pendingMonitorHorseSection) {
    dialogContent = <Loader cover visible />;
  } else {
    if (shouldRenderStartCommentSection) {
      startCommentSection = (
        <div className={styles.section}>
          <div className={styles.sectionTitle}>
            {getText('manage-horse-dialog-race-title', raceName)}
          </div>
          {isEditingStartComment && (
            <div>
              <Textarea
                maxLength={400}
                rows="5"
                value={editedStartComment}
                onChange={evt => setEditedStartComment(evt.target.value)}
                className={styles.textArea}
              />
              <div className={styles.commentEditButtonContainer}>
                <Button
                  onClick={() => {
                    if (startComment) {
                      editStartComment(startId, editedStartComment);
                    } else {
                      addStartComment(startId, editedStartComment);
                    }
                    setIsEditingStartComment(false);
                  }}
                  className={classNames(styles.button, styles.submit)}
                >
                  {startComment
                    ? getText('monitor-horse-comment-save-changes')
                    : getText('monitor-horse-comment-add')}
                </Button>
                <Button
                  onClick={() => {
                    setIsEditingStartComment(false);
                    setEditedStartComment(startComment ?? '');
                  }}
                  className={classNames(styles.button, styles.cancel)}
                >
                  {getText('monitor-horse-cancel')}
                </Button>
              </div>
            </div>
          )}
          {!isEditingStartComment && (
            <div>
              {startComment && (
                <div className={styles.comment}>{startComment}</div>
              )}
              <div className={styles.commentButtonContainer}>
                {startComment ? (
                  <>
                    <Button
                      className={styles.commentButton}
                      onClick={() => {
                        setEditedStartComment(startComment ?? '');
                        setIsEditingStartComment(true);
                      }}
                    >
                      <Edit className={styles.penIcon} />
                      {getText('monitor-horse-edit-comment')}
                    </Button>
                    <Button
                      className={classNames(
                        styles.commentButton,
                        styles.deleteCommentButton,
                      )}
                      onClick={() => {
                        showConfirmDialog({
                          title: getText(
                            'monitor-horse-confirm-start-comment-deletion',
                            horseName,
                          ),
                          onConfirm: () => deleteStartComment(startId),
                        });
                      }}
                    >
                      <AddCircle />
                      {getText('monitor-horse-delete-start-comment')}
                    </Button>
                  </>
                ) : (
                  <Button
                    className={styles.commentButton}
                    onClick={() => setIsEditingStartComment(true)}
                  >
                    <AddCircle />
                    {getText('monitor-horse-add-own-start-comment')}
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      );
    }
    if (shouldRenderMonitorHorseSection) {
      if (myHorse) {
        dialogContent = (
          <div className={styles.section}>
            <div className={styles.sectionTitle}>
              {getText('manage-horse-dialog-monitor-title')}
            </div>
            {isEditingComment && (
              <div className={styles.commentContainer}>
                <Textarea
                  maxLength={400}
                  rows="5"
                  value={editedComment}
                  onChange={evt => setEditedComment(evt.target.value)}
                  className={styles.textArea}
                />
                <div className={styles.commentEditButtonContainer}>
                  <Button
                    onClick={() => {
                      editHorse(traisHorseId, {
                        comment: editedComment,
                        notify: myHorse.notify,
                      });
                      setIsEditingComment(false);
                    }}
                    className={classNames(styles.button, styles.submit)}
                  >
                    {myHorse.comment
                      ? getText('monitor-horse-comment-save-changes')
                      : getText('monitor-horse-comment-add')}
                  </Button>
                  <Button
                    onClick={() => {
                      setIsEditingComment(false);
                      setEditedComment(comment ?? '');
                    }}
                    className={classNames(styles.button, styles.cancel)}
                  >
                    {getText('monitor-horse-cancel')}
                  </Button>
                </div>
              </div>
            )}
            {!isEditingComment && (
              <div className={styles.commentContainer}>
                {myHorse.comment && (
                  <div className={styles.comment}>{myHorse.comment}</div>
                )}
                {myHorse.commentDate && (
                  <div className={styles.commentDate}>
                    {formatDateDayMonth(myHorse.commentDate)}
                  </div>
                )}
                <div className={styles.commentButtonContainer}>
                  {myHorse.comment ? (
                    <>
                      <Button
                        className={styles.commentButton}
                        onClick={() => {
                          setEditedComment(myHorse.comment ?? '');
                          setIsEditingComment(true);
                        }}
                      >
                        <Edit className={styles.penIcon} />
                        {getText('monitor-horse-edit-comment')}
                      </Button>
                      <Button
                        className={classNames(
                          styles.commentButton,
                          styles.deleteCommentButton,
                        )}
                        onClick={() => {
                          if (!myHorse.notify) {
                            showConfirmDialog({
                              title: getText(
                                'monitor-horse-confirm-indirect-deletion',
                                horseName,
                              ),
                              onConfirm: () => removeHorse(traisHorseId),
                            });
                          } else {
                            showConfirmDialog({
                              title: getText(
                                'monitor-horse-confirm-comment-deletion',
                                horseName,
                              ),
                              onConfirm: () =>
                                editHorse(traisHorseId, {
                                  comment: null,
                                  notify: myHorse.notify,
                                }),
                            });
                          }
                        }}
                      >
                        <AddCircle />
                        {getText('monitor-horse-delete-comment')}
                      </Button>
                    </>
                  ) : (
                    <Button
                      className={styles.commentButton}
                      onClick={() => setIsEditingComment(true)}
                    >
                      <AddCircle />
                      {getText('monitor-horse-add-comment')}
                    </Button>
                  )}
                </div>
              </div>
            )}
            <div className={styles.checkboxContainer}>
              <CheckboxInput
                small
                checked={myHorse.notify}
                onChange={evt => {
                  if (myHorse.notify && !myHorse.comment) {
                    showConfirmDialog({
                      title: getText(
                        'monitor-horse-confirm-indirect-deletion',
                        horseName,
                      ),
                      onConfirm: () => removeHorse(traisHorseId),
                    });
                  } else {
                    editHorse(traisHorseId, {
                      comment: myHorse.comment,
                      notify: evt.target.checked,
                    });
                  }
                }}
                colorFill
                labelText={getText('monitor-horse-notify-checkbox')}
              />
            </div>
            <div className={styles.commentButtonContainer}>
              <Button
                className={classNames(
                  styles.commentButton,
                  styles.deleteCommentButton,
                )}
                onClick={() => {
                  showConfirmDialog({
                    title: getText('monitor-horse-confirm-delete', horseName),
                    onConfirm: () => removeHorse(traisHorseId),
                  });
                }}
              >
                <AddCircle />
                <span>{getText('monitor-horse-delete')}</span>
              </Button>
            </div>
          </div>
        );
      } else {
        dialogContent = (
          <div className={styles.section}>
            <div className={styles.sectionTitle}>
              {getText('manage-horse-dialog-monitor-title')}
            </div>
            <div className={styles.commentContainer}>
              <div className={styles.inputTitle}>
                {getText('manage-horse-dialog-comment-label')}
              </div>
              <Textarea
                maxLength={400}
                rows="5"
                value={comment}
                className={styles.textArea}
                onChange={evt => setComment(evt.target.value)}
              />
            </div>
            <div className={styles.checkboxContainer}>
              <CheckboxInput
                small
                checked={monitor}
                onChange={evt => setMonitor(evt.target.checked)}
                colorFill
                labelText={getText('monitor-horse-notify-checkbox')}
              />
            </div>
            <div className={styles.commitButtons}>
              <Button
                className={classNames(styles.button, styles.submit)}
                onClick={async () => {
                  await addHorse(traisHorseId, comment.trim(), monitor);
                  setComment('');
                  setMonitor(false);
                }}
                disabled={!monitor && !comment.trim()}
              >
                {getText('monitor-add-horse-add')}
              </Button>
            </div>
          </div>
        );
      }
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <CloseButton onClick={closeHandler} />
        <h1>{horseName}</h1>
      </div>

      <div className={styles.body}>
        {startCommentSection}
        {dialogContent}
      </div>
    </div>
  );
};

/**
 * The ManageHorseDialog will render different sections depending on the props passed to it.
 * To show the start comment section, it must receive a roundSlug (to derive startId) or startId prop.
 * To show monitor horse section, it must receive an atgHorseId, to look up its current monitor state.
 * By omitting these props, the dialog creator can choose what to exclude from the dialog.
 */

ManageHorseDialog.propTypes = {
  horseName: PropTypes.string.isRequired,
  closeHandler: PropTypes.func.isRequired,
  roundSlug: PropTypes.string,
  atgHorseId: PropTypes.number,
  startId: PropTypes.number,
};

export default ManageHorseDialog;
