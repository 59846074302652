import * as types from '../actionTypes';

const defaultState = {
  isLoading: false,
  error: null,
  sharedWithUsers: [],
};

export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case types.RECEIVE_SHARED_WITH_USERS:
      return {
        ...state,
        sharedWithUsers: action.payload,
      };

    default:
      return state;
  }
}
