import { lazy, Suspense } from 'react';

import FullscreenLoader from './loader/FullscreenLoader';

const AtgComponent = lazy(() => import(/* webpackChunkName: "atg" */ 'atg'));

const AsyncAtgApp = () => (
  <Suspense
    fallback={<FullscreenLoader visible labelProp="main-loader-atg-heading" />}
  >
    <AtgComponent entryPoint="route" />
  </Suspense>
);

// TODO loader
export default AsyncAtgApp;

// TODO loader
export const AsyncCreateBet = props => (
  <Suspense fallback={<span />}>
    <AtgComponent entryPoint="create" {...props} />
  </Suspense>
);

// TODO loader
export const AsyncJokerWiz = props => (
  <Suspense fallback={<span />}>
    <AtgComponent entryPoint="jokerwiz" {...props} />
  </Suspense>
);

// TODO loader
export const AsyncDialogs = props => (
  <Suspense fallback={<span />}>
    <AtgComponent entryPoint="dialogs" {...props} />
  </Suspense>
);
