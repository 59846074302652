import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';

import CheckoutPage from 'shared/components/CheckoutPage';
import { user } from 'shared/redux/selectors';
import { parseQueryString } from 'shared/utils';

const CheckoutPageContainer = () => {
  const isLoggedIn = useSelector(user.isLoggedIn);
  const { skuOrCampaignId } = useParams();
  const location = useLocation();
  // Pass down orderId that we'll get if the user
  // has returned from a 3-D Secure verification
  const { orderId } = parseQueryString(location.search);

  let sku;
  let campaignId;

  if (/^[\da-f]{24}$/.test(skuOrCampaignId)) {
    campaignId = skuOrCampaignId;
  } else {
    sku = skuOrCampaignId;
  }

  return (
    <CheckoutPage
      isLoggedIn={isLoggedIn}
      campaignId={campaignId}
      sku={sku}
      orderId={orderId}
    />
  );
};

export default CheckoutPageContainer;
