import config from 'config';
import { RSAA } from 'redux-api-middleware';

import * as types from '../actionTypes';
import { instructions } from '../selectors';

const makeUrl = playlistId =>
  `${
    'https://www.googleapis.com/youtube/v3/playlistItems' +
    '?part=id,snippet,status&' +
    '&key='
  }${config.youtubeApiKey}&maxResults=50&playlistId=${playlistId}`;

export const fetchInstructions = playlistId => ({
  [RSAA]: {
    endpoint: makeUrl(playlistId),
    bailout: state =>
      instructions.getPlaylistItems(state, { playlistId }).length > 0,
    method: 'GET',
    credentials: 'omit',
    types: [
      { type: types.REQUEST_INSTRUCTIONS, meta: { playlistId } },
      { type: types.RECEIVE_INSTRUCTIONS, meta: { playlistId } },
      {
        type: types.RECEIVE_INSTRUCTIONS_ERROR,
        meta: { playlistId, errorType: 'discrete' },
      },
    ],
  },
});
