import Icon from './Icon';

const User = ({ className }) => (
  <Icon name="user" className={className} viewBox="0 0 11 11">
    <g fill="#fff" fillRule="evenodd">
      <path d="M5.5,0 C7.0125,0 8.25,1.2375 8.25,2.75 C8.25,4.2625 7.0125,5.5 5.5,5.5 C3.9875,5.5 2.75,4.2625 2.75,2.75 C2.75,1.2375 3.9875,0 5.5,0 Z"></path>
      <path d="M11,11 L0,11 L0,9.625 C0,7.79625 3.67125,6.875 5.5,6.875 C7.32875,6.875 11,7.79625 11,9.625 L11,11 Z"></path>
    </g>
  </Icon>
);

// From overodds
export default User;
