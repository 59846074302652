import amplitude from 'amplitude-js';
import config from 'config';

import { JOKER_LOADED } from 'shared/constants/TrackingConstants';
import * as types from 'shared/redux/actionTypes';
import { user as userSelectors } from 'shared/redux/selectors';

const middleware = store => {
  const amplitudeClient = amplitude.getInstance();
  amplitudeClient.init(config.amplitudeApiKey);
  amplitudeClient.logEvent(JOKER_LOADED);

  return next => action => {
    if (action.type === types.SET_USER) {
      const returnValue = next(action);

      if (userSelectors.isLoggedIn(store.getState())) {
        const userId = userSelectors.getUserId(store.getState());
        amplitudeClient.setUserId(userId);
        amplitudeClient.setUserProperties({
          subscription: userSelectors.hasSubscription(store.getState())
            ? 'Joker bas'
            : 'free',
        });
      }

      return returnValue;
    }

    if (action.type === types.TRACK_USER) {
      const { eventName, eventDetails } = action.payload;
      amplitudeClient.logEvent(eventName, eventDetails);
    }
    return next(action);
  };
};

export default middleware;
