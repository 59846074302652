import config from 'config';
import { string } from 'prop-types';

const reLink = /<a /gi;

const insertAttributesIntoLinks = html =>
  html.replace(reLink, `<a target="_blank" rel="noopener noreferrer" `);

const insertAgentIdIntoRikstotoLinks = html =>
  html.replace(/href="(https:\/\/[^"]+)"/g, (hrefAttr, url) => {
    const lowerCaseUrl = url.toLowerCase();
    if (
      lowerCaseUrl.includes('rikstoto.no') &&
      !lowerCaseUrl.includes('agentid=') &&
      !lowerCaseUrl.includes(config.rikstotoAgentId)
    ) {
      const queryParamPrefix = url.includes('?') ? '&' : '?';
      const newUrl = `${url}${queryParamPrefix}agentId=${config.rikstotoAgentId}`;
      return `href="${newUrl}"`;
    }
    return hrefAttr;
  });

// Insert target and rel attributes to all links,
// and agentId parameter to all Rikstoto links for commission.
const transformHtml = html => {
  if (typeof html !== 'string') {
    return html;
  }
  let transformedHtml = insertAttributesIntoLinks(html);
  if (IS_NO_SITE) {
    transformedHtml = insertAgentIdIntoRikstotoLinks(transformedHtml);
  }
  return transformedHtml;
};

const HTMLContent = ({ content, className }) => (
  <div
    className={className}
    dangerouslySetInnerHTML={{ __html: transformHtml(content) }}
  />
);

HTMLContent.propTypes = {
  content: string,
  className: string,
};

export default HTMLContent;
