import * as types from 'live/redux/actionTypes';
import noop from 'lodash/noop';
import { RSAA } from 'redux-api-middleware';

export const livePlayGame = ({
  betId,
  type,
  resolve = noop,
  reject = noop,
}) => ({
  [RSAA]: {
    endpoint: `live/play/${type}/${betId}`,
    method: 'POST',
    headers: {
      betId,
    },
    types: [
      { type: types.LIVE_GAME_PLAY_REQUEST, meta: { type, betId } },
      {
        type: types.LIVE_GAME_PLAY_RECEIVE,
        meta: { type, betId },
        payload: () => resolve(),
      },
      {
        type: types.LIVE_GAME_PLAY_ERROR,
        meta: { type, betId },
        payload: (_action, _state, error) => reject(error),
      },
    ],
  },
});
