import dotProp from 'dot-prop-immutable';

import * as types from '../actionTypes';

const defaultState = {
  myHorses: {
    items: [],
    loading: false,
    hasLoadedItems: false,
    loadingDict: {},
    error: undefined,
  },
  horseDetails: {
    resolved: {},
    fullyLoaded: {}, // Marks horses with both details start comments loaded
    loadingDict: {},
    errorDict: {},
  },
  startComments: {
    resolved: {},
    resolvedHorseIdDict: {},
    loadingDict: {},
    loadingHorseIdDict: {},
    errorDict: {},
  },
  settings: {
    resolved: null,
    loading: false,
    error: null,
  },
  rounds: {
    resolved: {},
    loadingDict: {},
    errorDict: {},
  },
  traisHorseIds: {
    resolved: {},
    loadingDict: {},
    errorDict: {},
  },
};

export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case types.RECEIVE_ROUND: {
      return dotProp.set(
        state,
        `rounds.resolved.${action.payload.slug.toLowerCase()}`,
        action.payload,
      );
    }

    case types.RECEIVE_TRAIS_HORSE: {
      return dotProp.set(
        state,
        `traisHorseIds.resolved.${action.payload.ais_id}`,
        action.payload.id,
      );
    }

    case types.REQUEST_MY_HORSES: {
      return {
        ...state,
        myHorses: {
          ...state.myHorses,
          loading: true,
          error: undefined,
        },
      };
    }

    case types.RECEIVE_MY_HORSES: {
      return {
        ...state,
        myHorses: {
          ...state.myHorses,
          items: action.payload.results,
          hasLoadedItems: true,
          loading: false,
          error: undefined,
        },
      };
    }

    case types.RECEIVE_MY_HORSES_ERROR: {
      const {
        payload: { status, statusText },
      } = action;

      return {
        ...state,
        myHorses: {
          ...state.myHorses,
          loading: false,
          error: {
            status,
            statusText,
          },
        },
      };
    }

    case types.RECEIVE_HORSE_DETAILS: {
      return dotProp.set(
        state,
        `horseDetails.resolved.${action.meta.horseId}`,
        action.payload,
      );
    }

    case types.RESOLVE_HORSE_DETAILS: {
      return dotProp.set(
        state,
        `horseDetails.fullyLoaded.${action.meta.horseId}`,
        true,
      );
    }

    case types.RECEIVE_ADD_HORSE: {
      return {
        ...state,
        myHorses: {
          ...state.myHorses,
          items: [action.payload, ...state.myHorses.items],
          loading: false,
          error: undefined,
        },
      };
    }

    case types.REQUEST_EDIT_HORSE: {
      return dotProp.set(
        state,
        `myHorses.loadingDict.${action.meta.horseId}`,
        true,
      );
    }

    case types.RECEIVE_EDIT_HORSE: {
      const modifiedHorse = action.payload;
      const nextState = dotProp.set(
        state,
        `myHorses.items`,
        state.myHorses.items.map(horse =>
          horse.horseId === modifiedHorse.horseId ? modifiedHorse : horse,
        ),
      );
      return dotProp.set(
        nextState,
        `myHorses.loadingDict.${modifiedHorse.horseId}`,
        false,
      );
    }

    case types.RECEIVE_DELETE_HORSE: {
      const removedHorseId = action.meta.horseId;
      return {
        ...state,
        myHorses: {
          ...state.myHorses,
          items: state.myHorses.items.filter(
            horse => horse.horseId !== removedHorseId,
          ),
          loading: false,
          error: undefined,
        },
      };
    }

    case types.RECEIVE_START_COMMENTS: {
      const resolvedStartComments = {};
      for (const startId of action.meta.startIds) {
        // All queried startIds are set to null to indicate they've been checked
        resolvedStartComments[startId] = null;
      }
      for (const result of action.payload.results) {
        // The results (if any) will overwrite the null values
        resolvedStartComments[result.startId] = result.comment;
      }
      return dotProp.set(state, `startComments.resolved`, {
        ...state.startComments.resolved,
        ...resolvedStartComments,
      });
    }

    case types.RECEIVE_ADD_START_COMMENT:
    case types.RECEIVE_EDIT_START_COMMENT: {
      const { startId, comment } = action.meta;
      return dotProp.set(state, `startComments.resolved.${startId}`, comment);
    }

    case types.RECEIVE_DELETE_START_COMMENT: {
      return dotProp.set(
        state,
        `startComments.resolved.${action.meta.startId}`,
        null, // null instead of deleted, to indicate its empty value is known
      );
    }

    case types.RECEIVE_MONITOR_SETTINGS: {
      return {
        ...state,
        settings: {
          resolved: action.payload,
          loading: false,
          error: undefined,
        },
      };
    }

    case types.REQUEST_EDIT_MONITOR_SETTINGS: {
      return {
        ...state,
        settings: {
          ...state.settings,
          loading: true,
        },
      };
    }

    default:
      return state;
  }
}
