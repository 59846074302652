import classnames from 'classnames';
import { bool, func } from 'prop-types';

import Button from '../../button/Button';
import styles from './BurgerButton.module.scss';

const BurgerButton = ({ active = false, onClick }) => (
  <Button
    className={classnames(styles.burgerButton, {
      [styles.active]: active,
    })}
    onClick={onClick}
  >
    <span className={styles.line} />
    <span className={styles.line} />
    <span className={styles.line} />
    <span className={styles.line} />
  </Button>
);

BurgerButton.propTypes = {
  active: bool,
  onClick: func.isRequired,
};

export default BurgerButton;
