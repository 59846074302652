import config from 'config';
import { RSAA } from 'redux-api-middleware';

import * as types from 'shared/redux/actionTypes';
import { calendar } from 'shared/redux/selectors';
import {
  CACHE_FIVE_MINUTES,
  CACHE_ONE_HOUR,
  isValidCache,
} from 'shared/utils/cacheUtils';
import { ANALYSIS_SITES } from 'sport/constants/sportConstants';

export function fetchAtgTrendData() {
  return {
    [RSAA]: {
      endpoint: `calendar/fetchTrendData/30`,
      types: [
        { type: types.REQUEST_ATG_TREND_DATA },
        { type: types.RECEIVE_ATG_TREND_DATA },
        {
          type: types.RECEIVE_ATG_TREND_DATA_ERROR,
          meta: { errorType: 'discrete' },
        },
      ],
    },
  };
}

export function fetchTodaysBestBet() {
  return {
    [RSAA]: {
      bailout: state =>
        isValidCache(
          calendar.todaysBestBetSelector(state).timestamp,
          CACHE_FIVE_MINUTES,
        ),
      endpoint: `calendar/todaysBestBet`,
      types: [
        { type: types.REQUEST_TODAYS_BEST_BET },
        { type: types.RECEIVE_TODAYS_BEST_BET },
        {
          type: types.RECEIVE_TODAYS_BEST_BET_ERROR,
          meta: { errorType: 'discrete' },
        },
      ],
    },
  };
}

export function fetchWiz() {
  return {
    [RSAA]: {
      bailout: state =>
        isValidCache(
          calendar.wizSystemsSelector(state).timestamp,
          CACHE_FIVE_MINUTES,
        ),
      endpoint: `calendar/fetchWiz`,
      types: [
        { type: types.REQUEST_WIZ_SYSTEMS },
        { type: types.RECEIVE_WIZ_SYSTEMS },
        {
          type: types.RECEIVE_WIZ_SYSTEMS_ERROR,
          meta: { errorType: 'discrete' },
        },
      ],
    },
  };
}

export const fetchLatestTips = () => ({
  [RSAA]: {
    bailout: state =>
      isValidCache(
        calendar.latestTipsSelector(state).timestamp,
        CACHE_FIVE_MINUTES,
      ),
    endpoint: `tips/0/3`,
    method: 'GET',
    types: [
      types.REQUEST_LATEST_TIPS,
      types.RECEIVE_LATEST_TIPS,
      types.RECEIVE_LATEST_TIPS_ERROR,
    ],
  },
});

export const fetchLatestNews = () => ({
  [RSAA]: {
    bailout: state =>
      isValidCache(
        calendar.latestNewsSelector(state).timestamp,
        CACHE_FIVE_MINUTES,
      ),
    endpoint: `${config.traisApi}/v1/public/content/?offset=0&limit=3&type=news_article&type=joker_school&type=winnings`,
    method: 'GET',
    headers: {
      'TRMedia-API-Key': config.trMediaApiKey,
      site: process.env.REACT_APP_TRAIS_SITE,
    },
    types: [
      types.REQUEST_LATEST_NEWS,
      types.RECEIVE_LATEST_NEWS,
      types.RECEIVE_LATEST_NEWS_ERROR,
    ],
  },
});

export function fetchAtgCalendar() {
  return {
    [RSAA]: {
      bailout: state =>
        isValidCache(
          calendar.atgCalendarSelector(state).timestamp,
          CACHE_ONE_HOUR,
        ),
      endpoint: 'calendar/fetchCalendar',
      types: [
        types.REQUEST_ATG_CALENDAR,
        types.RECEIVE_ATG_CALENDAR,
        {
          type: types.RECEIVE_ATG_CALENDAR_ERROR,
          meta: { errorType: 'discrete' },
        },
      ],
    },
  };
}

export function fetchSportCalendar() {
  return {
    [RSAA]: {
      bailout: state =>
        isValidCache(
          calendar.sportCalendarSelector(state).timestamp,
          CACHE_ONE_HOUR,
        ),
      endpoint: 'sportCalendar/fetchCalendar',
      types: [
        types.REQUEST_SPORT_CALENDAR,
        types.RECEIVE_SPORT_CALENDAR,
        {
          type: types.RECEIVE_SPORT_CALENDAR_ERROR,
          meta: { errorType: 'discrete' },
        },
      ],
    },
  };
}

export function fetchJackpots() {
  const jackpotFetches = [fetchAtgJackpots];
  if (INCLUDE_SPORT) {
    jackpotFetches.push(fetchSportJackpots);
  }
  return dispatch => {
    Promise.all(jackpotFetches.map(jackpotFetch => dispatch(jackpotFetch())));
  };
}

export function fetchAtgJackpots() {
  return {
    [RSAA]: {
      bailout: state =>
        isValidCache(
          calendar.atgJackpotsSelector(state).timestamp,
          CACHE_ONE_HOUR,
        ),
      endpoint: 'calendar/fetchJackpots',
      types: [
        types.REQUEST_ATG_JACKPOTS,
        types.RECEIVE_ATG_JACKPOTS,
        {
          type: types.RECEIVE_ATG_JACKPOTS_ERROR,
          meta: { errorType: 'discrete' },
        },
      ],
    },
  };
}

export function fetchSportJackpots() {
  return {
    [RSAA]: {
      bailout: state =>
        isValidCache(
          calendar.sportJackpotsSelector(state).timestamp,
          CACHE_ONE_HOUR,
        ),
      endpoint: 'sportCalendar/fetchJackpots',
      types: [
        types.REQUEST_SPORT_JACKPOTS,
        types.RECEIVE_SPORT_JACKPOTS,
        {
          type: types.RECEIVE_SPORT_JACKPOTS_ERROR,
          meta: { errorType: 'discrete' },
        },
      ],
    },
  };
}

export function fetchSportAnalysis() {
  return dispatch =>
    Promise.all(
      Object.keys(ANALYSIS_SITES).map(site =>
        dispatch({
          [RSAA]: {
            bailout: state =>
              isValidCache(
                calendar.sportAnalysisSelector(state)[site]?.timestamp,
                CACHE_ONE_HOUR,
              ),
            endpoint: `${config.trsportApi}/v1/svenskaspel/rounds/latest?external=true`,
            method: 'GET',
            headers: {
              'TRMedia-API-Key': config.trMediaApiKey,
              site,
            },
            types: [
              {
                type: types.REQUEST_SPORT_ANALYSIS,
                meta: { site },
              },
              {
                type: types.RECEIVE_SPORT_ANALYSIS,
                meta: { site },
              },
              {
                type: types.RECEIVE_SPORT_ANALYSIS_ERROR,
                meta: { errorType: 'discrete', site },
              },
            ],
          },
        }),
      ),
    );
}

export function changeSportSectionVisibility(visible) {
  return {
    type: types.CHANGE_SPORT_SECTION_VISIBILITY,
    payload: visible,
    meta: {
      persist: 'local',
    },
  };
}

export function changeAtgSectionVisibility(visible) {
  return {
    type: types.CHANGE_ATG_SECTION_VISIBILITY,
    payload: visible,
    meta: {
      persist: 'local',
    },
  };
}

export function changeAtgListVisibility(visible) {
  return {
    type: types.CHANGE_ATG_LIST_VISIBILITY,
    payload: visible,
    meta: {
      persist: 'local',
    },
  };
}

export function changeSportListVisibility(visible) {
  return {
    type: types.CHANGE_SPORT_LIST_VISIBILITY,
    payload: visible,
    meta: {
      persist: 'local',
    },
  };
}
