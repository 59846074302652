import PropTypes from 'prop-types';

import logoSweden from 'images/jokersystemet_logotyp.svg';
import logoNorway from 'images/jokersystemet_logotyp-no.svg';
import logoWhiteSweden from 'images/jokersystemet_white_logotyp.svg';
import logoWhiteNorway from 'images/jokersystemet_white_logotyp-no.svg';
import { getText } from 'shared/utils/Text';

const logo = IS_SE_SITE ? logoSweden : logoNorway;
const logoWhite = IS_SE_SITE ? logoWhiteSweden : logoWhiteNorway;

const Logo = ({ white = false, className }) => (
  <img
    src={white ? logoWhite : logo}
    className={className}
    alt={getText('title-app')}
  />
);

Logo.propTypes = {
  className: PropTypes.string,
  white: PropTypes.bool,
};

export default Logo;
