import { deviceType, supportsPassiveEvents as passiveEvents } from 'detect-it';

import { CURRENT_INPUT_CHANGE } from 'atg/constants/Events';

import EventDispatcher from './EventDispatcher';

let lastIsTouch;

export function isTouchOnly() {
  return deviceType === 'touchOnly';
}

export function isMouseOnly() {
  return deviceType === 'mouseOnly';
}

export function isHybrid() {
  return deviceType === 'hybrid';
}

export function supportsPassiveEvents() {
  return passiveEvents;
}

export function isTouch() {
  if (isMouseOnly()) {
    return false;
  }

  if (isTouchOnly()) {
    return true;
  }

  // For hybrid devices, check what class current-input has set
  // on the body to determine if touch is being used
  const body = document.querySelector('body');
  return body.classList.contains('current-input-touch');
}

function initHybridObserver() {
  if (isHybrid()) {
    const body = document.querySelector('body');

    if (!body) {
      document.addEventListener('DOMContentLoaded', initHybridObserver);
      return;
    }

    lastIsTouch = isTouch();

    const observer = new MutationObserver(mutations => {
      mutations.forEach(function (mutation) {
        if (mutation.attributeName === 'class') {
          EventDispatcher.emit(CURRENT_INPUT_CHANGE);
          const curIsTouch = isTouch();
          if (curIsTouch !== lastIsTouch) {
            lastIsTouch = curIsTouch;
            EventDispatcher.emit(CURRENT_INPUT_CHANGE);
          }
        }
      });
    });

    observer.observe(body, { attributes: true });
  }
}

initHybridObserver();
