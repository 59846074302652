import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import {
  LOGGED_IN_TO_OTHER_DEVICE,
  SESSION_EXPIRED,
} from 'shared/constants/MessageTypes';
import { ui, user } from 'shared/redux/selectors';
import { getUrl } from 'shared/utils/urls';

import ErrorBoundRoute from './ErrorBoundRoute';

const AccessDenied = () => {
  const loggedInToOtherDevice = useSelector(state =>
    ui.hasMessageType(state, { messageType: LOGGED_IN_TO_OTHER_DEVICE }),
  );
  const sessionExpired = useSelector(state =>
    ui.hasMessageType(state, { messageType: SESSION_EXPIRED }),
  );
  const history = useHistory();

  useEffect(() => {
    if (loggedInToOtherDevice || sessionExpired) {
      history.replace('/');
    } else {
      window.location.href = getUrl.login();
    }
  }, [history, loggedInToOtherDevice, sessionExpired]);

  return null;
};

const AuthRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = useSelector(user.isLoggedIn);

  return (
    <ErrorBoundRoute
      {...rest}
      render={props =>
        isLoggedIn ? <Component {...props} /> : <AccessDenied />
      }
    />
  );
};

export default AuthRoute;
