import createCachedSelector from 're-reselect';

const getResolved = state => state.instructions.resolved;
const getErrors = state => state.instructions.errors;
const getPlaylistId = (_, props) => props.playlistId;

export const getPlaylistItems = createCachedSelector(
  [getResolved, getPlaylistId],
  (resolved, playlistId) =>
    resolved[playlistId] != null
      ? resolved[playlistId].items.map(item => item.snippet)
      : [],
)(getPlaylistId);

export const getPlaylistError = createCachedSelector(
  [getErrors, getPlaylistId],
  (errors, playlistId) => errors[playlistId],
)(getPlaylistId);
