import * as types from '../actionTypes';

export const onTraisSocketMessage = (data, kind) => ({
  type: types.TRAIS_SOCKET_RECEIVE_DATA,
  payload: { data, kind },
});

export const addTraisSubscription = kind => ({
  type: types.TRAIS_SOCKET_SUBSCRIBE,
  payload: { kind },
});

export const removeTraisSubscription = kind => ({
  type: types.TRAIS_SOCKET_UNSUBSCRIBE,
  payload: { kind },
});
