import { getText, hasText } from './Text';

const padRight = function (str_, pad, length) {
  let str = String(str_);
  while (str.length < length) {
    str = str + pad;
  }
  return str;
};

export const formatMoneyCompact = function (amount) {
  let suffix = '';

  let compactedAmount = amount;
  while (compactedAmount > 1000) {
    suffix += "'";
    compactedAmount = Math.floor(compactedAmount / 1000);
  }

  return compactedAmount + suffix;
};

export const formatMoney = function (amount, currency = 'sek') {
  let amountValue = amount;

  if (amountValue === undefined) {
    return '-';
  }

  const sign = amountValue < 0 ? '-' : '';

  amountValue = Math.abs(amountValue);
  amountValue = String(Math.round(amountValue * 100) / 100);

  let parts = amountValue.split('.');

  let wholeAmount = Math.floor(amountValue);
  let centsAmount = amountValue - wholeAmount;

  wholeAmount = parts[0];

  if (parts.length > 1) {
    centsAmount = parts[1];
  }

  parts = [];
  while (wholeAmount.length > 0) {
    const i = Math.max(0, wholeAmount.length - 3);
    parts.unshift(wholeAmount.slice(i));
    wholeAmount = wholeAmount.slice(0, Math.max(0, i));
  }

  const isSekOrNok = ['sek', 'nok'].includes(currency.toLowerCase());
  const thousandSeparator = isSekOrNok ? ' ' : '.';
  let amountString = parts.join(thousandSeparator);

  if (centsAmount) {
    amountString += `,${padRight(centsAmount, '0', 2)}`;
  }

  amountString = sign + amountString;

  if (!currency) {
    return amountString;
  } else {
    const textID = `general-currency-${currency.toLowerCase()}`;

    return hasText(textID)
      ? getText(textID, amountString)
      : `${amountString} ${currency}`;
  }
};

export function roundLargeAmount(amount) {
  // Only round with amounts above 10k
  if (!Number.isFinite(amount) || Number.isNaN(amount) || amount < 10000) {
    return amount;
  }

  // Round to nearest 10k on amounts below 1 million
  if (amount < 1000000) {
    return Math.round(amount / 10000) * 10000;
  }

  // Round to nearest 100k on amounts up to 10 million
  if (amount <= 10000000) {
    return Math.round(amount / 100000) * 100000;
  }

  // Round to nearest 500k on amounts up to 20 million
  if (amount <= 20000000) {
    return Math.round(amount / 500000) * 500000;
  }

  // Round to nearest 1 million
  return Math.round(amount / 1000000) * 1000000;
}

export function formatLargeMoneyAmount(
  amount,
  { currency = 'sek', shortenMillionSuffix = false } = {},
) {
  if (!Number.isFinite(amount) || Number.isNaN(amount)) {
    return amount;
  }

  const ONE_MILLION = 1000000;

  const roundedAmount = roundLargeAmount(amount);

  if (roundedAmount < ONE_MILLION) {
    return formatMoney(roundedAmount, currency);
  }

  const [millions, thousands] = String(roundedAmount / ONE_MILLION).split('.');

  const strAmount = thousands > 0 ? `${millions},${thousands}` : millions;

  let textID;
  if (shortenMillionSuffix) {
    textID = `currency-million-short_${currency.toLowerCase()}`;
  } else {
    const quantityForm =
      millions === 1 && thousands === 0 ? 'singular' : 'plural';
    textID = `currency-million_${quantityForm}-${currency.toLowerCase()}`;
  }
  if (hasText(textID)) {
    return getText(textID, strAmount);
  } else {
    if (process.env.NODE_ENV !== 'test') {
      console.warn(`Unknown currency ${currency}`);
    }
    return `${formatMoney(roundedAmount, '')} ${currency}`;
  }
}
