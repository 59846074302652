import classnames from 'classnames';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';

import styles from './index.module.scss';

const Textarea = ({
  className,
  disabled = false,
  onChange,
  maxLength = 200,
  onKeyDown = noop,
  placeholder = '',
  value = '',
}) => (
  <textarea
    className={classnames(className, styles.container)}
    disabled={disabled}
    maxLength={maxLength}
    onChange={onChange}
    onKeyDown={onKeyDown}
    placeholder={placeholder}
    rows={3}
    value={value}
  />
);

Textarea.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

export default Textarea;
