import compact from 'lodash/compact';
import difference from 'lodash/difference';
import { oneOf, string } from 'prop-types';
import { PureComponent } from 'react';

import { getText } from 'shared/utils/Text';

const instances = [];
let body = null;
let app = null;

let curBodyClasses = [];
let curAppClasses = [];

const setClassNames = (arr, target, method) => {
  arr.forEach(className => target.classList[method](className));
};

const updateClassNames = (prev, next, target) => {
  setClassNames(difference(prev, next), target, 'remove');
  setClassNames(difference(next, prev), target, 'add');
};

const updateTitle = (titles = []) => {
  const separator = ' · ';
  document.title = [...compact(titles).reverse(), getText('title-app')].join(
    separator,
  );
};

const update = () => {
  // Accumulate props from all instances
  // to an object with properties:
  //  theme {String} - theme set from the deepest instance
  //  bodyClassName {Array} – classes to apply to the body
  //  appClassName {Array} – classes to apply to the app container
  //  title {Array} – list of strings to apply to document title
  //
  const props = instances
    .map(instance => instance.props)
    .reduce((acc, { theme, ...props }) => {
      acc.theme = theme ? theme : acc.theme;
      Object.keys(props).forEach(key => {
        acc[key] = acc[key] || [];
        if (!acc[key].includes(props[key])) {
          acc[key].push(props[key]);
        }
      });
      return acc;
    }, {});

  const { title: titles, theme } = props;
  const { bodyClassName: bodyClasses = [], appClassName: appClasses = [] } =
    props;

  if (theme) {
    bodyClasses.push(`body-${theme}`);
    appClasses.push(`app-${theme}`);
  }

  updateClassNames(curBodyClasses, bodyClasses, body);
  curBodyClasses = bodyClasses;

  updateClassNames(curAppClasses, appClasses, app);
  curAppClasses = appClasses;

  updateTitle(titles);
};

export default class PageMeta extends PureComponent {
  static propTypes = {
    theme: oneOf(['atg', 'calendar', 'sport', 'article', 'auth']),
    bodyClassName: string,
    appClassName: string,
    title: string,
  };

  UNSAFE_componentWillMount() {
    if (!body) {
      body = document.body;
    }
    if (!app) {
      app = document.querySelector('#app');
    }
    instances.push(this);
    update();
  }

  componentDidUpdate() {
    update();
  }

  componentWillUnmount() {
    const index = instances.indexOf(this);
    instances.splice(index, 1);
    update();
  }

  render() {
    return null;
  }
}
