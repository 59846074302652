import { string } from 'prop-types';
import { connect } from 'react-redux';

import { showHelpDialog } from 'shared/redux/actions';

const mapDispatchToProps = (dispatch, { header, body }) => ({
  onClick: e => {
    e.stopPropagation();
    dispatch(showHelpDialog(header, body));
  },
});

const HelpButton = ({ onClick, iconClassName }) => (
  <img
    onClick={onClick}
    alt="Hjälp"
    src="/images/help.svg"
    className={`help-icon${iconClassName ? ` ${iconClassName}` : ''}`}
  />
);

HelpButton.propTypes = {
  header: string,
  body: string.isRequired,
};

export default connect(null, mapDispatchToProps)(HelpButton);
