import merge from 'lodash/merge';
import { applyMiddleware, compose, createStore } from 'redux';
import { apiMiddleware } from 'redux-api-middleware';
import thunk from 'redux-thunk';

import apiInjectMiddleware from './middleware/apiInjectMiddleware';
import errorMiddleware from './middleware/errorMiddleware';
import gtmTrackingMiddleware from './middleware/gtmTrackingMiddleware';
import persistentStorageMiddleware, {
  getPersistentState,
} from './middleware/persistentStorageMiddleware';
import stompMiddleware from './middleware/stompMiddleware';
import stompSportUpdateMiddleware from './middleware/stompSportUpdateMiddleware';
import trackingMiddleware from './middleware/trackingMiddleware';
import traisSocketMiddleware from './middleware/traisSocketMiddleware';
import userSideEffectsMiddleware from './middleware/userSideEffectsMiddleware';
import createReducer from './reducers';

// ATG Legacy
let store = null;
export function getStore() {
  return store;
}

function hydrateState(initialState, persistedState, key) {
  if (persistedState != null) {
    const state = merge({}, initialState, persistedState);
    store.dispatch({
      type: '__HYDRATE_STATE__',
      key,
      state,
    });
  }
}

export function configureStore() {
  let composeFn = compose;

  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeFn = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      actionSanitizer: action =>
        action.type === 'ATG/CHANGE_VIEW'
          ? { ...action, payload: '**NON-SERIALIZABLE**' }
          : action,
      stateSanitizer: state =>
        state.atg__view != null
          ? {
              ...state,
              atg__view: {
                ...state.atg__view,
                currentView: '**NON-SERIALIZABLE**',
              },
            }
          : state,
    });
  }

  const middleware = [
    stompMiddleware,
    traisSocketMiddleware,
    apiInjectMiddleware,
    apiMiddleware,
    errorMiddleware,
    persistentStorageMiddleware(),
    userSideEffectsMiddleware,
    trackingMiddleware,
    gtmTrackingMiddleware,
    stompSportUpdateMiddleware,
    thunk,
  ];

  const reducer = createReducer();

  if (process.env.REACT_APP_BUILD_TARGET !== 'prod') {
    // Throws an error when it detects mutations between and outside redux dispatches
    const stateInvariant = require('redux-immutable-state-invariant').default({
      ignore: ['atg__view'],
    });
    // Logs actions to console
    const { createLogger } = require('redux-logger');
    const logger = createLogger({
      collapsed: true,
    });
    // Tool to collect a report on the client's state
    const devPanel = require('./middleware/devPanelMiddleware').default;

    middleware.unshift(stateInvariant);
    middleware.push(devPanel, logger);
  }

  store = createStore(reducer, composeFn(applyMiddleware(...middleware)));
  store.asyncReducers = {};

  const persistedState = getPersistentState();
  const reducerKeys = Object.keys(reducer());
  reducerKeys.forEach(key => {
    hydrateState(store.getState()[key], persistedState[key], key);
  });

  return store;
}

export function injectAsyncReducers(store, reducers = {}) {
  const newKeys = Object.keys(reducers).filter(
    key => !store.asyncReducers[key],
  );
  if (newKeys.length > 0) {
    newKeys.forEach(key => {
      // Add new reducer to list of async reducers
      store.asyncReducers[key] = reducers[key];
    });
    // Update store reducers with new reducers
    store.replaceReducer(createReducer(store.asyncReducers));
    // Hydrate new reducers
    const persistedState = getPersistentState();
    newKeys.forEach(key => {
      const initialState = reducers[key]();
      hydrateState(initialState, persistedState[key], key);
    });
  }
}
