import { RSAA } from 'redux-api-middleware';

import * as types from '../actionTypes';

export const uploadBet = data => ({
  [RSAA]: {
    endpoint: `archive/uploadBet/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/octet-stream' },
    body: data,
    types: [
      types.REQUEST_UPLOAD_BET,
      types.RECEIVE_UPLOAD_BET,
      {
        type: types.RECEIVE_UPLOAD_BET_ERROR,
        meta: { errorType: 'discrete' },
      },
    ],
  },
});

export const clearArchiveUploadError = () => ({
  type: types.CLEAR_ARCHIVE_UPLOAD_ERROR,
});
