import * as sharedTypes from 'shared/redux/actionTypes';

import * as types from '../actionTypes';

const defaultState = {
  isLoading: false,
  error: null,
  data: undefined,
  prevData: undefined,
};

export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case types.REQUEST_SETTINGS:
    case types.REQUEST_UPDATE_SETTINGS:
      return {
        isLoading: true,
        error: null,
        data: action.payload,
        prevData: state.data,
      };

    case types.RECEIVE_SETTINGS:
      return {
        isLoading: false,
        error: null,
        data: action.payload,
        prevData: undefined,
      };

    case types.RECEIVE_UPDATE_SETTINGS:
      return {
        isLoading: false,
        error: null,
        data: state.data,
        prevData: undefined,
      };

    case types.RECEIVE_SETTINGS_ERROR:
    case types.RECEIVE_UPDATE_SETTINGS_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        data: state.prevData,
        prevData: undefined,
      };

    case sharedTypes.DELETE_USER:
      return defaultState;

    default:
      return state;
  }
}
