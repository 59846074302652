import { connect } from 'react-redux';

import InvitePage from 'shared/components/InvitePage';
import {
  clearInvite,
  deleteInvite,
  fetchInvite,
  setInviteAlias,
} from 'shared/redux/actions';
import {
  getInvite,
  getInviteError,
  getLoadingInvite,
} from 'shared/redux/selectors/shareSelectors';
import { getEmail } from 'shared/redux/selectors/userSelectors';

const extractFromRouteMatch = match => ({
  betId: match.params.betId,
  type: match.path.includes('sport') ? 'sport' : 'atg',
  betPath: `/${match.path.includes('sport') ? 'sport' : 'trav'}/${
    match.params.betId
  }`,
});

const mapStateToProps = (state, { match }) => {
  const { betId, type } = extractFromRouteMatch(match);
  return {
    type,
    betPath: `/${type === 'sport' ? 'sport' : 'trav'}/${betId}`,
    userEmail: getEmail(state),
    invite: getInvite(state, { betId, type }),
    error: getInviteError(state, { betId, type }),
    isloading: getLoadingInvite(state, { betId, type }),
  };
};

const mapDispatchToProps = (dispatch, { match }) => {
  console.info('mapDispatchToProps yet again');
  const { betId, type } = extractFromRouteMatch(match);
  return {
    fetchInvite: () => dispatch(fetchInvite({ betId, type })),
    setInviteAlias: alias => dispatch(setInviteAlias({ betId, type, alias })),
    deleteInvite: () => dispatch(deleteInvite({ betId, type })),
    clearInvite: () => dispatch(clearInvite({ betId, type })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvitePage);
