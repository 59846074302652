import { createSelector } from 'reselect';

export const getDialogs = state => state.dialogs;

/**
 * Creates a selector function for checking if a type of dialog is in the set of dialog objects
 * @param  {string} dialogType - The dialog type to search for
 */
export const hasDialog = createSelector(
  [state => state.dialogs, (_, props) => props.dialogType],
  (dialogs, type) => dialogs.some(dialog => dialog.type === type),
);

export const isLocked = createSelector(
  [state => state.dialogs, (_, props) => props.dialogKey],
  (dialogs, key) => (dialogs.find(dialog => dialog.key === key) || {}).locked,
);
