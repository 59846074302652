import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ManageHorseDialog from 'shared/components/dialog/ManageHorseDialog';
import { fetchRound, showConfirmDialog } from 'shared/redux/actions';
import {
  addHorse,
  addStartComment,
  deleteStartComment,
  editHorse,
  editStartComment,
  fetchStartComments,
  fetchTraisHorse,
  removeHorse,
} from 'shared/redux/actions/monitorActions';
import {
  getHorses,
  getRound,
  getStartComment,
  getStartId,
  getTraisHorseId,
} from 'shared/redux/selectors/monitorSelectors';

const mapStateToProps = (
  state,
  { atgHorseId, roundSlug, legNumber, startNumber, startId },
) => {
  // startId either as explicit prop or derived via fetched round data
  const _startId =
    startId ?? getStartId(state, { roundSlug, legNumber, startNumber });
  return {
    round: getRound(state, { roundSlug }),
    traisHorseId: getTraisHorseId(state, { atgHorseId }),
    startId: _startId,
    startComment: getStartComment(state, { startId: _startId }),
    myHorses: getHorses(state),
  };
};

const mapDispatchToProps = (dispatch, { atgHorseId, roundSlug }) => ({
  fetchRound: () => dispatch(fetchRound(roundSlug)),
  fetchTraisHorse: () => dispatch(fetchTraisHorse(atgHorseId)),
  fetchStartComment: startId => dispatch(fetchStartComments([startId])),
  ...bindActionCreators(
    {
      addHorse,
      editHorse,
      removeHorse,
      addStartComment,
      editStartComment,
      deleteStartComment,
      showConfirmDialog,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageHorseDialog);
